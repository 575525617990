import axios from 'axios';
import {
    API_HEADER,
    ORDERS_API
} from '../../../constants/API';
import {
    GET_ORDERS
} from '../types';


const getOrders = (queryParams = '') => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_ORDERS,
                loadingOrders: true,
                orders: []
            });
            const { token } = getState().UserReducer;
            const ordersResponse = await axios.get(`${ORDERS_API}?${queryParams}`, API_HEADER(token));
            console.log(ordersResponse)
            dispatch({
                type: GET_ORDERS,
                loadingOrders: false,
                orders: ordersResponse.data || []
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_ORDERS,
                loadingOrders: false,
                orders: false
            })
        }
    }
}

export { getOrders };