import axios from 'axios';
import {
    API_HEADER,
    BIDS_API
} from '../../../constants/API';
import {
    GET_BIDS
} from '../types';


const getBids = (search) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_BIDS,
                loadingBids: true,
                bids: []
            });
            const { token } = getState().UserReducer;
            const bidsResponse = await axios.get(`${BIDS_API}?status=Active&query=${search}&hasExpired=false`, API_HEADER(token));
            console.log(bidsResponse)
            dispatch({
                type: GET_BIDS,
                loadingBids: false,
                bids: bidsResponse.data || []
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_BIDS,
                loadingBids: false,
                bids: []
            })
        }
    }
}

export { getBids };