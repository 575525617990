import {
    bindActionCreators
} from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';

function mapStateToProps(state, props) {
    return {
        user: state.UserReducer,
        product: state.ProductReducer,
        order: state.OrderReducer,
        bid: state.BidReducer,
        paymentMethod: state.PaymentMethodReducer,
        shippingAddress: state.ShippingAddressReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

 const connecScreen = (screen) => connect(mapStateToProps, mapDispatchToProps)(screen);

 export default connecScreen;