const steps = [
  {
    title:"Pending Confirmation",
    description:"Your order has been received, payment is being processed by our team",
  },
  {
    title:"Order Confirmed",
    description:"Your payment has been received! Our fulfillment team has received your order and is preparing your order to ship"
  },
  {
    title:"Item en route to our Authentication Warehouse",
    description:"It takes 1-4 days to arrive to our warehouse."
  },
  {
    title:"Received at Warehouse",
    description:""
  },
  {
    title: "Pending Verification",
    description: "When your order has completed our multi-step authentication process, it will be sent out."
  },
  {
    title: "Verified",
    description: "Your item has successfully passed our multi step authentication process."
  },
  {
    title: "Waiting to be packaged",
    description: "The warehouse is now preparing your item to be shipped."
  },
  {
    title: "Shipped",
    description: "Your order has been shipped and is on its way to you."
  }
]

export default steps;