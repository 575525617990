import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100vw - 50px);
    margin: 0 auto;
    @media (max-width: 780px) {
        width: calc(100vw - 20px);
`;
// even location container will be styled differently. They will be TEXT IMAGE. Odd locations will be IMAGE TEXT
export const LocationContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.currentIndex % 2 === 0 ? 'row' : 'row-reverse'};
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 1.5rem;
    margin-bottom: 1.5rem;
    gap: 5vw;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    }
    @media (max-width: 780px) {
        flex-direction: column;
        gap: 0px;
        margin-bottom: 2rem;
        align-items: flex-start;
    }
`;
export const LocationItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    @media (max-width: 780px) {
        width: 100%;
        padding: 0.725rem 1.5rem;
    }
`;
export const LocationImage = styled.img`
    width: 40%;
    height: 40%;
    border-radius: ${props => props.currentIndex % 2 === 0 ? '1.5rem 0 0 1.5rem' : '0 1.5rem 1.5rem 0'};
    object-fit: cover;
    @media (max-width: 780px) {
        width: 100%;
        height: 100%;
        border-radius: 1.5rem 1.5rem 0 0;
    }
`;
export const LocationText = styled.h3`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 3rem;
    @media (max-width: 780px) {
        font-size: 1.5rem;
    }
    `;
export const LocationSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 1.125rem;
    @media (max-width: 780px) {
        font-size: 0.825rem;
    }
`;
export const LocationIcon = styled.img`
    @media (max-width: 780px) {
        width: 1.5rem;
        height: 1.5rem;
    }
`;
export const HeroTextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 80px;
    @media (max-width: 780px) {
        padding-left: 0px;
    }
`;
export const HeroImageContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
export const Hero = styled.div`
    display: flex;
    justify-content: space-between;
    height: calc(50vh - 90px);
    max-width: 100vw;
    @media (max-width: 780px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 0 1.5rem;
    }
`;
export const HeroText = styled.h1`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 3.5rem;
    @media (max-width: 780px) {
        font-size: 2rem;
        margin-bottom: 0.25rem;
    }
`;
export const HeroSubText = styled.h2`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 1.5rem;
    @media (max-width: 780px) {
        font-size: 1.125rem;
    }
`;
export const HeroImage = styled.img`
    width: 100%;
    object-fit: contain;
    @media (max-width: 780px) {
        max-height: 300px;
    }
`;