import styled from "styled-components";
import Fonts from "../../constants/Fonts";
import Button from "../../components/Button";
import ReactSlider from "react-slider";
import Colors from "../../constants/Colors";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.browseBackground};
  overflow-x: hidden;
`;

export const CloseButton = styled.img`
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
`;

export const ApplyButton = styled(Button)`
  background: ${Colors.blue};
  color: ${Colors.white};
  width: 80%;
  opacity: ${(props) => (props.handleOpacity ? 0.5 : 1)};
`;

export const ClearFilterButton = styled(Button)`
  background: ${Colors.white};
  color: ${Colors.midGrey};
  border: none;
  width: 80%;
  outline: none;
`;

export const FilterButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FiltersContainer = styled.div`
  width: 300px;
  min-height: 100vh;
  background: ${Colors.white};
  padding-top: 60px;
  padding-left: 50px;
  @media (max-width: 780px) {
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 100px;
    z-index: 999999;
    overflow-y: scroll;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 780px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const FilterButton = styled.p`
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.white};
    position: fixed;
    z-index: 88888;
    bottom: 0;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
    right: 20px;
    background: ${Colors.blue};
}
`;

export const ProductSearchContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  @media (max-width: 780px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
`;
export const ProductsContainer = styled.div`
  margin-top: 20px;
  max-width: 100%;
  @media (max-width: 780px) {
    margin-top: 10px;
  }
`;

export const CheckboxContainer = styled.div``;
export const FilterContainer = styled.div`
  margin-bottom: 40px;
`;
export const FilterTitle = styled.p`
  font-size: 14px;
  font-family: ${Fonts.PRIMARY_BOLD};
  color: ${Colors.blue};
  margin-bottom: 20px;
`;

export const StyledSlider = styled(ReactSlider)`
  width: 80%;
  height: 15px;
  @media (max-width: 780px) {
    width: 100%;
    height: 30px;
  }
`;

export const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  margin-top: -5px;
  text-align: center;
  font-size: 6px;
  border-radius: 50%;
  background-color: ${Colors.blue};
  color: #fff;
  cursor: grab;
  @media (max-width: 780px) {
    height: 40px;
    line-height: 40px;
    width: 40px;
    margin-top: -5px;
    font-size: 12px;
  }
`;

export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${Colors.lightGrey};
  border-radius: 999px;
`;