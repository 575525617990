import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const ListContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 90vw;
`

const ProductCard = styled.div`
    flex: 0 0 auto;
    width: 200px;
    background: ${Colors.white};
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    cursor: pointer;
`
const ProductTitle = styled.p`
    font-size: 12px;
    opacity: 0.7;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.greyishBlue};
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    margin: 45px 0px 10px 0px;
    height: 70px;
    @media (max-width: 780px) {
        white-space: pre-wrap;
    }
    

`
const ProductPrice = styled.p`
    font-size: 18px;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.greyishBlue};
    margin: 0px;
`
const ProductImage = styled.img`
    max-width: 150px;
    object-fit: contain;
    @media (max-width: 780px) {
        max-width: 150px;

    }
`
const TextContainer = styled.div``
const LinkContainer = styled(Link)`
    text-decoration: none;
    background-color: ${Colors.white};
    padding: 20px;
    border-radius: 5px;

`

const ProductScroller = (props) => {
    const { products } = props;

    const getImage = (product) => {
        if (product.featuredImage) {
            return product.featuredImage !== null && product.featuredImage.originalSrc;
        }
        if (product.featuredImage === null) {
            return "https://res.cloudinary.com/dm5xjgl02/image/upload/v1668198427/Safe-73-jpg_t8olqa.jpg"
        }
        return product.images && product.images[0] ? product.images[0] : '';
    }
    const getPrice = (product) => {
        if (product.priceRangeV2) return `$${product.priceRangeV2.minVariantPrice.amount}`
        const stockXPrice = product && product.lowestAsk ? product.lowestAsk : null;
        if (
            product &&
            product.Listings &&
            product.Listings.length > 0
        ) {
            const lowestPricedListing = product.Listings.reduce((prev, curr) => Number(prev.price) < Number(curr.price) ? prev : curr);
            const { price } = lowestPricedListing;
            const priceToShow = !stockXPrice || price < stockXPrice ? price : stockXPrice;
            return `$${priceToShow}`
        } else if (stockXPrice) {
            return `$${stockXPrice}`
        }
        return 'Sold out'
    }
    return (
        <FadeIn>
            <ListContainer>

                {
                    products.map(product =>
                        <ProductCard key={product.id}>
                            <LinkContainer to={{
                                pathname: product.handle ? `/product/${product.handle}/?shopify=true` : `/product/${product.stockXHandle}`
                            }}>
                                <ProductImage
                                    src={getImage(product)}
                                    alt='product-image'
                                />
                                <TextContainer>
                                    <ProductTitle>
                                        {product.title}
                                    </ProductTitle>
                                    <ProductPrice>
                                        {getPrice(product)}
                                    </ProductPrice>
                                </TextContainer>
                            </LinkContainer>
                        </ProductCard>
                    )}
            </ListContainer>
        </FadeIn>
    )
}

export default ProductScroller;