import axios from 'axios';
import {
    API_HEADER,
    PAYMENT_METHOD_API
} from '../../../constants/API';
import {
    CREATE_PAYMENT_METHOD
} from '../types';


const createPaymentMethod = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_PAYMENT_METHOD,
                creatingPaymentMethod: true,
                createdPaymentMethod: null
            });
            const { token } = getState().UserReducer;
            const createPaymentMethodResponse = await axios.post(`${PAYMENT_METHOD_API}`, data,  API_HEADER(token));
            console.log(createPaymentMethodResponse)
            dispatch({
                type: CREATE_PAYMENT_METHOD,
                creatingPaymentMethod: false,
                createdPaymentMethod: createPaymentMethodResponse.data
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: CREATE_PAYMENT_METHOD,
                creatingPaymentMethod: false,
                createdPaymentMethod: null
            })
        }
    }
}

export { createPaymentMethod };