import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import connectScreen from "../../redux/connectScreen";
import Hero from "../../components/Hero";
import StickyHeader from "../../components/StickyHeader";
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import { HeroImages, MobileHeroImages } from "../../constants/HeroImages";
import ProductSection from "../../components/ProductSection";
import Input from "../../components/Input";
import RoundButton from "../../components/RoundButton";
import Copy from "../../constants/Copy";
import SearchImage from "../../assets/images/search_image.svg";
import LoadingScreen from "../../components/LoadingScreen";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  Container,
  CategoryContainer,
  ProductSections,
  TabSearchContainer,
  SearchBarContainer,
  SearchContainer,
  AutoCompleteContainer,
  AutoCompleteItem,
  ProductSKU,
  ProductTitle,
  ProductImage,
  ProductTextContainer,
  InnerTabContainer,
} from "./styles";
import OuterTabs from "../../components/Tabs";
import ApparelFilters from "../../components/ApparelFilters";

const Home = (props) => {
  const {
    trendingAll,
    trendingAdidas,
    trendingNike,
    trendingJordan,
    newReleasesForGender,
    collectionProducts,
  } = props.product;
  const [selectedOuterTab, setSelectedOuterTab] = useState("sneakers");
  const [selectedInnerTab, setSelectedInnerTab] = useState("men");
  const [search, setSearch] = useState("");
  const [shouldRedirectToBrowse, setShouldRedirectToBrowse] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [selectedSize, setSelectedSize] = useState('all sizes');
  const [selectedBrand, setSelectedBrand] = useState('all brands');
  const [filteredProducts, setFilteredProducts] = useState(collectionProducts);
  const history = useHistory();
  useEffect(() => {
    props.getTrendingProductsForGender("men");
  }, []);

  useEffect(() => {
    if (search !== undefined && search !== "") {
      const delayDebounceFn = setTimeout(() => {
        searchProducts(search);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      props.product.products = [];
    }
  }, [search]);

  /**
   * useEffect hook to filter products based on selected size and brand,
   * and update the filtered products state accordingly.
   *
   * @param {Array} collectionProducts - The array of products to be filtered.
   * @param {string} selectedSize - The selected size for filtering products.
   * @param {string} selectedBrand - The selected brand for filtering products.
   */
  useEffect(() => {
    // Create a copy of the collectionProducts array to work with
    let products = collectionProducts;

    // If a specific size is selected, filter products based on that size
    if (selectedSize !== 'all sizes') {
      products = products.filter(product =>
        product.variants.edges.some(variantEdge =>
          variantEdge.node.selectedOptions.some(
            option => option.name === 'Sizes' && option.value === selectedSize
          )
        )
      );
    }

    // If a specific brand is selected, filter products based on that brand
    if (selectedBrand !== 'all brands') {
      products = products.filter(product =>
        product.brand === selectedBrand
      );
    }

    // Update the state with the filtered products
    setFilteredProducts(products);
  }, [collectionProducts, selectedSize, selectedBrand]);


  const getHeroImages = () => {
    return isMobile ? MobileHeroImages : HeroImages;
  };
  const getImage = (product) => {
    if (product.featuredImage) {
      return product.featuredImage !== null && product.featuredImage.originalSrc;
    }
    if (product.featuredImage === null) {
      return "https://res.cloudinary.com/dm5xjgl02/image/upload/v1668198427/Safe-73-jpg_t8olqa.jpg"
    }
    return product.images && product.images[0] ? product.images[0] : '';
  }
  const onGenderTabClicked = (category) => {
    props.getTrendingProductsForGender(category);
    setSelectedInnerTab(category);
  };
  const onCategoryTabClicked = (category) => {
    setSelectedOuterTab(category);
    if (category === 'apparel') {
      setSelectedInnerTab('clothing');
      const pageSize = 20;
      props.getCollectionProductsByHandle('clothing', pageSize, selectedSize, selectedBrand);
      return;
    } else {
      setSelectedInnerTab("men");
    }
  };

  const onCollectionTabClicked = () => {
    props.getCollectionProductsByHandle('clothing', 50);
  };

  const getSizes = () => {
    let sizes = ['all sizes'];
    collectionProducts.forEach(product => {
      product.variants.edges.forEach(variantEdge => {
        variantEdge.node.selectedOptions.forEach(option => {
          if (option.name === 'Sizes' && option.value !== 'Default Title' && !sizes.includes(option.value)) {
            sizes.push(option.value);
          }
        });
      });
    });
    return sizes;
  };

  const getBrands = () => {
    let brands = ['all brands'];
    collectionProducts.forEach(product => {
      if (!brands.includes(product.brand)) {
        brands.push(product.brand);
      }
    });
    return brands;
  };

  const renderSearchResults = useMemo(() => {
    return search !== '' && (
      <AutoCompleteContainer>
        {props.product.loadingProducts ? (
          <SearchBarContainer>
            <Spinner animation="border" variant="primary" />
          </SearchBarContainer>
        ) : (
          props.product.products.map((product) => (
            <AutoCompleteItem
              to={{
                pathname: product.handle ? `/product/${product.handle}/?shopify=true` : `/product/${product.stockXHandle}`,
              }}
            >
              <ProductImage src={getImage(product)} />
              <ProductTextContainer>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductSKU>{product.sku}</ProductSKU>
              </ProductTextContainer>
            </AutoCompleteItem>
          ))
        )}
      </AutoCompleteContainer>
    );
  }, [search, props.product.loadingProducts, props.product.products]);
  const sectionToRender = useMemo(() => {
    switch (selectedOuterTab) {
      case 'sneakers': {
        return (
          <>
            <ProductSection
              headerTitle={"New Releases"}
              headerButtonText={"See All"}
              products={newReleasesForGender}
            />
            <ProductSection
              headerTitle={"Trending All"}
              headerButtonText={"See All"}
              products={trendingAll}
            />
            <ProductSection
              headerTitle={"Trending Nike"}
              headerButtonText={"See All"}
              products={trendingNike}
            />
            <ProductSection
              headerTitle={"Trending Jordan"}
              headerButtonText={"See All"}
              products={trendingJordan}
            />
            <ProductSection
              headerTitle={"Trending Adidas"}
              headerButtonText={"See All"}
              products={trendingAdidas}
            />
            <RoundButton
              onClick={() => history.push("/browse")}
              width="25%"
              mobileWidth={"calc(100% - 40px)"}
            >
              {Copy.HomePageBottomButton}
            </RoundButton>
          </>
        )
      }
      case 'apparel': {
        return (
          <>
            <ApparelFilters
              sizes={getSizes()}
              brands={getBrands()}
              selectedSize={selectedSize}
              selectedBrand={selectedBrand}
              setSelectedSize={setSelectedSize}
              setSelectedBrand={setSelectedBrand}
            />
            <ProductSection
              headerTitle={"Apparel"}
              headerButtonText={"See All"}
              products={filteredProducts}
            />
          </>
        )
      }
      default: (
        <p>default</p>
      )
    }
  }, [selectedOuterTab, newReleasesForGender, trendingAll, trendingNike, trendingJordan, trendingAdidas, selectedSize, selectedBrand, filteredProducts])

  const innerTabsToRender = useMemo(() => {
    switch (selectedOuterTab) {
      case 'sneakers': {
        return (
          <>
            <Tab
              selectedTab={selectedInnerTab}
              tab={"men"}
              onTabClicked={onGenderTabClicked}
            />
            <Tab
              selectedTab={selectedInnerTab}
              tab={"women"}
              onTabClicked={onGenderTabClicked}
            />
            <Tab
              selectedTab={selectedInnerTab}
              tab={"child"}
              onTabClicked={onGenderTabClicked}
            />
          </>
        )
      }
      case 'apparel': {
        return (
          <>
            <Tab
              selectedTab={selectedInnerTab}
              tab={"clothing"}
              onTabClicked={onCollectionTabClicked}
            />
          </>
        )
      }
      default: (
        <p>default</p>
      )
    }
  }, [selectedOuterTab, selectedInnerTab])

  const searchProducts = (newSearchText) => {
    //new search text to refresh, since set state is not synchrounous

    const data = {
      query: newSearchText || search,
    };
    props.getProducts(data);
  };
  if (shouldRedirectToBrowse) {
    return <Redirect to={`/browse?search=${search}`} />;
  }
  return (
    <Container>
      <Hero images={getHeroImages()} />
      <CategoryContainer>
        <StickyHeader>
          <TabSearchContainer>
            <OuterTabs>
              <Tab
                selectedTab={selectedOuterTab}
                tab={"sneakers"}
                onTabClicked={onCategoryTabClicked}
              />
              <Tab
                selectedTab={selectedOuterTab}
                tab={"apparel"}
                onTabClicked={onCategoryTabClicked}
              />
            </OuterTabs>
            <SearchContainer>
              <Input
                icon={SearchImage}
                placeholder="Search by title, sku, brand.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setShouldRedirectToBrowse(true);
                  }
                }}
              />
              {renderSearchResults}
            </SearchContainer>
          </TabSearchContainer>
          <InnerTabContainer>
            <Tabs>
              {innerTabsToRender}
            </Tabs>
          </InnerTabContainer>
        </StickyHeader>
        <ProductSections>
          {props.product.loadingProductsSections ? (
            <LoadingScreen />
          ) : (
            sectionToRender
          )}
        </ProductSections>
      </CategoryContainer>
    </Container>
  );
};

export default connectScreen(Home);
