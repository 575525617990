import axios from 'axios';
import {
    API_HEADER,
    BIDS_API
} from '../../../constants/API';
import {
    CREATE_BID
} from '../types';


const createBid = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_BID,
                creatingBid: true,
                createdBid: null
            });
            const { token } = getState().UserReducer;
            const bidResponse = await axios.post(`${BIDS_API}`, data,  API_HEADER(token));
            console.log(bidResponse)
            dispatch({
                type: CREATE_BID,
                creatingBid: false,
                createdBid: bidResponse.createdBid
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: CREATE_BID,
                creatingBid: false,
                createdBid: null
            })
            throw e;
        }
    }
}

export { createBid };