import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
//import ExpressCheckoutButton from './ExpressCheckoutButton';
import backIcon from '../assets/images/previous_not.svg';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Button from './Button';
import VisaIcon from '../assets/images/visa.svg';
import MasterCardIcon from '../assets/images/mastercard.svg';
import AmericanExpressIcon from '../assets/images/americanexpress.svg';
import JCBIcon from '../assets/images/jcb.png';
import DiscoverIcon from '../assets/images/discover.svg';
import DinersClubIcon from '../assets/images/diners.svg';
import UnionPayIcon from '../assets/images/union.svg';
import CardIcon from '../assets/images/card.svg';

const Container = styled.div`
    width: 50vw;
    padding: 80px 80px 80px 80px;
    background: ${Colors.lightGrey};
    @media (max-width: 780px) {
        padding: 50px 40px 40px 40px;
        width: 100vw;
        min-height: 60vh;
    }
`;
const BackIcon = styled.img`
    width: 15px;
    height: 15px;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const Title = styled.p`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-left: 5px;
`;

const ListContainer = styled.div`
    margin-top: 20px;
`;

const AddPaymentMethodButton = styled(Button)`
    margin-top: 20px;
    background: ${Colors.blue};
    border: none;
    color: ${Colors.white};
    text-transform: uppercase;
    font-size: 12px;
    width: 100%;
`
const PaymentMethodContainer = styled.div`
    display: flex;
    background: ${Colors.white};
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: ${Colors.midGrey} 1px solid;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const CardContainer = styled.div`
    display: flex;
    align-items: center;

`;

const CardImage = styled.img`
    width: 40px;
    height: 40px;
`;
const CardDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;
const CardNumber = styled.p`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 12px;
`;
const CardExpiry = styled.p`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY};
    font-size: 10px;
    color: ${Colors.midGrey};
`;
const DeleteButton = styled(Button)`
    background: ${Colors.red};
    border: none;
    height: 40px;
    color: ${Colors.white};
    @media (max-width: 780px) {
        max-width: 40px;
        font-size: 8px;
    }
`;


const PaymentForm = (props) => {
    const { 
        onBack, 
        paymentMethods, 
        loadingPaymentMethods, 
        goToAddPaymentForm, 
        deletePaymentMethod, 
        onSelect, 
        // showExpressCheckout = false,
        // fees,
        // productTitle,
        // size,
        // createExpressCheckout,
        // createExpressOrder
    } = props;

    const selectCardImage = (brand) => {
        switch (brand) {
            case 'American Express':
                return AmericanExpressIcon;
            case 'Diners Club':
                return DinersClubIcon;
            case 'Discover':
                return DiscoverIcon;
            case 'JCB':
                return JCBIcon;
            case 'MasterCard':
                return MasterCardIcon;
            case 'UnionPay':
                return UnionPayIcon;
            case 'Visa':
                return VisaIcon;
            default:
                return CardIcon;
        }
    }
    return (
        <Container>
            <TitleContainer
                onClick={() => onBack()}
            >
                {onBack ? <BackIcon src={backIcon} /> : null}
                <Title>Payment Method</Title>
            </TitleContainer>
            {/* {
               // showExpressCheckout && fees.total !== 'TBD' &&
                <ExpressCheckoutButton
                    fees={fees}
                    productTitle={productTitle}
                    size={size}
                    createExpressCheckout={() => createExpressCheckout()}
                    createExpressOrder={(data) => createExpressOrder(data)}
                />
            } */}
            {
                loadingPaymentMethods ?
                    <Spinner animation="border" variant="primary" />
                    :
                    <ListContainer>
                        {paymentMethods && paymentMethods.length > 0 ? paymentMethods.map((paymentMethod, index) =>
                            <PaymentMethodContainer
                                key={paymentMethod.customerPaymentProfileId}
                                onClick={() => onSelect(paymentMethod)}
                            >
                                <CardContainer>
                                    <CardImage src={selectCardImage(paymentMethod.payment.creditCard.cardType)} />
                                    <CardDataContainer>
                                        <CardNumber>{paymentMethod.payment.creditCard.cardNumber}</CardNumber>
                                        <CardExpiry>Expires {paymentMethod.payment.creditCard.expirationDate}</CardExpiry>
                                    </CardDataContainer>
                                </CardContainer>
                                <DeleteButton
                                    onClick={() => deletePaymentMethod(paymentMethod.customerPaymentProfileId)}
                                >
                                    Delete
                                </DeleteButton>
                            </PaymentMethodContainer>

                        ) : null}
                    </ListContainer>
            }
            <AddPaymentMethodButton
                onClick={() => goToAddPaymentForm()}
            >
                Add Payment Method
            </AddPaymentMethodButton>
        </Container>
    )
}

export default PaymentForm;