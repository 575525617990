import axios from "axios";
//import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import connectScreen from "../../redux/connectScreen";
import { Container, ProductTitle, ProductSubTitle, BuyButton } from "./styles";
import Copy from "../../constants/Copy";

const Confirm = (props) => {
  const location = useLocation();
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderPending, setOrderPending] = useState(true);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(async () => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("status");
    setStatus(status);
    const orderToken = searchParams.get("orderToken");

    const orderData = localStorage.getItem("orderData");
    const gtmData = localStorage.getItem("gtmData");
    const orderStatus = localStorage.getItem("orderCreated");

    const parsedOrderData = JSON.parse(orderData);
    const parsedGtmData = JSON.parse(gtmData);

    const data = {
      token: orderToken,
    };

    if (status === "SUCCESS") {
      if(orderStatus === "true"){
        setOrderSuccess(true);
        setOrderPending(false);
        setOrderCancelled(false);
      }else{
        await axios
        .post(
          `${process.env.REACT_APP_API}/api/afterpayConfirmation`,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.user.token}`,
            },
          }
        )
        .then(async (response) => {
          if (response.data.status === "APPROVED") {
            createOrder(parsedOrderData, parsedGtmData);
          }
          if (
            response.data.status === "DECLINED" ||
            response.data.status === "CANCELLED" ||
            response.data.status === "EXPIRED"
          ) {
            setOrderPending(false);
            setOrderSuccess(false);
            setOrderCancelled(false);
          }
        });
      }
      
    } else {
      setOrderPending(false);
      setOrderSuccess(false);
      setOrderCancelled(true);
    }
  }, [location.search]);

  const createOrder = async (data, gtmData) => {
    try {
      await props.createOrder(data);
      if (props.order.failedToCreateOrder) {
        setOrderPending(false);
        setOrderSuccess(false);
        setOrderCancelled(false);
        throw new Error("Failed to create order");
      }
      localStorage.setItem("orderCreated", "true");
      setOrderSuccess(true);
      setOrderPending(false);
      setOrderCancelled(false);
      window.dataLayer.push(gtmData);
    } catch (e) {
      alert(String(e));
      setOrderPending(false);
      setOrderSuccess(false);
      setOrderCancelled(false);
    }
  };

  return (
    <Container>
      {status !== null && <ProductTitle>Order Status</ProductTitle>}
      {status !== null && orderPending && !orderSuccess && (
        <ProductSubTitle>Confirming your order...</ProductSubTitle>
      )}
      {status !== null && !orderPending && !orderSuccess && !orderCancelled && (
        <ProductSubTitle>
          You're payment could not be fully processed at the moment. Please
          contact our support team
        </ProductSubTitle>
      )}
      {status !== null && !orderPending && !orderCancelled && orderSuccess && (
        <ProductSubTitle>{Copy.BuySuccessSubtitle}</ProductSubTitle>
      )}
      {status !== null && !orderPending && !orderSuccess && orderCancelled && (
        <ProductSubTitle>Your order has been cancelled</ProductSubTitle>
      )}
      <Link to="/">
        <BuyButton>{Copy.BuyButtonText}</BuyButton>
      </Link>
    </Container>
  );
};

export default connectScreen(Confirm);
