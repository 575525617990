import axios from 'axios';
import {
    PRODUCTS_API,
    API_HEADER
} from '../../../constants/API';
import {
    GET_TRENDING_PRODUCTS_FOR_GENDER
} from '../types';

const getTrendingProductsForGender = (gender) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_TRENDING_PRODUCTS_FOR_GENDER,
                loadingProductsSections: true,
                trendingAll: [],
                trendingAdidas: [],
                trendingNike: [],
                trendingJordan: [],
                newReleasesForGender: [],
            })
            const { token } = getState().UserReducer
            const allProductsResponse = await axios.get(`${PRODUCTS_API}?gender=${gender}`, API_HEADER(token))
            const adidasProductsResponse = await axios.get(`${PRODUCTS_API}?gender=${gender}&brand=adidas`, API_HEADER(token))
            const nikeProductsResponse = await axios.get(`${PRODUCTS_API}?gender=${gender}&brand=nike`, API_HEADER(token))
            const jordanProductsResponse = await axios.get(`${PRODUCTS_API}?gender=${gender}&brand=jordan`, API_HEADER(token))
            const newReleasesResponseForGender = await axios.get(
              `${PRODUCTS_API}/newReleases?gender=${gender}`,
              API_HEADER(token)
            );

            dispatch({
                type: GET_TRENDING_PRODUCTS_FOR_GENDER,
                loadingProductsSections: false,
                trendingAll: allProductsResponse.data,
                trendingAdidas: adidasProductsResponse.data,
                trendingNike: nikeProductsResponse.data,
                trendingJordan: jordanProductsResponse.data,
                newReleasesForGender: newReleasesResponseForGender.data,
            })

        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_TRENDING_PRODUCTS_FOR_GENDER,
                loadingProductsSections: false,
                trendingAll: [],
                trendingAdidas: [],
                trendingNike: [],
                trendingJordan: [],
                newReleasesForGender: [],
            })
        }
    }
}

export { getTrendingProductsForGender };