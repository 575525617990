import {
    CREATE_BID,
    GET_BIDS,
    DELETE_BID
} from '../actions/types';
const defaultState = {
    createdBid: null,
    creatingBid: false,
    loadingBids: false,
    bids: [],
    deletingBid: false
}
const BidReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_BID:
            return {
                ...state,
                createdBid: action.createdBid,
                creatingBid: action.creatingBid,
            }
        case GET_BIDS:
            return {
                ...state,
                loadingBids: action.loadingBids,
                bids: action.bids
            }
        case DELETE_BID:
            return {
                ...state,
                deletingBid: action.deletingBid
            }
        default:
            return state;
    }
}

export default BidReducer;