const locations = [
  // All images are stored at https://imagekit.io/ and are accessed by gabriel@unit203.com with the password "Jidwod-vazro9-xoxwav"
  {
    name: "CF Fairview",
    address: "Unit 1064 - 1800 Sheppard Ave E, North York, ON M2J 5A7",
    image: "https://ik.imagekit.io/wllxedb0e/tr:w-600/FAIRVIEW.jpg",
    googleMapsURL:
      "https://www.google.com/maps/place/SAFE/@43.777994,-79.3462801,17z/data=!3m2!4b1!5s0x89d4d2f06e8afd13:0xb43516d7d7748785!4m5!3m4!1s0x89d4d39d0e10b3c9:0x8f4caefb9ef3daa0!8m2!3d43.7779901!4d-79.3440914",
  },
  {
    name: "CF Georgian Mall",
    address: "509 Bayfield St Barrie ON L4M 4Z8 Canada",
    image: "https://ik.imagekit.io/wllxedb0e/tr:w-600/MARKVILLE.jpg",
    googleMapsURL:
      "https://www.google.com/maps/place/509+Bayfield+St,+Barrie,+ON+L4M+4Z8/@44.4130869,-79.7106598,17z/data=!3m1!4b1!4m6!3m5!1s0x882aa2efc3eb57ff:0xe1a0db215da57504!8m2!3d44.4130869!4d-79.7080849!16s%2Fg%2F11bw3x4qlq?entry=ttu",
  },
  {
    name: "CF Limeridge",
    address: "Unit 227B - 999 Upper Wentworth St, Hamilton, ON L9A 4X5",
    image: "https://ik.imagekit.io/wllxedb0e/tr:w-600/LIMERIDGE.jpg",
    googleMapsURL:
      "https://www.google.com/maps/place/SAFE/@43.2181348,-79.8657695,17z/data=!3m2!4b1!5s0x882c9a4fbc626e91:0x3529fdafb052fb55!4m5!3m4!1s0x882c9b1a6e5be991:0x47a31a280e633413!8m2!3d43.218131!4d-79.8612848",
  },
  {
    name: "CF Masonville Place",
    address: "Box 65 / Unit 142 - 1680 Richmond St, London, ON N6G 3Y9",
    image: "https://ik.imagekit.io/wllxedb0e/tr:w-600/MASONVILLE.jpeg",
    googleMapsURL:
      "https://www.google.com/maps/place/CF+Masonville+Place,+1680+Richmond+St,+London,+ON+N6G+3Y9/@43.025599,-81.2805688,17z/data=!3m1!4b1!4m5!3m4!1s0x882eee434d07c883:0x635fdba5f14923e4!8m2!3d43.025599!4d-81.2783801",
  },
  {
    name: "CF Sherway Gardens",
    address: "Unit 1834 - 25 The West Mall, Etobicoke, ON M9C 1B8",
    image: "https://ik.imagekit.io/wllxedb0e/tr:w-600/SHERWAY2.jpg",
    googleMapsURL: "https://goo.gl/maps/P7PRL5a5eShBHNvBA",
  },
];
  
  export default locations;