import firebase from 'firebase';
import axios from 'axios';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API';
import {
    LOGIN_LOADING
} from '../types';


async function getUser(fbUserId, token) {
    /*
        Creates user in database with fbuid
    */
    try {
        const userResponse = await axios.post(USERS_API, { fbUserId }, API_HEADER(token));
        // console.log(userResponse)
        return userResponse.data;
    } catch (e) {
        console.log(e)
        return false
    }
}


async function getToken() {
    /*
        Get JWT for user
    */
    try {
        return await firebase.auth().currentUser.getIdToken(true);
    } catch (e) {
        //TODO: message error
        return false;
    }
}

const signOut = () => {
    return async (dispatch) => {
        dispatch({
            type: LOGIN_LOADING,
            loginLoading: false,
            isLoggedIn: false,
            fbUser: {},
            dbUser: {}
        });
        await firebase.auth().signOut();
    }
}

const setUserState = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_LOADING,
            loginLoading: true,
            isLoggedIn: false
        })
        
        firebase.auth().onAuthStateChanged(async user => {
            // console.log({user});
            if (user) {
                //get db user
                const token = await getToken();
                // console.log(token)
                console.log(user.isAnonymous)
                if (user.isAnonymous) {
                    dispatch({
                        type: LOGIN_LOADING,
                        loginLoading: false,
                        isLoggedIn: false,
                        fbUser: user,
                        dbUser: null,
                        token
                    })
                } else {
                    if (token) {
                       
                        const dbUser = await getUser(user.uid, token);
                        
                        dispatch({
                            type: LOGIN_LOADING,
                            loginLoading: false,
                            isLoggedIn: true,
                            fbUser: user,
                            dbUser,
                            token
                        })
                        return true;
                    }
                }
            } else {
                await firebase.auth().signInAnonymously();

                //no fb user
                dispatch({
                    type: LOGIN_LOADING,
                    loginLoading: true,
                    isLoggedIn: false,
                    fbUser: {},
                    dbUser: {}
                })
            }
        })
    }
}

export { setUserState, getUser, signOut };