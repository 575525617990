const { default: Colors } = require("../constants/Colors");
const { default: Fonts } = require("../constants/Fonts");

const styled = require("styled-components").default;

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.lightGrey};
  border-top: 1px solid ${Colors.lightGrey};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  width: 130px;
  height: 130px;
  margin-right: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const StatusContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const StatusLabel = styled.p`
  font-family: ${Fonts.PRIMARY};
  margin-right: 3px;
  margin-bottom: 5px;
`;
const StatusText = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${(props) => props.color || Colors.midGrey};
`;
const StatusRow = styled.div`
  display: flex;
`;

const ViewProductText = styled.span`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.primary};
  cursor: pointer;
`;

const redirectToProductPage = (productHandle) => {
  window.location.href = `https://shopwithsafe.com/product/${productHandle}`;
};

const ProductCard = ({ order }) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={order.stockXProduct.images[0]} alt="product" />
      </ImageContainer>
      <StatusContainer>
        <StatusContainerColumn>
          <StatusRow>
            <StatusLabel>Product title:</StatusLabel>
            <StatusText color={Colors.midGrey}>
              {order?.stockXProduct.title}
            </StatusText>
          </StatusRow>
          <StatusRow>
            <StatusLabel>Size:</StatusLabel>
            <StatusText color={Colors.midGrey}>
              {order?.stockXProduct.selectedSize}
            </StatusText>
          </StatusRow>
          <StatusRow>
            <StatusLabel>Status:</StatusLabel>
            <StatusText color={Colors.midGrey}>{order?.status}</StatusText>
          </StatusRow>
          <StatusRow>
            <ViewProductText
              onClick={() =>
                redirectToProductPage(order?.stockXProduct.stockXHandle)
              }
            >
              View Product
            </ViewProductText>
          </StatusRow>
        </StatusContainerColumn>
      </StatusContainer>
    </Container>
  );
};

export default ProductCard;
