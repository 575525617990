import { Row, Col } from 'antd';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const BuyButton = styled.div`
    height: 50px;
    border-radius:3px;
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    background-color: ${props => props.selectedOption === props.buyButton ? Colors.blue : Colors.lightBackground}
`

const ButtonTitle = styled.div`
    font-family: ${Fonts.PRIMARY};
    color: ${props => props.selectedSize === props.size ? Colors.yellow : Colors.midGrey};
`

const BuyGrid = (props) => {
  const {onOptionClick, selectedOption, buyButtons} = props;

  return (
    <Row gutter={16}>
      {buyButtons.map((buyButton, index) => (
        <Col key={index} span={12}>
          <BuyButton
            onClick={() => onOptionClick(buyButton)}
            selectedOption={selectedOption}
            buyButton={buyButton}
            >
          <ButtonTitle
            selectedOption={selectedOption}
            buyButton={buyButton}
          >
            {buyButton}
          </ButtonTitle>
          </BuyButton>
        </Col>
      ))}
    </Row>
  );
}

export default BuyGrid;