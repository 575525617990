import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';

const Container = styled.div`
    background-color: ${Colors.blue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 5%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    @media (max-width: 780px) {
        padding-left: 0;
    }
`


const StickyHeader = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    )
}

export default StickyHeader;