import Helmet from 'react-helmet'

const handleMetaTags = (title) => {
  if(title === 'Adidas') {
    console.log(title);
    return (
      <Helmet>
        <title>Authentic Adidas & Yeezy Deadstock Sneakers | Safe</title>
        <meta name="title" content="Authentic Adidas & Yeezy Deadstock Sneakers | Safe" />
        <meta name="description" content="Safe carries authentic Adidas & Yeezy Sneakers available to purchase in Canada including Yeezy v2 350, Yeezy 500, Yeezy 700, Yeezy Slide & Yeezy Foam Runner" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shopwithsafe.com/browse/Adidas" />
        <meta property="og:title" content="Authentic Adidas & Yeezy Deadstock Sneakers | Safe" />
        <meta property="og:description" content="Safe carries authentic Adidas & Yeezy Sneakers available to purchase in Canada including Yeezy v2 350, Yeezy 500, Yeezy 700, Yeezy Slide & Yeezy Foam Runner" />
        <meta property="og:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
        <meta property="product:retailer_item_id" content="" />
        <meta property="product:price:amount" content=""/>
        <meta property="product:price:currency" content="CAD"/>
        <meta property="product:availability" content=""/>

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://shopwithsafe.com/browse/Adidas" />
        <meta property="twitter:title" content="Safe | Exclusive Sneakers Available For Everyone" />
        <meta property="twitter:description" content="SAFE is Canada’s Online Streetwear sneaker store, making your favourite kicks accessible for everyone. Brands include Jordan, Nike, adidas, Bape, New Balance, Essentials, Vlone, Anti Social Social Club & revenge. " />
        <meta property="twitter:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
      </Helmet>
    )
  } else if(title === 'Nike') {
    return (
      <Helmet>
        <title>Authentic Nike Deadstock Sneakers | Safe</title>
        <meta name="description" content="Safe carries authentic mens & womens Nike Sneakers available to purchase in Canada ranging from Jordan 1’s to Nike SB Dunk as well as Travis Scotts." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shopwithsafe.com/browse/Nike" />
        <meta property="og:title" content="Authentic Nike Deadstock Sneakers | Safe" />
        <meta property="og:description" content="Safe carries authentic mens & womens Nike Sneakers available to purchase in Canada ranging from Jordan 1’s to Nike SB Dunk as well as Travis Scotts." />
        <meta property="og:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
        <meta property="product:retailer_item_id" content="" />
        <meta property="product:price:amount" content=""/>
        <meta property="product:price:currency" content="CAD"/>
        <meta property="product:availability" content=""/>

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://shopwithsafe.com/browse/Nike" />
        <meta property="twitter:title" content="Authentic Nike Deadstock Sneakers | Safe" />
        <meta property="twitter:description" content="Safe carries authentic mens & womens Nike Sneakers available to purchase in Canada ranging from Jordan 1’s to Nike SB Dunk as well as Travis Scotts." />
        <meta property="twitter:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
      </Helmet>
    )
  } else if(title === 'Jordan'){
    return (
      <Helmet>
        <title>Authentic Jordan Deadstock Sneakers | Safe</title>
        <meta name="description" content="Safe carries authentic mens & womens Jordan Sneakers available to purchase in Canada including Jordan 1’s, Jordan 3’s, Jordan 4’s, Jordan 11’s, etc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shopwithsafe.com/browse/Jordan" />
        <meta property="og:title" content="Authentic Jordan Deadstock Sneakers | Safe" />
        <meta property="og:description" content="Safe carries authentic mens & womens Jordan Sneakers available to purchase in Canada including Jordan 1’s, Jordan 3’s, Jordan 4’s, Jordan 11’s, etc." />
        <meta property="og:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
        <meta property="product:retailer_item_id" content="0" />
        <meta property="product:price:amount" content=""/>
        <meta property="product:price:currency" content="CAD"/>
        <meta property="product:availability" content=""/>

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://shopwithsafe.com/browse/Jordan" />
        <meta property="twitter:title" content="Authentic Jordan Deadstock Sneakers | Safe" />
        <meta property="twitter:description" content="Safe carries authentic mens & womens Jordan Sneakers available to purchase in Canada including Jordan 1’s, Jordan 3’s, Jordan 4’s, Jordan 11’s, etc." />
        <meta property="twitter:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
      </Helmet>
    )
  }
}

export default handleMetaTags;