import React from 'react';
import Copy from '../../constants/Copy';
import heroImage from '../../assets/images/how-it-works-hero.png';
import locations from '../../constants/locations';
import locationIcon from '../../assets/images/location-icon.png';
import {
    Container,
    LocationContainer,
    LocationItem,
    LocationImage,
    LocationText,
    LocationSubText,
    LocationIcon,
    HeroTextContainer,
    HeroImageContainer,
    Hero,
    HeroText,
    HeroSubText,
    HeroImage,
} from './styles';

const openGoogleMaps = (url) => {
    window.open(url, '_blank');
}

const Locations = () => {
    return (
        <Container>
            <Container>
                <Hero>
                    <HeroTextContainer>
                        <HeroText>
                            {Copy.locationsTite}
                        </HeroText>
                        <HeroSubText>
                            {Copy.locationsSubtitle}
                        </HeroSubText>
                    </HeroTextContainer>
                    <HeroImageContainer>
                        <HeroImage src={heroImage} />
                    </HeroImageContainer>
                </Hero>
            </Container>
            <Container>
                {locations.map((location, index) => {
                    return (
                        <LocationContainer onClick={() => openGoogleMaps(location.googleMapsURL)} currentIndex={index}>
                            <LocationImage currentIndex={index} src={location.image} />
                            <LocationItem>
                                <LocationText>
                                    {location.name}
                                </LocationText>
                                <LocationSubText>
                                    <LocationIcon src={locationIcon} />
                                    {location.address}
                                </LocationSubText>
                            </LocationItem>
                        </LocationContainer>
                    )
                })}
            </Container>
        </Container>
    )
}

export default Locations;