import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const ListContainer = styled(Container)`
    width: calc(300px - 50px);
    padding-left: 0px !important;
    @media (max-width: 780px) {
        width: auto;
    }
    
`

const SizeCard = styled(Col)``

const Size = styled.div`
    height: 40px;
    margin-bottom: 15px;
    border-radius:3px;
    max-width: 60px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    background-color: ${props => props.selectedSize === props.size ? Colors.blue : Colors.lightBackground}
`
const SizeTitle = styled.div`
    font-size: 11px;
    font-family: ${Fonts.PRIMARY};
    color: ${props => props.selectedSize === props.size ? Colors.yellow : Colors.midGrey};
    @media (max-width: 780px) {
        font-size: 14px;
    }
`

const SizeGrid = (props) => {
    const { 
        sizes, 
        onSizeClick, 
        selectedSize ,
        xs = 3,
        sm = 4,
        md = 4,
        lg = 4,
        xl = 4,
        containerStyle
    } = props;
    return (
        <ListContainer style={containerStyle}>
            <Row gutterWidth={15}>
                {
                    sizes.map((size, index) =>
                        <SizeCard
                            key={`$${size} - ${index}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl}
                        >
                            <Size
                                onClick={() => onSizeClick(size)}
                                size={size}
                                selectedSize={selectedSize}
                            >
                                <SizeTitle
                                    size={size}
                                    selectedSize={selectedSize}
                                >
                                    {size}
                                </SizeTitle>
                            </Size>
                        </SizeCard>
                    )}
            </Row>
        </ListContainer>
    )
}

export default SizeGrid;
