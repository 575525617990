import axios from 'axios';
import {
    API_HEADER,
    ORDERS_API
} from '../../../constants/API';
import {
    CREATE_EXPRESS_CHECKOUT
} from '../types';


const createExpressCheckout = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_EXPRESS_CHECKOUT,
                creatingExpressCheckout: true,
                paymentIntent: null
            });
            const { token } = getState().UserReducer;
            const createExpressCheckoutResponse = await axios.post(`${ORDERS_API}/express`, data,  API_HEADER(token));
            console.log(createExpressCheckoutResponse)
            dispatch({
                type: CREATE_EXPRESS_CHECKOUT,
                creatingExpressCheckout: false,
                paymentIntent: createExpressCheckoutResponse.data
            })
            return createExpressCheckoutResponse.data
        } catch (e) {
        
            dispatch({
                type: CREATE_EXPRESS_CHECKOUT,
                creatingExpressCheckout: true,
                paymentIntent: null
            })
            throw e;
        }
    }
}

export { createExpressCheckout };