import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import SizeSelector from './SizeSelector';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    height: calc(100vh - 190px);
    padding: 100px;
    background: ${Colors.white};
    @media (max-width: 780px) {
        width: 100vw;
        padding: 40px;
    }
`;
const Title = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    text-align: center;
`
const Size = styled.p`
    font-family: ${Fonts.PRIMARY};
`
//SELL FEATURE DISABLE const Prices = styled.p`
//     font-family: ${Fonts.PRIMARY};
//     margin-bottom: 5px;
// `
const Image = styled.img`
    width: 80%;
    height: auto;
   
`


const CheckoutTab = (props) => {
    const {
        title,
        images,
        //SELL FEATURE DISABLE       highestBid,
        //SELL FEATURE DISABLE        lowestAsk,
        size,
        sizeType = 'label',
        sizes,
        onSizeClick,
        labelText,
        selectedSize
    } = props;
    return (
        <Container>
            <Title>{title}</Title>
            {/* SELL FEATURE DISABLE   <Prices>Highest Bid: ${highestBid} | Lowest Ask: ${lowestAsk} </Prices> */}
            { sizeType === 'label' ? <Size>Size {size}</Size> : null}

            <Image src={images[0] || ''} />
            { sizeType === 'selector' ? <SizeSelector
                sizes={sizes}
                onSizeClick={onSizeClick}
                selectedSize={selectedSize}
                labelText={labelText}
            />
                : null}

        </Container>
    )
}

export default CheckoutTab;