const getProductPrice = (size, product) => {
    if (
        product &&
        product.Listings &&
        Array.isArray(product.Listings)
    ) {
        const listingsForSize = product.Listings.filter(listing => { return listing.size === size });
        if (
            listingsForSize &&
            listingsForSize.length > 0
        ) {
            const lowestPricedListing = listingsForSize.reduce((prev, curr) => Number(prev.price) < Number(curr.price) ? prev : curr);
            if (lowestPricedListing) {
                return { compareAt: lowestPricedListing.compareAt, price: lowestPricedListing.price, id: lowestPricedListing.id };
            }
        }
        return 'N/A';
    }
    return null;
}

export default getProductPrice;