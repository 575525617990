import React from 'react';
import styled from 'styled-components';
const Container = styled.div``;

const Blog = () => {
    return (
        <Container>

        </Container>
    )
}

export default Blog;