import { Modal } from "antd";
import OrderDetails from "./OrderDetails";
import OrderStatusProgressBar from "./OrderStatusProgressBar";
import SectionDetails from "./SectionDetails";
import ProductCard from "./ProductCard";
import Button from "./Button";
import Colors from "../constants/Colors";
const styled = require("styled-components").default;

const OrderModal = ({ showOrderModal, setShowOrderModal, order }) => {
  const statusToCompare = [
    "Pending",
    "Confirmed",
    "Enroute",
    "Received",
    "Pending Verification",
    "Verified",
    "Waiting to be packaged",
    "Shipped",
  ];

  const handleStatusChange = () => {
    if (order && order.status) {
      const index = statusToCompare.indexOf(order.status) + 1;
      return index;
    }
  };

  const handleOk = () => {
    setShowOrderModal(false);
  };

  const handleCancel = () => {
    setShowOrderModal(false);
  };

  return (
    <Modal
      footer={null}
      visible={showOrderModal}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{
        maxHeight: "calc(100vh - 20vh)",
        overflowY: "auto",
        paddingBottom: "0px",
      }}
      style={{ top: "15vh" }}
    >
      <OrderDetails order={order} />
      <ProductCard order={order} />
      <OrderStatusProgressBar order={order} index={handleStatusChange()} />
      <SectionDetails order={order} type={"Price Details"} />
      <SectionDetails order={order} type={"Payment Method"} />
      {order && order.status.toLowerCase() === "shipped" && (
        <SaveButton onClick={() => {window.open(order.orderShippingLabel, "_blank");}}>
        Track Shipment
      </SaveButton>  
      )}
    </Modal>
  );
};

const SaveButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${Colors.blue};
  border: none;
  color: ${Colors.white};
  text-transform: uppercase;
`;

export default OrderModal;
