import React, { useState } from "react";
import firebase from "firebase";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import styled from "styled-components";
import connectScreen from "../../redux/connectScreen";
import Tab from "../../components/Tab";
import Tabs from "../../components/Tabs";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import Button from "../../components/Button";
import Copy from "../../constants/Copy";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 90px - 78px);
  @media (max-width: 780px) {
    padding-top: 0px;
  }
`;
const Card = styled.div`
  background: ${Colors.lightGrey};
  padding-bottom: 20px;
  width: 400px;
  @media (max-width: 780px) {
    width: 100%;
    min-height: 100vh;
  }
`;

const AuthForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const FormContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled(Tabs)`
  width: 100% !important;
`;
const TabContainer = styled(Tab)`
  width: 100% !important;
`;

const FormInput = styled.input`
  margin-top: 10px;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  padding-left: 20px;
  font-family: ${Fonts.PRIMARY};
  ::placeholder {
    font-family: Futura;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #94a1b0;
    opacity: 0.59;
  }
  @media (max-width: 780px) {
    font-size: 16px;
  }
`;

const ForgotPassword = styled.p`
  font-family: ${Fonts.PRIMARY};
  margin-top: 20px;
`;

const FormButton = styled(Button)`
  width: 100%;
  border: none;
  padding: 20px 0px 20px 0px;
`;

const ForgotPasswordTitle = styled.p`
  font-family: ${Fonts.PRIMARY_BOLD};
`;
const ForgotPasswordSubTitle = styled.p`
  font-family: ${Fonts.PRIMARY};
`;
const FormError = styled.p`
  margin-top: 10px;
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.red};
`;

const PasswordWarningText = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.midGrey};
  margin: 10px;
  font-size: 12px;
`;

const Login = (props) => {
  const [selectedAuthForm, setSelectedAuthForm] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const login = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      setError(e.message);
    }
  };

  const signup = async () => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      await firebase.auth().currentUser.sendEmailVerification();
    } catch (e) {
      setError(e.message);
    }
  };

  const forgotPassword = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setSelectedAuthForm("login");
      message.success(`Sent reset email to ${email}`);
    } catch (e) {
      setError(e.message);
    }
  };

  const ForgotPasswordForm = () => (
    <FormContainer>
      <ForgotPasswordTitle>{Copy.ForgotPasswordTitle}</ForgotPasswordTitle>
      <ForgotPasswordSubTitle>
        {Copy.forgotPasswordSubTitle}
      </ForgotPasswordSubTitle>
      <FormInput
        placeholder="Email Address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        type="email"
      />
      <FormError>{error}</FormError>
      <FormButton onClick={() => forgotPassword()}>
        {Copy.forgotPasswordButton}
      </FormButton>
    </FormContainer>
  );

  const LoginForm = () => (
    <FormContainer>
      <FormInput
        placeholder="Email Address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        type="email"
      />
      <FormInput
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
      />
      <FormError>{error}</FormError>
      <FormButton onClick={() => login()}>{Copy.loginButton}</FormButton>
      <ForgotPassword onClick={() => setSelectedAuthForm("forgotPassword")}>
        Forgot Password?
      </ForgotPassword>
    </FormContainer>
  );

  const SignupForm = () => (
    <FormContainer>
      <FormInput
        placeholder="Username"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <FormInput
        placeholder="Email Address"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        type="email"
      />
      <FormInput
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
      />
        <PasswordWarningText>{Copy.passwordWarningText}</PasswordWarningText>

      <FormError>{error}</FormError>

      <FormButton onClick={() => signup()}>{Copy.signupButton}</FormButton>
    </FormContainer>
  );

  if (props.user.isLoggedIn) {
    return <Redirect to="/account" />;
  }

  return (
    <Container>
      <Card>
        <TabsContainer>
          <TabContainer
            selectedTab={selectedAuthForm}
            onTabClicked={(tab) => setSelectedAuthForm(tab)}
            tab={"login"}
            fontColor={Colors.black}
          />
          <TabContainer
            selectedTab={selectedAuthForm}
            onTabClicked={(tab) => setSelectedAuthForm(tab)}
            tab={"signup"}
            fontColor={Colors.black}
          />
        </TabsContainer>
        <AuthForm>
          {selectedAuthForm === "signup"
            ? SignupForm()
            : selectedAuthForm === "login"
            ? LoginForm()
            : ForgotPasswordForm()}
        </AuthForm>
      </Card>
    </Container>
  );
};

export default connectScreen(Login);
