import React from "react";
import styled from 'styled-components';
import Afterpaycheckout from '../../assets/images/Afterpay_Checkout_Button_Mint-Black@0.5.png'

const AffirmLabelContainer = styled.div`
  background-color: #ffffff;
  border: none;
  width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  

`;

const AfterpayLabel = ({ onClick }) => {
  return (
    <div style={{ display: "flex" }}>
      <AffirmLabelContainer>
        <img
          src={Afterpaycheckout}
          alt="Afterpay Button"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: 300,
            height: "auto",
          }}
        />
      </AffirmLabelContainer>
    </div>
  );
};

export default AfterpayLabel;
