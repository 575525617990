import React from 'react';
import styled from 'styled-components';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
const Container = styled.div``;

const Header = styled.div`
    background: ${Colors.lightBackground};
    padding: 20px;
`;
const Title = styled.h2`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY};
`;

const Contact = () => {

    return (
        <Container>
            <Header>
                <Title>
                    Contact
                </Title>
            </Header>
            <ReactTypeformEmbed url="https://l69v3tf7jwe.typeform.com/to/NqmLSrJ0" />
        </Container>
    )
}

export default Contact;