import styled from "styled-components";

const OrderDetails = ({ order }) => {
  const { shippingAddress } = order;

  /**
   * It returns a formatted shipping address if the shipping address exists
   * @returns The shipping address of the order.
   */
  const formatShippingAddress = () => {
    if (shippingAddress) {
      return `${shippingAddress.street1}, ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.country}, ${shippingAddress.zip}`;
    }
  };

  /**
   * It takes the order object and returns the date in a format that's easier to read
   * @returns The date of the order.
   */
  const formatOrderDate = () => {
    if (order && order.createdAt) {
      const date = new Date(order.createdAt);
      // remove the day of the week
      const dateWithoutDay = date.toDateString().split(" ").slice(1).join(" ");
      return dateWithoutDay;
    }
  };

  const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  `;

  const Title = styled.div`
    font-size: 1rem;
    font-weight: 700;
    margin-right: 10px;
    width: 130px;
  `;
  const OrderDetail = styled.div`
    font-size: 1rem;
    max-width: 14rem;
    line-height: 1.2rem;
  `;

  return (
    <div>
      <h1>Order Details</h1>
      <Container>
        <Title>Order Number</Title>
        <OrderDetail>{order.id}</OrderDetail>
      </Container>
      {order?.status.toLowerCase() === "shipped" && (
        <Container>
          <Title>Ship To</Title>
          <OrderDetail>{formatShippingAddress()}</OrderDetail>
        </Container>
      )}
      <Container>
        <Title>Order Date</Title>
        <OrderDetail>{formatOrderDate()}</OrderDetail>
      </Container>
    </div>
  );
};

export default OrderDetails;
