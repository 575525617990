import React from 'react';
import { Spinner } from "react-bootstrap";
import styled from 'styled-components';
import addressIcon from '../assets/images/account-nav/address_selected.svg';
import Discounts from '../assets/images/account-nav/discount.png';
import notificationIcon from '../assets/images/account-nav/notification_selected.svg';
import paymentIcon from '../assets/images/account-nav/payment_selected.svg';
import nextIcon from '../assets/images/next_not.svg';
import BidExpiryOptions from '../constants/BidExpiryOptions';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Container = styled.div`
    margin-left: 40px;
    margin-right: 40px;
    padding: 40px;
    padding-top: 0px;
    @media (max-width: 780px) {
        padding: 0px;
        margin-bottom: 100px;
    }
`;
const CheckoutItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  justify-content: space-between;
`;

const DiscountItem = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  justify-content: space-between;
`;

const CheckoutContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const DiscountCheckout = styled.div`
  display: flex;
  @media (max-width: 780px) {
    flex-direction: column;
    flex: 1;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
const Title = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 20px;
`;

const NextIcon = styled.img`
  color: black;
  width: 10px;
  height: 10px;
`;

const ExpiresSelect = styled.select`
  outline: none;
  font-family: ${Fonts.PRIMARY};
  border: none;
  background: none;
`;
const ExpiresOption = styled.option``;

const DiscountInput = styled.input`
  width: 100%;
  height: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-left: 20px;
`;
const Discount = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;
const DiscountButton = styled.button`
  border-radius: 30px;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  width: auto;
  font-family: ${Fonts.PRIMARY};
  margin: 0px 10px;
  @media (max-width: 780px) {
    margin: 10px auto;
    width: 100%;
  }
`;
const ErrorMessage = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 40px;
  color: ${Colors.red};
`;

const CheckoutForm = (props) => {
  const {
    address,
    payment,
    discount,
    discountCode,
    setDiscountCode,
    expiresOn,
    onAddressClick,
    onPaymentClick,
    onExpiryChange,
    onDiscountClick,
    onDiscountCancel,
    applyDiscount,
    loadingDiscount,
    discountErrorMessage,
    showCheckoutForm,
    isAffirm,
  } = props;

  const doesUnitAddressExisit = (unitNumber) => {
    if (unitNumber === null || unitNumber === "") {
      return false;
    } else {
      return true;
    }
  };
  const getSelectedAddress = () => {
    return doesUnitAddressExisit(props.address.unitNumber)
      ? `${props.address.unitNumber} - ${props.address.address}, ${props.address.city}, ${props.address.postal}`
      : `${props.address.address}, ${props.address.city}, ${props.address.postal}`;
  };

  return (
    <Container id="checkout-container">
      <CheckoutItem onClick={() => onAddressClick()}>
        <CheckoutContainer>
          <Icon src={addressIcon} />
          <Title>
            {address ? getSelectedAddress() : "Add Shipping Address"}
          </Title>
        </CheckoutContainer>
        <NextIcon src={nextIcon} />
      </CheckoutItem>
      {address && (
        <CheckoutItem onClick={() => onPaymentClick()}>
          <CheckoutContainer>
            <Icon src={paymentIcon} />
            <Title>
              {payment
                ? `${payment.payment.creditCard.cardNumber}`
                : "Add Card"}
            </Title>
          </CheckoutContainer>
          <NextIcon src={nextIcon} />
        </CheckoutItem>
      )}
      {
        <>
          <DiscountItem onClick={discount ? null : () => onDiscountClick()}>
            <CheckoutContainer>
              {!discount ? (
                <>
                  <Icon src={Discounts} />
                  <Title>Discount code</Title>
                </>
              ) : (
                <DiscountCheckout>
                  <Discount>
                    <Icon src={Discounts} />
                    <DiscountInput
                      type="text"
                      placeholder="Enter discount code"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                    />
                  </Discount>
                  {loadingDiscount ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="md"
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    <>
                      <DiscountButton
                        onClick={() => applyDiscount(discountCode)}
                      >
                        Apply
                      </DiscountButton>
                      <DiscountButton onClick={() => onDiscountCancel()}>
                        Cancel
                      </DiscountButton>
                    </>
                  )}
                </DiscountCheckout>
              )}
            </CheckoutContainer>
          </DiscountItem>
          {discountErrorMessage && (
            <ErrorMessage>{discountErrorMessage}</ErrorMessage>
          )}
        </>
      }
      {expiresOn ? (
        <CheckoutItem>
          <CheckoutContainer>
            <Icon src={notificationIcon} />
            <Title>Expires in {expiresOn} days</Title>
          </CheckoutContainer>
          <ExpiresSelect
            onChange={(e) => onExpiryChange(e.target.value)}
            value={expiresOn}
          >
            {BidExpiryOptions.map((bidExpiryOption) => (
              <ExpiresOption value={bidExpiryOption.value}>
                {bidExpiryOption.label}
              </ExpiresOption>
            ))}
          </ExpiresSelect>
        </CheckoutItem>
      ) : null}
    </Container>
  );
};

export default CheckoutForm;
