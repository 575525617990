import axios from 'axios';
import {
    PRODUCTS_API,
    API_HEADER
} from '../../../constants/API';
import {
    GET_PRODUCTS
} from '../types';


const getProducts = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PRODUCTS,
                loadingProducts: true,
                products: []
            })
            const url = new URL(PRODUCTS_API);
            url.search = new URLSearchParams(data);
            const { token } = getState().UserReducer;
            console.log({ url })
            const productsResponse = await axios.get(url, API_HEADER(token))

            dispatch({
                type: GET_PRODUCTS,
                loadingProducts: false,
                products: productsResponse.data
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_PRODUCTS,
                loadingProducts: false,
                products: false
            })
        }
    }
}

export { getProducts };