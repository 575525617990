import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Copy from '../../constants/Copy';
import heroImage from '../../assets/images/authentication-hero.png';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
const Container = styled.div`
    
`;
const HeroTextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 80px;
    @media (max-width: 780px) {
        padding-left: 0px;

    }
`;
const HeroImageContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
const Hero = styled.div`
    display: flex;
    height: calc(100vh - 90px);
    @media (max-width: 780px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 40px;
    }
`;
const HeroText = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 48px;
`;
const HeroSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 18px;
`;
const HeroImage = styled.img`
    width: 100%;
    object-fit: contain;
    @media (max-width: 780px) {
        max-height: 300px;
    }
`;
const HeroButton = styled(Button)`
    width: 70%;
    background: ${Colors.blue};
    color: ${Colors.white};
    @media (max-width: 780px) {
        width: 100%;
    }
`;

const Features = styled.div`
    display: flex;
    flex-direction: column;
    padding: 80px;
    background: ${Colors.lightBackground};
    @media (max-width: 780px) {
        padding: 40px;
        padding-top: ${props => props.reverse ? '0px' : '40px'};
        flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    }
`;
const FeatureText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 40px;
    margin-bottom: 5px;
`;
const FeatureSubText = styled.div`
    font-family: ${Fonts.PRIMARY};
    font-size: 18px;
    color: ${Colors.midGrey};
`;
const FeatureList = styled(Row)`
    margin-top: 40px;
    margin-bottom: 40px;
`;
const FeatureItem = styled(Col)`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 780px) {
        margin-top: 0px;
        margin-bottom: 0px;
    }

`;
const FeatureItemTextContainer = styled.div`
    
`;

const FeatureItemText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-top: 20px;
`;
const FeatureItemIcon = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 20px;
`;
const FeatureItemSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
`;
const FeatureImage = styled.img`
    width: 80%;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
const FeaturesContainer = styled.div`
    display: flex;
    @media (max-width: 780px) {
        flex-direction: column;
    }
`;
const Authentication = () => {
    return (
        <Container>
            <Hero>
                <HeroTextContainer>

                    <HeroText>
                        {Copy.authenticationTitle}
                    </HeroText>
                    <HeroSubText>
                        {Copy.authenticationSubText}
                    </HeroSubText>

                    <Link to='/browse'>
                        <HeroButton>
                            Browse Sneakers
                        </HeroButton>
                    </Link>

                </HeroTextContainer>
                <HeroImageContainer>
                    <HeroImage src={heroImage} />
                </HeroImageContainer>
            </Hero>
            <FeaturesContainer>
                <Features>
                    <FeatureText>
                        {Copy.authenticationFeatureTitle}
                    </FeatureText>
                    <FeatureSubText>
                        {Copy.authenticationFeatureSubtext}
                    </FeatureSubText>
                    <FeatureList>
                        {Copy.authenticationFeatureOne.map(feature =>
                            <FeatureItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FeatureItemIcon src={feature.icon} />
                                <FeatureItemTextContainer>
                                    <FeatureItemText>
                                        {feature.title}
                                    </FeatureItemText>
                                    <FeatureItemSubText>
                                        {feature.subtext}
                                    </FeatureItemSubText>
                                </FeatureItemTextContainer>
                            </FeatureItem>
                        )}
                    </FeatureList>
                    <FeatureImage src={Copy.authenticationImageOne} />
                </Features>
                <Features reverse>
                    <FeatureImage src={Copy.authenticationImageTwo} />

                    <FeatureList >
                        {Copy.authenticationFeatureTwo.map(feature =>
                            <FeatureItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FeatureItemIcon src={feature.icon} />
                                <FeatureItemTextContainer>
                                    <FeatureItemText>
                                        {feature.title}
                                    </FeatureItemText>
                                    <FeatureItemSubText>
                                        {feature.subtext}
                                    </FeatureItemSubText>
                                </FeatureItemTextContainer>
                            </FeatureItem>
                        )}
                    </FeatureList>
                </Features>
            </FeaturesContainer>
        </Container>
    )
}

export default Authentication;