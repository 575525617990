import axios from 'axios';
import {
    API_HEADER,
    DELETE_PAYMENT_METHOD_API
} from '../../../constants/API';
import {
    DELETE_PAYMENT_METHOD
} from '../types';

const deletePaymentMethod = (cardId) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: DELETE_PAYMENT_METHOD,
                deletingPaymentMethod: true,
            });
            const { token } = getState().UserReducer;
            await axios.delete(`${DELETE_PAYMENT_METHOD_API}/${cardId}`, API_HEADER(token));
            dispatch({
                type: DELETE_PAYMENT_METHOD,
                deletingPaymentMethod: false,
            })
        } catch (e) {
            console.log('Error deleting payment method');
            console.log(e)
            dispatch({
                type: DELETE_PAYMENT_METHOD,
                deletingPaymentMethod: false,
            })
        }
    }
}

export { deletePaymentMethod };