import axios from 'axios';
import {
    API_HEADER,
    PAYMENT_METHOD_API
} from '../../../constants/API';
import {
    GET_PAYMENT_METHODS
} from '../types';


const getPaymentMethods = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PAYMENT_METHODS,
                loadingPaymentMethods: true,
                paymentMethods: []
            });
            const { token } = getState().UserReducer;
            const paymentMethodsResponse = await axios.get(PAYMENT_METHOD_API, API_HEADER(token));
            console.log(paymentMethodsResponse)
            dispatch({
                type: GET_PAYMENT_METHODS,
                loadingPaymentMethods: false,
                paymentMethods: paymentMethodsResponse.data ? paymentMethodsResponse.data : []
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_PAYMENT_METHODS,
                loadingPaymentMethods: false,
                paymentMethods: false
            })
        }
    }
}

export { getPaymentMethods };