import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import firebase from 'firebase';
import styled from 'styled-components';
import SideMenu from '../../components/SideMenu';
import ShippingForm from '../../components/ShippingForm';
import PaymentForm from '../../components/PaymentForm';
import AddPaymentForm from '../../components/AddPaymentForm';
import connectScreen from '../../redux/connectScreen';
import { Spinner } from 'react-bootstrap';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import SearchImage from '../../assets/images/search_image.svg';
import OrderModal from '../../components/OrderModal';
import { Input } from 'antd';

const Container = styled.div`
    min-height: calc(100vh - 90px - 74px);
    max-width: 100vw;
    overflow-x: hidden;
`;
const OrdersContainer = styled.div`
    width: 100%;
    background: ${Colors.lightGrey};
    @media (max-width: 780px) {
        width: 100vw;
        min-height: 60vh;
    }
`;
const SettingsContainer = styled.div`
    padding: 80px 80px 80px 80px;
    background: ${Colors.white};
    height: 100vh;
    overflow-x: hidden;
    @media (max-width: 780px) {
        padding: 50px 40px 40px 40px;
        width: 100vw;
        min-height: 60vh;
    }
`;

const SettingsHeader = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 18px;
    font-weight: 800;
`

const LogoutButton = styled(Button)`
    background: transparent;
    border: none;
    justify-content: flex-end;
    color: ${Colors.midGrey};
    font-size: 12px;
    padding: 0px;
`;
const SaveProfileButton = styled(Button)`
    background: ${Colors.blue};
    border: none;
    color: ${Colors.white};
`;
const ProfileImage = styled.img`
    border-radius: 50%;
    margin-top: 20px;
`;
const InputContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const InputLabel = styled.p`
    font-family: ${Fonts.PRIMARY};
    margin-bottom: 5px;
    color: ${Colors.midGrey};
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TabSearchContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 80px;
    background: ${Colors.blue};
    @media (max-width: 780px) {
        justify-content: center;
        width: 100%;
    }
`

const SearchContainer = styled.div`
    width: 50%;
    margin-right: 40px;
    @media (max-width: 780px) {
        display: none;
    }
`

const ContentContainer = styled.div``;

const TabContent = styled.div`
    max-width: 100%;
    overflow-y: scroll;

`;
const ProductContainer = styled.div`
    background: ${Colors.white};
    display: flex;
    align-items: center;
    padding: 25px;
    border: 1px solid ${Colors.lightGrey};
    width: 100%;
`;
const ProductImage = styled.img`
    width: 150px;
    height: auto;
    object-fit: contain;
`;
const ProductTextContainer = styled.div`
    margin-left: 20px;
    width: 100%;
`;
const ProductDetails = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 12px;
`;
const ProductTitle = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-bottom: 5px;
`;
const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content:  space-between;
    @media (max-width: 780px) {
        flex-direction: column;
    }
`;
const StatusContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
const StatusLabel = styled.p`
    font-family: ${Fonts.PRIMARY};
    margin-right: 3px;
    margin-bottom: 5px;
`;
const StatusText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${props => props.color || Colors.midGrey};
`;
const StatusRow = styled.div`
    display: flex;
`;

const DeleteBidButton = styled(Button)`
    background: transparent;
    border: none;
    color: ${Colors.red};
    font-size: 12px;
    text-align: left;
    padding: 0px;
    justify-content: flex-start;
    outline: none;
`
const OpenLabelButton = styled.a`
    font-family: ${Fonts.PRIMARY};
`
const SaveButton = styled(Button)`
    width: 100%;
    background: ${Colors.blue};
    border: none;
    color: ${Colors.white};
    text-transform: uppercase;
`;


const Auth = (props) => {
    useEffect(() => {
        props.getShippingAddress();
        props.getPaymentMethods();
        getBids();
    }, [])

    const [name, setName] = useState(props.user.fbUser ? props.user.fbUser.displayName : '')
    const [currentPaymentForm, setCurrentPaymentForm] = useState('payment')
    const [selectedOrderTab, onOrderTabClick] = useState('Current');
    const [search, setSearch] = useState('')
    const [showOrderModal, setShowOrderModal] = useState(false);

    const { order } = props.order;

    const SettingsMenu = [
        {
            label: 'Profile',
            key: 'profile'
        },
        {
            label: 'My Orders',
            key: 'orders'
        },
        {
            label: 'Shipping',
            key: 'shipping'
        },
        {
            label: 'Payment Methods',
            key: 'payments'
        },
    ]


    const updateUser = async () => {
        try {
            const user = firebase.auth().currentUser;
            await user.updateProfile({
                displayName: name,
            });
            message.success('Updated Profile')
        } catch (e) {
            message.error(String(e));
        }


    }
    const Profile = () => {
        const { fbUser } = props.user
        const PROFILE_IMAGE = 'https://res.cloudinary.com/dz01ewqnd/image/upload/v1617730312/icons8-male-user-100_gbyoxy.png';
        const profileImage = fbUser.photoURL || PROFILE_IMAGE;
        return (
            <SettingsContainer>
                <SettingsHeader>Profile</SettingsHeader>
                <ProfileImage
                    style={{background: Colors.black}}
                    src={profileImage}
                />
                <InputContainer>
                    <InputLabel>Name</InputLabel>
                    <Input
                        placeholder='Enter your name'
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: name ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`,  marginBottom: "10px", padding: "10px"}}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <InputLabel>Email</InputLabel>
                    <Input
                        placeholder='Enter your name'
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: fbUser.email ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`,  marginBottom: "10px", padding: "10px"}}
                        value={fbUser.email}
                    />
                </InputContainer>
                <ButtonContainer>
                    <SaveProfileButton
                        onClick={() => updateUser()}
                    >
                        Save
                    </SaveProfileButton>
                    <LogoutButton
                        onClick={() => props.signOut()}
                    >
                        Logout
                    </LogoutButton>
                </ButtonContainer>
            </SettingsContainer>
        )
    }
    const getBids = () => {
        props.getBids(search);
        onOrderTabClick('Current');
    }
    const getPendingOrders = () => {
        props.getOrders(`status=Pending&query=${search}`);
        onOrderTabClick('Pending');
    }
    const getShippedOrders = () => {
        props.getOrders(`pendingVerification=false&query=${search}`);
        onOrderTabClick('Shipped');
    }

    const deleteBid = async (id) => {
        await props.deleteBid(id);
        getBids()
    }

    const getOrderWithProductData = (order) => {
        let product = order && order.stockXProduct ? order.stockXProduct : null;
        product = order && order.Listings && Array.isArray(order.Listings) && order.Listings.length > 0 ? order.Listings[0].Product : product;
        product = order && order.Bids && Array.isArray(order.Bids) && order.Bids.length > 0 ? order.Bids[0].Product : product;
        let listing = order && order.stockProduct ? order.stockProduct : null;
        listing = order && order.Listings && Array.isArray(order.Listings) && order.Listings.length > 0 ? order.Listings[0] : listing;
        listing = order && order.Bids && Array.isArray(order.Bids) && order.Bids.length > 0 ? order.Bids[0] : listing;

        return {
            ...order,
            Product: product,
            Listing: listing
        }
    }

    const onSearch = (newSearch) => {
        setSearch(newSearch);
        if (selectedOrderTab === 'Current') {
            props.getBids(newSearch);
        } else if (selectedOrderTab === 'Pending') {
            props.getOrders(`status=Pending&query=${newSearch}`);
        } else {
            props.getOrders(`pendingVerification=false&query=${newSearch}`);
        }
    }

    const renderOrderModal = (data) => {
        Promise.all([
        props.getOrder(data.id)
        ]).then(() => {
            setShowOrderModal(true);
        }
        )
    }

    const ProductCard = ({ data, type = 'bid', labelKey }) => {
        const { Product } = type === 'bid' ? data : getOrderWithProductData(data);
        const productImage = (Product?.images && Product.images.length > 0) ? Product.images[0] : '';
        return (
            <ProductContainer>
                <ProductImage
                    src={productImage}
                />
                <ProductTextContainer>
                    <ProductTitle>{Product?.title}</ProductTitle>
                    <ProductDetails>{Product?.color} Size {data?.size}</ProductDetails>

                    {type === 'bid' ?
                        <StatusContainer>
                            <StatusContainerColumn>
                                <StatusRow>
                                    <StatusLabel>Status:</StatusLabel>
                                    <StatusText color={Colors.green}>
                                        {data?.status}
                                    </StatusText>
                                </StatusRow>
                                <StatusRow>
                                    <StatusLabel>Bid Price:</StatusLabel>
                                    <StatusText color={Colors.midGrey}>
                                        ${data?.price}
                                    </StatusText>
                                </StatusRow>
                            </StatusContainerColumn>
                            <StatusContainerColumn>
                                <DeleteBidButton
                                    onClick={() => deleteBid(data.id)}
                                >
                                    Delete Bid
                            </DeleteBidButton>
                            </StatusContainerColumn>
                        </StatusContainer>
                        : null
                    }
                    {type === 'order' ?
                        <StatusContainer>
                            <StatusContainerColumn>
                                <StatusRow>
                                    <StatusLabel>Status:</StatusLabel>
                                    <StatusText color={Colors.midGrey}>
                                        {data?.status}
                                    </StatusText>
                                </StatusRow>
                                <StatusRow>
                                    <StatusLabel>Total:</StatusLabel>
                                    <StatusText color={Colors.black}>
                                        ${data?.totalPrice}
                                    </StatusText>
                                </StatusRow>
                            </StatusContainerColumn>
                            <StatusContainerColumn>
                                <StatusRow style={{flexDirection: "column", alignItems: "center"}}>
                                    <StatusRow>
                                        <StatusText color={Colors.black}>
                                            {data.pendingVerification ? 'Verification: Pending' : 'Verification: Finished'}
                                        </StatusText>
                                    </StatusRow>
                                    <SaveButton
                                    onClick={() => renderOrderModal(data)}
                                    >
                                        Order Details
                                    </SaveButton>
                                </StatusRow>
                                {data[labelKey] ?
                                    <StatusRow>
                                        <OpenLabelButton
                                            href={data[labelKey]}
                                            target='_blank'
                                        >
                                            View Shipping Label
                                        </OpenLabelButton>
                                    </StatusRow>
                                    : null
                                }
                            </StatusContainerColumn>
                        </StatusContainer>
                        : null
                    }
                </ProductTextContainer>
            </ProductContainer>
        )
    }
    /**
     * It takes an array of orders, and returns a new array of orders sorted by the date they were
     * created
     * @param orders - an array of orders
     * @returns the orders array sorted by the date.
     */
    const sortOrdersByDate = (orders) => {
        return orders.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        })

    };
    const Orders = () => {
        const { bids } = props.bid;
        let { orders } = props.order;
        orders = sortOrdersByDate(orders);
        return (
            <OrdersContainer>
                <TabSearchContainer>
                    <Tabs>
                    {/* SELL FEATURE DISABLE    <Tab
                            selectedTab={selectedOrderTab}
                            tab={'Current'}
                            onTabClicked={() => getBids('')}
                        />

                    */}
                        <Tab
                            selectedTab={selectedOrderTab}
                            tab={'Pending'}
                            onTabClicked={() => getPendingOrders('Pending')}
                        />
                        <Tab
                            selectedTab={selectedOrderTab}
                            tab={'Shipped'}
                            onTabClicked={() => getShippedOrders('Shipped')}
                        />
                    </Tabs>
                    <SearchContainer>
                        <Input
                            icon={SearchImage}
                            placeholder='Search by title'
                            value={search}
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </SearchContainer>
                </TabSearchContainer>
                <ContentContainer>
                    {
                        selectedOrderTab === 'Current' ?
                            <TabContent>
                                {
                                    bids && bids.map(bid =>
                                        <ProductCard
                                            data={bid}
                                        />
                                    )
                                }
                            </TabContent>
                            : null
                    }
                    {
                        selectedOrderTab === 'Pending' ?
                            <TabContent>
                                {
                                    orders.map(order =>
                                        <ProductCard
                                            data={order}
                                            type='order'
                                            labelKey='orderShippingLabel'
                                        />
                                    )
                                }
                            </TabContent>
                            : null
                    }
                    {
                        selectedOrderTab === 'Shipped' ?
                            <TabContent>
                                {
                                    orders.map(order =>
                                        <ProductCard
                                            data={order}
                                            type='order'
                                            labelKey='orderShippingLabel'
                                        />
                                    )
                                }
                            </TabContent>
                            : null
                    }
                </ContentContainer>

            </OrdersContainer >
        )
    }

    const applyShippingAddress = async (newAddress) => {
        await props.saveShippingAddress(newAddress);
        await props.getShippingAddress();
        message.success('Updated Shipping');
    }

    const Shipping = () => {
        const { shippingAddress, loadingShippingAddress } = props.shippingAddress;
        return (
            <div>
                {
                    loadingShippingAddress ?
                        <Spinner animation="border" variant="primary" />
                        :
                        <ShippingForm
                            addressData={shippingAddress || {}}
                            onApply={(newAddress) => applyShippingAddress(newAddress)}
                        />
                }
            </div>
        )
    }


    const openPaymentMethod = () => {
        props.getPaymentMethods();
        setCurrentPaymentForm('payment');
    }

    const createPaymentMethod = async (card, billingAddress) => {
        await props.createPaymentMethod({ card, billingAddress });
        openPaymentMethod();
    }

    const deletePaymentMethod = async (cardId) => {
        await props.deletePaymentMethod(cardId);
        openPaymentMethod();
    }
    const Payments = () => {
        const { paymentMethods, loadingPaymentMethods, creatingPaymentMethod } = props.paymentMethod;
        return (
            <div>
                {
                    currentPaymentForm === 'payment' ?
                        <PaymentForm
                            paymentMethods={paymentMethods}
                            loadingPaymentMethods={loadingPaymentMethods}
                            goToAddPaymentForm={() => setCurrentPaymentForm('addPayment')}
                            deletePaymentMethod={cardId => deletePaymentMethod(cardId)}
                            onSelect={(card) => console.log(card)}
                        />
                        : null
                }
                {
                    currentPaymentForm === 'addPayment' ?
                        <AddPaymentForm
                            onBack={() => openPaymentMethod()}
                            onCreate={(card, billingAddress) => createPaymentMethod(card, billingAddress)}
                            creatingPaymentMethod={creatingPaymentMethod}
                        />
                        : null
                }
            </div>
        )
    }



    const ContentItems = [
        {
            component: () => Profile(),
            key: 'profile'
        },
        {
            component: () => Orders(),
            key: 'orders'
        },
        {
            component: () => <Shipping />,
            key: 'shipping'
        },
        {
            component: () => <Payments />,
            key: 'payments'
        },
    ]

    if (!props.user.isLoggedIn) {
        return (
            <Redirect to='/login' />
        )
    }
    return (
        <Container>
            <SideMenu
                menuItems={SettingsMenu}
                contentItems={ContentItems}
                name={props.user.fbUser.displayName}
            />
            {showOrderModal && <OrderModal order={order} showOrderModal={showOrderModal} setShowOrderModal={setShowOrderModal} />}
        </Container>
    )
}

export default connectScreen(Auth);