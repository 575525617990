import Helmet from 'react-helmet'

const handleCatalogMetaTags = (title,id,description,url,image,price,availability) => {
    return (
      <Helmet>
        <title>Authentic Adidas & Yeezy Deadstock Sneakers | Safe</title>
        <meta name="title" content="Authentic Adidas & Yeezy Deadstock Sneakers | Safe" />
        <meta name="description" content="Safe carries authentic Adidas & Yeezy Sneakers available to purchase in Canada including Yeezy v2 350, Yeezy 500, Yeezy 700, Yeezy Slide & Yeezy Foam Runner" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="product:retailer_item_id" content={id} />
        <meta property="product:price:amount" content={price}/>
        <meta property="product:price:currency" content="CAD"/>
        <meta property="product:availability" content={availability}/>

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://shopwithsafe.com/browse/Adidas" />
        <meta property="twitter:title" content="Safe | Exclusive Sneakers Available For Everyone" />
        <meta property="twitter:description" content="SAFE is Canada’s Online Streetwear sneaker store, making your favourite kicks accessible for everyone. Brands include Jordan, Nike, adidas, Bape, New Balance, Essentials, Vlone, Anti Social Social Club & revenge. " />
        <meta property="twitter:image" content="https://www.shopwithsafe.com/static/media/home.17ccc68c.jpg" />
      </Helmet>
    )
}

export default handleCatalogMetaTags;