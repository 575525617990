import React, { useState } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import backIcon from "../assets/images/previous_not.svg";
import Colors from "../constants/Colors";
import Fonts from "../constants/Fonts";
import Button from "./Button";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Input } from 'antd';

const Container = styled.div`
  height: 100vh;
  overflow-x: hidden; 
  width: 100%;
  padding: 80px 80px 80px 80px;
  background: ${Colors.white};
  @media (max-width: 780px) {
    padding: 50px 40px 40px 40px;
    width: 100vw;
    min-height: 60vh;
  }
`;
const BackIcon = styled.img`
  width: 15px;
  height: 15px;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
`;
const Title = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY_BOLD};
  margin-left: 5px;
`;

const AddPaymentMethodButton = styled(Button)`
  margin-top: 20px;
  background: ${Colors.blue};
  border: none;
  color: ${Colors.white};
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
`;
const ErrorMessage = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.red};
  margin-top: 10px;
`;
const InputContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;
const InputLabel = styled.p`
  margin-bottom: 5px;
  font-family: ${Fonts.PRIMARY};
`;

const AddPaymentForm = (props) => {
  const { onBack, onCreate } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [postal, setPostal] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiry, setExpiry] = useState("");
  const country = "CA";
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  function getAddressComponentFromAutofilledAdrress(
    addressComponents,
    componentName
  ) {
    var value = "";
    var type = addressComponents.filter((aComp) =>
      aComp.types.some((typesItem) => typesItem === componentName)
    );
    if (type != null && type.length > 0) value = type[0].short_name;
    return value;
  }

  const ADDRESS_AUTOFILL_VALUES = {
    POSTAL: "postal_code",
    COUNTRY: "country",
    PROVINCE: "administrative_area_level_1",
    CITY: "locality",
    TOWN: "sublocality_level_1",
    AREA: "sublocality_level_2",
    NEAREST_ROAD: "route",
  };

  const autoFillAddress = async (data) => {
    try {
      console.log({ data });
      //get address data from geocode api
      const addressDetails = await geocodeByPlaceId(data.value.place_id);
      console.log({ addressDetails });
      //get components of address from data
      const autoFilledAddress = data.value.structured_formatting.main_text;
      const autoFilledPostal = getAddressComponentFromAutofilledAdrress(
        addressDetails[0].address_components,
        ADDRESS_AUTOFILL_VALUES.POSTAL
      );
      const autoFilledCity = getAddressComponentFromAutofilledAdrress(
        addressDetails[0].address_components,
        ADDRESS_AUTOFILL_VALUES.CITY
      );
      const autoFilledProvince = getAddressComponentFromAutofilledAdrress(
        addressDetails[0].address_components,
        ADDRESS_AUTOFILL_VALUES.PROVINCE
      );
      //set state with address components
      setProvince(autoFilledProvince);
      setCity(autoFilledCity);
      setAddress(autoFilledAddress);
      setPostal(autoFilledPostal);
    } catch (e) {
      console.log(e);
    }
  };

  const customStyles = {
    container: (provider) => {
      return {
        ...provider,
        fontFamily: Fonts.PRIMARY,
        fontSize: 18,
      };
    },
  };

  const handleSubmit = async (
    firstName,
    lastName,
    phone,
    country,
    address,
    postal,
    city,
    province,
    cardNumber,
    cvv,
    expiry
  ) => {
    setLoading(true);

    console.log({
      cardNumber,
      cvv,
      expiry,
    });

    if (
      firstName &&
      firstName !== "" &&
      lastName &&
      lastName !== "" &&
      phone &&
      phone !== "" &&
      country &&
      country !== "" &&
      address &&
      address !== "" &&
      postal &&
      postal !== "" &&
      city &&
      city !== "" &&
      province &&
      province !== "" &&
      cardNumber &&
      cardNumber !== "" &&
      expiry &&
      expiry !== "" &&
      cvv &&
      cvv !== ""
    ) {
      const card = {
        number: cardNumber.replace(/\s/g, ""),
        expiry: expiry.replace(/\\\//g, "/").replace(/\s/g, ""),
        cvv,
      };
      const billingAddress = {
        firstName,
        lastName,
        postal,
        address,
        province,
        country,
        city,
        phone,
      };
      await onCreate(card, billingAddress);
    } else {
      setErrorMessage("Please enter a valid billing address");
    }

    setLoading(false);
  };
  return (
    <Container>
      <TitleContainer onClick={() => onBack()}>
        <BackIcon src={backIcon} />
        <Title>Add Payment Method</Title>
      </TitleContainer>
      <InputContainer>
        <InputLabel>Card Info</InputLabel>
        <PaymentInputsWrapper {...wrapperProps} style={{ width: "100%"  }}>
          <svg {...getCardImageProps({ images })} />
          <input
            style={{color: cardNumber ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
            {...getCardNumberProps({
              onChange: (e) => setCardNumber(e.target.value),
            })}
            value={cardNumber}
          />
          <input
            style={{color: expiry ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
            {...getExpiryDateProps({
              onChange: (e) => setExpiry(e.target.value),
            })}
            value={expiry}
          />
          <input
            style={{color: cvv ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
            {...getCVCProps({
              onChange: (e) => setCvv(e.target.value),
            })}
            value={cvv}
          />
        </PaymentInputsWrapper>
      </InputContainer>

      <InputContainer>
        <InputLabel>First Name</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: firstName ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your first name"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Last Name</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: lastName ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your last name"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Phone Number</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: phone ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your phone number"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Address</InputLabel>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["ca"],
            },
          }}
          selectProps={{
            defaultInputValue: address,
            onChange: (data) => autoFillAddress(data),
            styles: customStyles,
          }}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>City</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: city ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your city"
          onChange={(e) => setCity(e.target.value)}
          value={city}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Province</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: province ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your province"
          onChange={(e) => setProvince(e.target.value)}
          value={province}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Country</InputLabel>
        <Input 
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: country ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          value={country}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Postal Code</InputLabel>
        <Input
          style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: postal ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
          placeholder="Enter your postal code"
          onChange={(e) => setPostal(e.target.value)}
          value={postal}
        />
      </InputContainer>

      <ErrorMessage>{errorMessage}</ErrorMessage>
      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <AddPaymentMethodButton
          onClick={() =>
            handleSubmit(
              firstName,
              lastName,
              phone,
              country,
              address,
              postal,
              city,
              province,
              cardNumber,
              cvv,
              expiry
            )
          }
        >
          Add
        </AddPaymentMethodButton>
      )}
    </Container>
  );
};

export default AddPaymentForm;
