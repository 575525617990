export const API =
  process.env.REACT_APP_ENV === "production"
    ? `${process.env.REACT_APP_API}/api`
    : `${process.env.REACT_APP_API_DEV}/api`;
export const API_HEADER = (token) => {
  return { headers: { Authorization: `Bearer ${token}` } };
};
export const USERS_API = `${API}/users`;
export const PRODUCTS_API = `${API}/products`;
export const ORDERS_API = `${API}/orders`;
export const BIDS_API = `${API}/bids`;
export const PAYMENT_METHOD_API = `${API}/paymentMethods`;
export const DELETE_PAYMENT_METHOD_API = `${API}/paymentMethods`;
export const SHIPPING_ADDRESS_API = `${API}/shippingAddresses`;
export const SHOPIFY_API = `${process.env.REACT_APP_API}/shopify`;
// export const SHOPIFY_API = `https://a454-5-254-108-213.ngrok-free.app/api/shopify`;
