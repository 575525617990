import {
    CREATE_ORDER,
    GET_ORDERS,
    GET_ORDER,
    CALCULATE_CHECKOUT,
    CREATE_EXPRESS_CHECKOUT
} from '../actions/types';
const defaultState = {
    createdOrder: null,
    creatingOrder: false,
    orders: [],
    loadingOrders: false,
    calculatingCheckout: false,
    checkoutData: {
        totalShipping: 'TBD',
        totalTaxes: 'TBD',
        totalFees: 'TBD',
        subtotal: 'TBD',
        total: 'TBD'
    },
    paymentIntent: null,
    creatingExpressCheckout: false
}
const OrderReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_ORDER:
            return {
                ...state,
                createdOrder: action.createdOrder,
                creatingOrder: action.creatingOrder,
            }
        case CREATE_EXPRESS_CHECKOUT: 
            return {
                ...state,
                creatingExpressCheckout: action.creatingExpressCheckout,
                paymentIntent: action.paymentIntent
            }
        case CALCULATE_CHECKOUT:
            return {
                ...state,
                calculatingCheckout: action.calculatingCheckout,
                checkoutData: action.checkoutData,
            }
        case GET_ORDERS:
            return {
                ...state,
                orders: action.orders,
                loadingOrders: action.loadingOrders,
            }
        case GET_ORDER:
            return {
                ...state,
                order: action.order,
                loadingOrder: action.loadingOrder,
            }
        default:
            return state;
    }
}

export default OrderReducer;