import React, { useState } from 'react';
import styled from 'styled-components';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Button from '../components/Button';
import backIcon from '../assets/images/previous_not.svg';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Input } from 'antd';

const Container = styled.div`
    padding: 80px 80px 80px 80px;
    background: ${Colors.white};
    height: 100vh;
    overflow-x: hidden; 
    @media (max-width: 780px) {
        padding: 50px 40px 40px 40px;
        width: 100vw;
        min-height: 60vh;
    }
`;
const BackIcon = styled.img`
    width: 15px;
    height: 15px;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const Title = styled.p`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-left: 20px;
    font-size: 18px;
    font-weight: 800;
`;

const FormContainer = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 780px) {
        padding: 10px;
        margin-top: 20px;
    }
`;
const InputContainer = styled.div`
    margin-bottom: 10px;
    width: 100%;

`;
const InputLabel = styled.p`
    margin-bottom: 5px;
    font-family: ${Fonts.PRIMARY};

`;
const SaveButton = styled(Button)`
    margin-top: 20px;
    background: ${Colors.blue};
    border: none;
    color: ${Colors.white};
    text-transform: uppercase;
`;

const ShippingForm = (props) => {

    const { addressData, onApply } = props;
    const [name, setName] = useState(addressData.name || '');
    const [lastName, setLastName] = useState(addressData.lastName || '');
    const [phone, setPhone] = useState(addressData.phone || '');
    const [address, setAddress] = useState(addressData.address || '');
    const [unitNumber, setUnitNumber] = useState(addressData.unitNumber || '');
    const [city, setCity] = useState(addressData.city || '');
    const [province, setProvince] = useState(addressData.province || '');
    const [postal, setPostal] = useState(addressData.postal || '');

    const country = 'Canada';
    const { onBack } = props;
    //autocomplete functions
    const ADDRESS_AUTOFILL_VALUES = {
        POSTAL: 'postal_code',
        COUNTRY: 'country',
        PROVINCE: 'administrative_area_level_1',
        CITY: 'locality',
        TOWN: 'sublocality_level_1',
        AREA: 'sublocality_level_2',
        NEAREST_ROAD: 'route'
    }
    function getAddressComponentFromAutofilledAdrress(addressComponents, componentName) {
        var value = '';
        var type = addressComponents.filter(aComp =>
            aComp.types.some(typesItem => typesItem === componentName))
        if (type != null && type.length > 0)
            value = type[0].short_name
        return value;
    }

    const autoFillAddress = async (data) => {
        try {
            console.log({ data })
            //get address data from geocode api
            const addressDetails = await geocodeByPlaceId(data.value.place_id);
            console.log({ addressDetails });
            //get components of address from data
            const autoFilledAddress = data.value.structured_formatting.main_text;
            const autoFilledPostal = getAddressComponentFromAutofilledAdrress(addressDetails[0].address_components, ADDRESS_AUTOFILL_VALUES.POSTAL)
            const autoFilledCity = getAddressComponentFromAutofilledAdrress(addressDetails[0].address_components, ADDRESS_AUTOFILL_VALUES.CITY)
            const autoFilledProvince = getAddressComponentFromAutofilledAdrress(addressDetails[0].address_components, ADDRESS_AUTOFILL_VALUES.PROVINCE)
            //set state with address components
            setProvince(autoFilledProvince)
            setCity(autoFilledCity);
            setAddress(autoFilledAddress);
            setPostal(autoFilledPostal)
        } catch (e) {
            console.log(e)
        }

    }

    const customStyles = {
        container: (provider) => {
            return {
                ...provider,
                fontFamily: Fonts.PRIMARY,
                fontSize: 18
            }
        },
    }

    return (
        <Container>
            <TitleContainer
                onClick={() => onBack()}
            >
                {onBack ? <BackIcon src={backIcon} /> : null}
                <Title>Shipping Address</Title>
            </TitleContainer>
            <FormContainer>
                <InputContainer>
                    <InputLabel>Name</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: name ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your name'
                        onChange={e => setName(e.target.value)}
                        value={name}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Last Name</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: name ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your last name'
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Phone Number</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: phone ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your phone number'
                        onChange={e => setPhone(e.target.value)}
                        value={phone}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Address</InputLabel>
                    <GooglePlacesAutocomplete
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5"}}
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['ca'],
                            }
                        }}
                        selectProps={{
                            defaultInputValue: address,
                            onChange: data => autoFillAddress(data),
                            styles: customStyles
                        }}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Unit Number</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: unitNumber ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your Unit Number'
                        onChange={e => setUnitNumber(e.target.value)}
                        value={unitNumber}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>City</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: city ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your city'
                        onChange={e => setCity(e.target.value)}
                        value={city}

                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Province</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: province ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your province'
                        onChange={e => setProvince(e.target.value)}
                        value={province}

                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Country</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: country ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        value={country}
                    />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Postal Code</InputLabel>
                    <Input
                        style={{borderRadius: "8px", backgroundColor: "#F5F5F5", color: postal ? "black" : "#717A82", fontFamily: `${Fonts.PRIMARY}`}}
                        placeholder='Enter your postal code'
                        onChange={e => setPostal(e.target.value)}
                        value={postal}

                    />
                </InputContainer>
                <SaveButton
                    onClick={() => onApply({
                        name,
                        lastName,
                        phone,
                        country,
                        address,
                        unitNumber,
                        postal,
                        city,
                        province
                    })}
                >
                    Apply
                </SaveButton>
            </FormContainer>
        </Container>
    )
}

export default ShippingForm;