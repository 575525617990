import React, { useState } from 'react';
import styled from 'styled-components';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

const Container = styled.div`
    align-self: flex-start;
    @media (max-width: 780px) {
        padding: 20px;
        margin-bottom: 0px; 
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center; /* Add this line */
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 15px;
    gap: 20px;
    @media (max-width: 780px) {
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
`;

const DropdownContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DropdownLabel = styled.label`
    margin-right: 10px;
    margin-bottom: 0px;
`;

const Dropdown = styled.select`
    padding: 5px;
    border: 1px solid ${Colors.gray};
    border-radius: 5px;
`;

const ApparelFilters = (props) => {

  const { selectedBrand, selectedSize, setSelectedBrand, setSelectedSize, sizes, brands } = props;

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const sizeOrder = {
    "all sizes": 0,
    "xxs": 1,
    "xs": 2,
    "s": 3,
    "m": 4,
    "l": 5,
    "xl": 6,
    "xxl": 7,
    "xxxl": 8,
    "os": 9
  };

  const sortedSizes = sizes.sort((a, b) => {
    const orderA = sizeOrder[a.toLowerCase()];
    const orderB = sizeOrder[b.toLowerCase()];

    // Handle sizes not found in sizeOrder
    if (orderA === undefined && orderB === undefined) {
      return a.localeCompare(b); // Sort alphabetically
    } else if (orderA === undefined) {
      return 1; // Move 'UnknownSize' to the end
    } else if (orderB === undefined) {
      return -1; // Move 'UnknownSize' to the end
    } else {
      return orderA - orderB;
    }
  });

  return (
    <Container>
      <Header>
        <DropdownContainer>
          <DropdownLabel>Size:</DropdownLabel>
          <Dropdown value={selectedSize} onChange={handleSizeChange}>
            {sortedSizes.map(size => <option key={size} value={size}>{capitalizeFirstLetter(size)}</option>)}
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <DropdownLabel>Brand:</DropdownLabel>
          <Dropdown value={selectedBrand} onChange={handleBrandChange}>
            {brands.map(brand => <option key={brand} value={brand}>{capitalizeFirstLetter(brand)}</option>)}
          </Dropdown>
        </DropdownContainer>
      </Header>
    </Container>
  );
}

export default ApparelFilters;
