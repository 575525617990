import React from "react";
import styled from "styled-components";
import Colors from "../constants/Colors";
import Fonts from "../constants/Fonts";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: -30px;
  z-index: 7777;
  @media (max-width: 780px) {
    margin-bottom: 0px;
    margin-top: -30px;
  }
`;
const Tab = styled.div`
  background: ${Colors.blue};
  padding: 20px;
  margin: 0px;
  border-top-left-radius: ${(props) => (props.index === 0 ? "5px" : "0px")};
  border-bottom-left-radius: ${(props) => (props.index === 0 ? "5px" : "0px")};
  border-top-right-radius: ${(props) => (props.index === 1 ? "5px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.index === 1 ? "5px" : "0px")};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
  justify-content: space-between;
  cursor: pointer;
`;
const TabTitle = styled.p`
  font-size: 12px;
  font-family: ${Fonts.PRIMARY_BOLD};
  color: ${(props) =>
    props.selectedTab === props.tab ? Colors.yellow : Colors.midGrey};
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 20px;
`;

const Underline = styled.div`
  height: 2px;
  width: 100%;
  background: ${(props) =>
    props.selectedTab === props.tab ? Colors.yellow : Colors.blue};
`;

const CheckoutTab = (props) => {
  const { tabs, onTabClick, selectedTab } = props;
  return (
    <Container>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          index={index}
          selectedTab={selectedTab}
          tab={tab}
          onClick={() => onTabClick(tab)}
        >
          <TabTitle selectedTab={selectedTab} tab={tab}>
            {tab}
          </TabTitle>
          <Underline selectedTab={selectedTab} tab={tab} />
        </Tab>
      ))}
    </Container>
  );
};

export default CheckoutTab;
