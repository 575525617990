import {
    CREATE_PAYMENT_METHOD,
    GET_PAYMENT_METHODS,
    DELETE_PAYMENT_METHOD
} from '../actions/types';
const defaultState = {
    createdPaymentMethod: null,
    creatingPaymentMethod: false,
    loadingPaymentMethods: false,
    paymentMethods: [],
    deletingPaymentMethod: false
}
const PaymentMethodReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_METHOD:
            return {
                ...state,
                createdPaymentMethod: action.createdPaymentMethod,
                creatingPaymentMethod: action.creatingPaymentMethod,
            }
        case GET_PAYMENT_METHODS:
            return {
                ...state,
                loadingPaymentMethods: action.loadingPaymentMethods,
                paymentMethods: action.paymentMethods
            }
        case DELETE_PAYMENT_METHOD: 
            return {
                ...state,
                deletingPaymentMethod: action.deletingPaymentMethod
            }
        default:
            return state;
    }
}

export default PaymentMethodReducer;