import AuthenticIcon from "../assets/images/authenticated.png";
import AuthenticIconOne from "../assets/images/authentication-one.jpeg";
import AuthenticIconTwo from "../assets/images/authentication-two.jpeg";
import CareIcon from "../assets/images/care.png";
import DutiesIcon from "../assets/images/duties.png";
import GlobalIcon from "../assets/images/global.png";
import PricingIcon from "../assets/images/pricing.png";
import SecureIcon from "../assets/images/secure.png";
import ShopIcon from "../assets/images/shop-icon.png";

import BuyIcon from "../assets/images/shop.png";
import ShipIcon from "../assets/images/ship.png";
import VerifiedIcon from "../assets/images/verfied.png";

import ConditionIcon from "../assets/images/condition.png";
import BuildIcon from "../assets/images/build.png";
import PackagingIcon from "../assets/images/packaging.png";
import AccessoriesIcon from "../assets/images/accessories.png";
import TechIcon from "../assets/images/tech.png";
import QAIcon from "../assets/images/qa.png";

const Copy = {
  HomePageBottomButton: "Browse 1000+ more sneakers",
  TOSLabel: "Terms & Conditions",
  ForgotPasswordTitle: "FORGOT PASSWORD?",
  forgotPasswordSubTitle: "Confirm your email and we’ll send the instructions",
  forgotPasswordButton: "RESET PASSWORD",
  loginButton: "LOGIN",
  signupButton: "SIGNUP",
  filterButton: "Filter",
  ProductLoadingErrorMessage: "Product not found",
  BuyPriceHeader: "Price details",
  BuyProccessingFee: "Processing Fee",
  BuyShippingFeeFast: "Shipping (1 - 3 business days)",
  BuyShippingFee: "Shipping (5 - 10 business days)",
  Discount: "Discount",
  BuyTaxesFee: "Tax",
  BuyProtectionFee: "Package Protection",
  BuyTotal: "Total CAD",
  BuyInputPlaceholder: "Enter Bid",
  BidSuccessTitle: "Bid Placed",
  BidSuccessSubtitle:
    "Your bid has been created, you will be notified by email",
  BidButtonText: "CONTINUE SHOPPING",
  BuySuccessTitle: "Order Placed",
  BuySuccessSubtitle:
    "Your order has been created, you will be notified by email",
  BuyButtonText: "CONTINUE SHOPPING",
  DUTY_BANNER: "Duty Free For Canada 🇨🇦",
  MAINTAIN_BANNER: "Undergoing Maintenance. To place an order call 289-276-7233",
  appTextTitle: "Download the iOS or Android SAFE App",
  appSubtitle: "Enter your phone number and we will text you the download link",
  fastShippingText: "At your door in 1 - 3 business days",
  bestPriceText: "At your door in 5 - 10 business days",
  faq: {
    purchasing: {
      title: "Purchasing",
      rows: [
        {
          title: "How to purchase on SAFE?",
          content:
            "Find the item you wish to buy by using our search bar, then click on the size you are looking to purchase and then click buy.",
        },
        {
          title: "Are shipping fees included?",
          content: "Shipping fees are calculated and applied at checkout.",
        },
        // {
        //     title: 'Does it cost anything to cancel or update a bid?',
        //     content: 'No it doesnt. you can cancel or update your bid at no cost before a seller accepts your offer'
        // },
        {
          title: "Can I change my address once an item is purchased?",
          content:
            "Unfortunately, once an order is purchased and confirmed we cannot change the shipping address",
        },
        {
          title: "My order was cancelled. Can I still buy the item?",
          content:
            "If your order is canceled, the item may still be available for purchase. Please note, the item may not be available at the same price as your canceled order. For sneakers, you can also place an offer for the item at your ideal price in the SAFE app.",
        },
      ],
    },
    shipping: {
      title: "Shipping",
      rows: [
        {
          title: "Do I have to pay tax on my order?",
          content:
            "Yes, you have to pay tax on every order made on the SAFE app. Taxes are calculated based on your region.",
        },
        {
          title: "What payment options do you accept?",
          content:
            "We accept many different payment options being creditcard, visa debit, paypal and apple pay.",
        },
        {
          title: "Im having issuses purchasing what do I do?",
          content:
            "Occasionally our payments processor is unable to complete your purchase. This can be for a number of reasons, including an incorrect credit card number, insufficient funds or wrong postal code. Please review the card number you entered and the postal code first and if those are correct, we recommend contacting the card-issuing bank. If you’re still having issues, please contact us for additional assistance.",
        },
        {
          title: "How much does shipping cost in Canada?",
          content: "$20 CAD per order",
        },
        {
          title: "How long does shipping take in Canada?",
          content:
            "Once an order is purchased it takes 5-7 business day to be delivered to the customer",
        },
        {
          title: "How long does shipping take to the US?",
          content:
            "Once an order is purchased it takes 7-10 business day to be delivered to the customer",
        },
        {
          title: "Can I pick up my order?",
          content: "No, all orders must be shipped out via Canada Post or UPS.",
        },
        {
          title: "When will I get tracking for my order?",
          content:
            "A tracking numnber will be sent once your order has been confirmed and placed which takes 1-3 business days.",
        },
      ],
    },
    returns: {
      title: "Return Policies",
      rows: [
        {
          title: "Are returns and exchanges allowed?",
          content:
            "All purchases are final sale we do not accept any returns or exchanges. If there are any issues with an order you placed please contact us at orders@shopwithssafe.com",
        },
      ],
    },
    certifiedProducts: {
      title: "SAFE Certified Products",
      rows: [
        // {
        //     title: 'I cant find a product on your website?',
        //     content: 'We do allow the original box to be slightly damaged, such as minor dents and rips. We do not allow boxes with major rips or tears.'
        // },
        {
          title: "Does SAFE sell used sneakers?",
          content: "No SAFE only sells brand new (Deadstock) sneakers",
        },
        {
          title: "Do all sneakers come with a box?",
          content:
            "Yes, all sneakers come with a box and original all as if it came from a retailer.",
        },
        {
          title: "Are all sneakers on SAFE legit?",
          content:
            "Yes all sneakers and items are 100% authentic and checked by our team at our warehouse before being shipped out.",
        },
      ],
    },
  },
  locationsTite: 'Locations',
  locationsSubtitle: 'Find your nearest SAFE store',
  howItWorksTitle: "How SAFE Works?",
  howItWorksSubText:
    "SAFE is the most trusted marketplace for buying and selling sneakers in Canada.",
  featureTitle: "Why  buy or sell on SAFE?",
  featureSubText:
    "We verify and insure every item for authenticity so you can shop worry free",
  features: [
    {
      title: "100% Verified Authentic",
      subtext:
        "Any item purchased is put through our multi-step verification process, which is overseen by our team of professional authenticators.",
      icon: AuthenticIcon,
    },
    {
      title: "Pricing",
      subtext:
        "Our real-time dynamic pricing helps you to get your sneakers duty-free!",
      icon: PricingIcon,
    },
    {
      title: "Global Access",
      subtext:
        "Discover and browse for your favourite shoes from around the world. No matter how rare a sneaker is, we have it! On our platform, they are always available for purchase.",
      icon: GlobalIcon,
    },
    {
      title: "No Duties",
      subtext:
        "There are no import fees! We eliminate it so you dont have to think about paying extra fees at the door.",
      icon: DutiesIcon,
    },
    {
      title: "Secure",
      subtext:
        "Our protection and fraud systems, which are powered by world-class organizations, protect your confidential info 24 hours a day, 7 days a week.",
      icon: SecureIcon,
    },
    {
      title: "Here For You",
      subtext:
        "You can be sure that we are always available to answer any and every question regarding our marketplace. Visit our contact us page to see how you can contact us!",
      icon: CareIcon,
    },
  ],
  howItWorksStepsTitle: "How it works?",
  howItWorksSteps: [
    {
      title: "Buy",
      subtext: "Browse and buy from our catalog of thousands of sneakers",
      icon: BuyIcon,
    },
    {
      title: "We Authenticate",
      subtext: "Your item arrives at our authentication center from the seller",
      icon: VerifiedIcon,
    },
    {
      title: "We Ship To You",
      subtext:
        "When the item passes our verification process we ship to you",
      icon: ShipIcon,
    },
  ],
  buyingGuideTitle: "The SAFE Buying Guide",
  buyingGuideSubtext:
    "Learn more about why, how, and what you need to know before buying on SAFE.",
  buyingGuideFeatures: [
    {
      title: "Browse Our Global Platform",
      subtext:
        "Discover and browse for your favourite shoes from around the world. No matter how rare a sneaker is, we have it! On our platform, they are always available for purchase.",
      icon: GlobalIcon,
    },
    {
      title: "Pick Your Shoe",
      subtext:
        "Purchase now at our low price, which can fluctuate by the second. We maintain a constant eye on the market, which allows us to keep our prices within market range.",
      icon: ShopIcon,
    },
    {
      title: "Enjoy Guaranteed Authenticity",
      subtext:
        "Every sneaker is individually examined by our team of authenticators. Theyll double-check that any sneaker you buy is 100% genuine and brand new before delivering it to your door.",
      icon: AuthenticIcon,
    },
  ],
  buyingGuideStepsTitle: "Pathway To Excellence",
  buyingGuideSteps: [
    {
      title: "Buy",
      subtext:
        'On SAFE, purchasing your favourite sneakers is as simple as pressing the "Buy Now" button. Due to market fluctuations, our prices adjust frequently. However, you can rest assured that you will be able to purchase always receive duty-free sneakers!',
      icon: BuyIcon,
    },
    {
      title: "We Authenticate",
      subtext:
        "Your favourite sneaker is sourced from one of our trusted sneaker suppliers. We make certain that sneakers are delivered to us as soon as possible for verification. We'll send it to you once we've confirmed it's 100 percent authentic and Brand New! The average delivery time from order date is 7-12 business days.",
      icon: VerifiedIcon,
    },
    {
      title: "Enjoy Your Kicks",
      subtext:
        "We send the order to you as soon as we verify that it is 100 percent genuine. You should feel free to flex on everyone once it arrives by tagging us with your new kicks!",
      icon: ShipIcon,
    },
  ],
  authenticationTitle: "Our Authentication Process",
  authenticationSubtext:
    "Every item bought on SAFE is guaranteed authenticity.",
  authenticationImageOne: AuthenticIconOne,
  authenticationImageTwo: AuthenticIconTwo,
  authenticationFeatureTitle: "Our Process",
  authenticationFeatureSubtext:
    "Our team of professional authenticators use a strict, multi-step verification procedure which includes the following process.",
  authenticationFeatureOne: [
    {
      title: "Condition",
      subtext:
        "We only sell deadstock (brand new) sneakers on our platform. That means every item you purchase will be brand new and never worn.",
      icon: ConditionIcon,
    },
    {
      title: "Sneaker Build",
      subtext:
        "Our authenticators go through a check list of the sneakers construction itself, which involves looking at materials stitching, smell, shape and many more unique features.",
      icon: BuildIcon,
    },
    {
      title: "Packaging",
      subtext:
        "Our experts ensure that packaging follows the utmost specifications in order to deliver a brand new product, which is about as significant as the product itself.",
      icon: PackagingIcon,
    },
  ],
  authenticationFeatureTwo: [
    {
      title: "Accessories",
      subtext:
        "Rest easy knowing that your SAFE purchase will meet every retail purchase experience, from the entire list of accessories to all of the extra add-ons.",
      icon: AccessoriesIcon,
    },
    {
      title: "Advanced Technology",
      subtext:
        "To help our authenticators capture every minor detail, we use artificial intelligence as well.",
      icon: TechIcon,
    },
    {
      title: "Quality Assurance",
      subtext:
        "Our QA experts ensure that nothing falls through the seams as a final inspection in our authentication process.",
      icon: QAIcon,
    },
  ],
  passwordWarningText: "At least 8 characters, 1 uppercase, 1 number, 1 symbol",
};

export default Copy;
