import axios from 'axios';
import {
    API_HEADER,
    SHIPPING_ADDRESS_API
} from '../../../constants/API';
import {
    SAVE_SHIPPING_ADDRESS
} from '../types';


const saveShippingAddress = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: SAVE_SHIPPING_ADDRESS,
                savingShippingAddress: true,
            });
            const { token } = getState().UserReducer;
            const shippingAdressResponse = await axios.post(SHIPPING_ADDRESS_API, data, API_HEADER(token));
            console.log(shippingAdressResponse)
            dispatch({
                type: SAVE_SHIPPING_ADDRESS,
                savingShippingAddress: false,
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: SAVE_SHIPPING_ADDRESS,
                savingShippingAddress: false,
            })
        }
    }
}

export { saveShippingAddress };