import React from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import privacy from '../../constants/privacy';
const Container = styled.div``;

const Header = styled.div`
    background: ${Colors.lightBackground};
    padding: 20px;
`;
const Title = styled.h2`
    margin-bottom: 0px;
    font-family: ${Fonts.PRIMARY};
`;
const Content = styled.div`
    padding: 20px;
    @media (max-width: 780px) {
        padding: 20px;
    }
`;

const Privacy = () => {
    return (
        <Container>
            <Header>
                <Title>
                    Privacy Policy
                </Title>
            </Header>
            <Content
                dangerouslySetInnerHTML={{ __html: privacy }}
            >
            </Content>

        </Container>
    )
}

export default Privacy;