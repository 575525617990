import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import ProductReducer from './ProductReducer';
import OrderReducer from './OrderReducer';
import BidReducer from './BidReducer';
import ShippingAddressReducer from './ShippingAddressReducer';
import PaymentMethodReducer from './PaymentMethodReducer';


export default combineReducers({
    ProductReducer,
    UserReducer,
    OrderReducer,
    BidReducer,
    PaymentMethodReducer,
    ShippingAddressReducer
})