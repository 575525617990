import React, { useState } from 'react';
import styled from 'styled-components';
import Copy from '../../constants/Copy';
import Fonts from '../../constants/Fonts';
import Colors from '../../constants/Colors';
import appImage from '../../assets/images/app.png';
import Button from '../../components/Button';
import connectScreen from '../../redux/connectScreen';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 90px - 120px);
    background: ${Colors.lightBackground};
    @media (max-width: 780px) {
        flex-direction: column;
    }
`;
const Title = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 36px;
    @media (max-width: 780px) {
        font-size: 24px;
    }
`;
const Subtitle = styled.p`
    font-family: ${Fonts.PRIMARY};
`;
const PhoneNumberInput = styled.input`
    width: 100%;
    font-size: 24px;
    font-family: ${Fonts.PRIMARY_BOLD};
    outline: none;
    padding: 10px;
`;
const TextContainer = styled.div`
    width: 50%;
    padding-right: 100px;
    @media (max-width: 780px) {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 40px;

    }
`;
const ImageContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    height: 100%;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
const AppImage = styled.img`
    width: 70%;
    object-fit: contain;
    @media (max-width: 780px) {
        width: 80%;
        margin-top: 20px;
    }
`;
const ConfirmButton = styled(Button)`
    width: 100%;
    background: ${Colors.blue};
    margin-top: 10px;
    color: ${Colors.white};
`;

const App = (props) => {

    const [sent, setSent] = useState(false);
    const [phone, setPhone] = useState('');

    const sendAppLinkToPhone = () => {
        setSent(true);
        props.textAppLink({phone});
    }

    return (
        <Container>
            <ImageContainer>
                <AppImage src={appImage} />
            </ImageContainer>
            <TextContainer>
                <Title>{Copy.appTextTitle}</Title>
                <Subtitle>{Copy.appSubtitle}</Subtitle>
                <PhoneNumberInput
                    type='tel'
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                />
                {sent ? (<ConfirmButton disabled={sent}>
                    APP LINK SENT!
                </ConfirmButton>) : (<ConfirmButton onClick={() => sendAppLinkToPhone()}>
                    TEXT ME
                </ConfirmButton>)}

            </TextContainer>

        </Container>
    )
}

export default connectScreen(App);