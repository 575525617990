import swal from "sweetalert";

const affirm = typeof window !== "undefined" ? window.affirm : null;

export const createCheckoutAffirm = (affirmCheckoutOptions) => {
  try {
    const {
      customer,
      title,
      sku,
      price,
      images,
      stockXHandle,
      totalShipping,
      totalTaxes,
      total,
      discount,
      discountCode,
      setIsAffirm,
      setShowCheckoutForm,
    } = affirmCheckoutOptions;

    const {
      address,
      name,
      lastName,
      city,
      country,
      province,
      postal,
      phone,
      unitNumber,
    } = customer.shipping;
    const { email } = customer.email;

    console.log("AFFIRMING", name,customer);
    const addressObject = {
      line1: address,
      line2: unitNumber,
      city: city,
      state: province,
      zipcode: postal,
      country: country,
    };

    const nameObject = {
      first:name,
      last:lastName,
      full: name+" "+lastName,
    };

    const contactInfo = {
      name: nameObject,
      address: addressObject,
      //phone_number: "9051237347",
      email: email,
    };

    const item = {
      display_name: title,
      sku: sku,
      unit_price: Math.floor(price * 100),
      qty: 1,
      item_image_url: images[0],
      item_url: `https://shopwithsafe.com/product/${stockXHandle}`,
    };

    const metadata = {
      mode: "modal",
    };

    const checkoutOptions = {
      merchant: {
        user_confirmation_url: "http://shopwithsafe.com/confirm",
        user_cancel_url: "http://shopwithsafe.com/cancel",
        public_api_key: "Q693GC2M4BR6HF9C",
        use_vcn: false,
        name: "SHOP WITH SAFE",
      },
      shipping: contactInfo,
      billing: contactInfo,
      items: [item],
      // discounts should only be included if there is a discount
      discounts: discount && {
        discount: {
          discount_amount: Math.floor(discount * 100),
          discount_display_name: discountCode,
        },
      },
      metadata: metadata,
      currency: "CAD",
      shipping_amount: Math.floor(totalShipping * 100),
      tax_amount: Math.floor(totalTaxes * 100),
      total: Math.floor(total * 100),
    };

    affirm.checkout(checkoutOptions);

    const onSuccess = async function (a) {
      try {
        if (affirmCheckoutOptions.onSuccess) {
          affirmCheckoutOptions.onSuccess({
            checkoutToken: a.checkout_token,
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during transaction authorization:",
          error
        );
      }
    };

    const onOpen = function (token) {
      affirm.ui.ready(function () {
        if (setShowCheckoutForm && setIsAffirm) {
          setIsAffirm(true);
          setShowCheckoutForm(true);
        }
      });
    };

    const onFail = function () {
      swal({
        title: "Checkout closed",
        text: "Your Affirm checkout was closed.",
        icon: "error",
        button: "OK",
      }).then(() => {
        setIsAffirm(false);
        setShowCheckoutForm(false);
      });
    };

    console.log("NAME NAME", contactInfo);
    const onValidationError = function () {
      affirm.ui.ready(function () {
        affirm.ui.error.on("close", function () {
          name === ""
            && swal({
                title: "Checkout Error",
                text: "Please fix your name on the shipping form and try again.",
                icon: "error",
                button: "OK",
              })
        });
      });
    };

    affirm.checkout.open({
      onSuccess,
      onFail,
      onOpen,
      onValidationError,
    });
  } catch (error) {
    console.error("An error occurred during Affirm checkout:", error);
    // Display a generic error message to the user and provide options to retry or contact support
    swal({
      title: "Error",
      text: "An error occurred during the checkout process. Please try again or contact support.",
      icon: "error",
      button: "OK",
    });
  }
};
