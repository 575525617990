import styled from "styled-components";
import Colors from "../../constants/Colors";
import Button from "../../components/Button";
import Fonts from "../../constants/Fonts";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50vw - 80px);
  margin: 40px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 40px;
  background: ${Colors.white};
  @media (max-width: 780px) {
    margin: 20px;
    margin-top: 40px;
    padding: 20px;
    margin-bottom: 20px;
    width: calc(100vw - 40px);
  }
`;
export const ErrorContainer = styled.div``;

export const CheckoutFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  height: 100%;
  background: ${Colors.lightGrey};
  min-height: calc(100vh - 190px);
  padding-bottom: 80px;
  @media (max-width: 780px) {
    padding-top: 0px;
    padding-bottom: 40px;
    justify-content: flex-start;
  }
`;

export const CheckoutButtonBar = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 40px;
  width: 100%;
  bottom: 0;
  background: white;
  height: 100px;
  z-index: 7778;
  @media (max-width: 780px) {
    padding-right: 20px;
  }
`;

export const CheckoutButton = styled(Button)`
  border-radius: 30px;
  padding-left: 50px;
  padding-right: 50px;
  height: 60px;
  border: none;
  width: auto;
  :disabled {
    background: ${Colors.lightGrey};
    color: ${Colors.midGrey};
    max-width: 50%;
  }
  @media (min-width: 780px) {
    padding-left: 100px;
    padding-right: 100px;
    height: auto;
  }
`;

export const EditButton = styled(Button)`
  border-radius: 30px;
  padding-left: 50px;
  padding-right: 50px;
  height: 60px;
  border: none;
  width: auto;
  margin-bottom: 20px;
  background: ${Colors.black};
  color: ${Colors.white};
  white-space: nowrap;
  max-width: 50%;
  @media (min-width: 780px) {
    padding-left: 100px;
    padding-right: 100px;
    height: auto;
  }
`;

export const BackButton = styled(Button)`
  background: none;
  border: none;
`;

export const CheckoutButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: 780px) {
    flex-direction: column;
  }
`;

export const Logo = styled.img`
  height: 30px; /* Adjust the height as needed */
  margin: 5px 5px;
`;

export const CheckLabel = styled.div`
  font-family: ${(props) => (props.large ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY)};
  font-size: 14px;
  color: ${Colors.darkGrey};
  /* margin-left: 10px; */
  text-transform: capitalize;
`;
