import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import { Messages } from '../constants/Footer';

const Container = styled.div`
    max-width: 100%;
    background-color: ${Colors.blue};
    padding: 30px 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const Copyright = styled.div`
    font-size: 12px;
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.white};
`

const FooterLink = styled(Link)`
    margin-left: 10px;
    color: ${Colors.midGrey};
    font-family: ${Fonts.PRIMARY};
`;

const Footer = () => {
    return (
        <Container>
            <Content>
                <FooterLink to='/faq'>FAQ</FooterLink>
                <FooterLink to='/howItWorks'>How It Works</FooterLink>
                <FooterLink to='/privacy'>Privacy</FooterLink>
                <FooterLink to='/terms'>Terms</FooterLink>
                <FooterLink to='/contact'>Contact</FooterLink>     
            </Content>
            <Copyright>{Messages.copyright} </Copyright>
        </Container>
    )
}

export default Footer;