import React from 'react'
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Container = styled.div`
    position: absolute;
    width: 100%;
    top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.midGrey};
    padding: 10px;
`;
const Text = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-bottom: 0px;
    color: ${Colors.white};
`;

const Banner = (props) => {
    const { text } = props;
    return (
        <Container>
            <Text>
                {text}
            </Text>
        </Container>
    )
}

export default Banner;