export const LOGIN_LOADING = 'LOGIN_LOADING';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_TRENDING_PRODUCTS_FOR_GENDER = 'GET_TRENDING_PRODUCTS_FOR_GENDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_BID = 'CREATE_BID';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const GET_SHIPPING_ADDRESS = 'GET_SHIPPING_ADDRESS';
export const SAVE_SHIPPING_ADDRESS = 'SAVE_SHIPPING_ADDRESS';
export const GET_BIDS = 'GET_BIDS';
export const DELETE_BID = 'DELETE_BID';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const TEXT_APP_LINK = 'TEXT_APP_LINK';
export const CALCULATE_CHECKOUT = 'CALCULATE_CHECKOUT';
export const CREATE_EXPRESS_CHECKOUT = 'CREATE_EXPRESS_CHECKOUT';
export const GET_COLLECTION_PRODUCTS_BY_HANDLE = 'GET_COLLECTION_PRODUCTS_BY_HANDLE';
