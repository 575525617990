import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    min-width: 90vw;
`

const LoadingScreen = () => {
    return (
        <div>
            <LoadingContainer>
                <Spinner animation="border" variant="primary" />
            </LoadingContainer>
        </div>
    )
}

export default LoadingScreen;