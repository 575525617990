import axios from 'axios';
import {
    API_HEADER,
    SHIPPING_ADDRESS_API
} from '../../../constants/API';
import {
    GET_SHIPPING_ADDRESS
} from '../types';


const getShippingAddress = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_SHIPPING_ADDRESS,
                loadingShippingAddress: true,
                shippingAddress: {}
            });
            const { token } = getState().UserReducer;
            const shippingAddressResponse = await axios.get(SHIPPING_ADDRESS_API, API_HEADER(token));
            console.log(shippingAddressResponse)
            dispatch({
                type: GET_SHIPPING_ADDRESS,
                loadingShippingAddress: false,
                shippingAddress: shippingAddressResponse.data
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_SHIPPING_ADDRESS,
                loadingShippingAddress: false,
                shippingAddress: false
            })
        }
    }
}

export { getShippingAddress };