import React from "react";
import styled from "styled-components";
// import Info from "../../assets/images/info.png";
import Info from "../assets/images/info.png";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 18px;
  margin-top: 10px;
`;

const BoldText = styled.span`
  font-weight: bold;
  display: flex;
  gap: 5px;
`;

const Text = styled.span`
  font-weight: normal;
`;

const Logo = styled.img`
  height: 20px; /* Adjust the height as needed */
  margin: 0 5px;
`;

export const Icon = styled.img`
  @media screen and (max-width: 780px) {
    width: 15px;
    height: 15px;
  }
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const TextWithLogo = ({ logoUrl,url,text }) => (
  <Container>
    <BoldText>
      {text} <Text>with</Text>
    </BoldText>
    {!url && <Logo src={logoUrl} alt="Logo" />}
    {url && <a href={url} target="_blank" rel="noreferrer"><Logo src={logoUrl} alt="Logo" /></a>}  
    <Text>at checkout</Text>
    {url && <a href={url} target="_blank" rel="noreferrer"><Icon src={Info} alt="info" /></a>}
  </Container>
);

export default TextWithLogo;
