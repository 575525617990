import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;
const FeeLabel = styled.p`
    color: ${props => props.bold ? Colors.black : Colors.midGrey};
    font-family: ${props => props.bold ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY};
    @media (max-width: 768px) {
        font-size: 12px;
    }
`

export const CheckLabel = styled.p`
    color: ${props => props.bold ? Colors.black : Colors.midGrey};
    margin-top: 10px;
    font-family: ${props => props.bold ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY};
    @media (max-width: 768px) {
        font-size: 12px;
    }
`

const Fee = styled.p`
    color: ${props => props.bold ? Colors.black : Colors.midGrey};
    font-family: ${props => props.bold ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY};
    @media (max-width: 768px) {
        font-size: 12px;
    }
`


const FeeLine = (props) => {
    const { label, fee = '',  bold = false } = props
    return (
        <Container>
            <FeeLabel bold={bold}>{label}</FeeLabel>
            <Fee bold={bold}>{label === "Discount" ? `-$${fee}` : `${fee}` }</Fee>
        </Container>
    )
}

export default FeeLine;