import axios from 'axios';
import {
    API_HEADER,
    USERS_API
} from '../../../constants/API';
import {
    TEXT_APP_LINK
} from '../types';


const textAppLink = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: TEXT_APP_LINK,
                textingAppLink: true,
            });
            const { token } = getState().UserReducer;
            const textAppLinkResponse = await axios.post(`${USERS_API}/textAppLink`, data,  API_HEADER(token));
            console.log(textAppLinkResponse)
            dispatch({
                type: TEXT_APP_LINK,
                textingAppLink: false,
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: TEXT_APP_LINK,
                textingAppLink: false,
            })
        }
    }
}

export { textAppLink };