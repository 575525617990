import React from "react";
import { Provider } from "react-redux";
import firebase from "firebase";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";
import Nav from "./nav";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import configureStore from "./redux/store";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
// import logo from "./images/logo.png";

function App() {
  const gtmParams = {
    id: "GTM-MN6RW7G",
    environment: {
      gtm_auth: "uWtRqwXhha8oJxa_WerQEw",
      gtm_preview: "env-1",
    },
    dataLayer: {},
  };

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DB_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_ANALYTICS_ID,
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.analytics().logEvent("notification_received");
  }
  // Initialize Firebase

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  ReactGA.initialize("UA-199839548-1");

  return (
    <GTMProvider state={gtmParams}>
      <Provider store={configureStore()}>
        <Elements stripe={stripePromise}>
          <Nav />
          {/* <div
            style={{
              background: "black",
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              style={{ width: 250, height: 200, objectFit: "contain" }}
              src={logo}
              alt="logo"
            />
            <p
              style={{
                color: "white",
                fontSize: 36,
                textAlign: "center",
                width: "60%",
                fontFamily: "avenir",
              }}
            >
              We are currently undergoing maintenance. We will be back online by
              Feb 5th, 2022{" "}
            </p>
          </div> */}
        </Elements>
      </Provider>
    </GTMProvider>
  );
}

export default App;
