import {
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_TRENDING_PRODUCTS_FOR_GENDER,
} from "../actions/types";
const defaultState = {
  loadingProducts: true,
  products: [],
  loadingProduct: true,
  product: [],
  loadingProductsSections: false,
  trendingAll: [],
  trendingAdidas: [],
  trendingNike: [],
  trendingJordan: [],
  newReleasesForGender: [],
  collectionProducts: [],
};
const ProductReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        loadingProducts: action.loadingProducts,
        products: action.products,
      };
    case GET_PRODUCT:
      return {
        ...state,
        loadingProduct: action.loadingProduct,
        product: action.product,
        variants: action.variants,
      };
    case GET_TRENDING_PRODUCTS_FOR_GENDER:
      return {
        ...state,
        type: action.type,
        loadingProductsSections: action.loadingProductsSections,
        trendingAll: action.trendingAll,
        trendingAdidas: action.trendingAdidas,
        trendingNike: action.trendingNike,
        trendingJordan: action.trendingJordan,
        newReleasesForGender: action.newReleasesForGender,
      };
    case "GET_COLLECTION_PRODUCTS_BY_HANDLE":
      return {
        ...state,
        loadingProductsSections: action.loadingProductsSections,
        collectionProducts: action.collectionProducts,
      };
    default:
      return state;
  }
};

export default ProductReducer;
