import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Copy from '../../constants/Copy';
import heroImage from '../../assets/images/buying-guide-hero.png';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
const Container = styled.div`
    
`;
const HeroTextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 80px;
    @media (max-width: 780px) {
        padding-left: 0px;

    }
`;
const HeroImageContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
const Hero = styled.div`
    display: flex;
    height: calc(100vh - 90px);
    @media (max-width: 780px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 40px;
    }
`;
const HeroText = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 48px;
`;
const HeroSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 18px;
`;
const HeroImage = styled.img`
    width: 100%;
    object-fit: contain;
    @media (max-width: 780px) {
        max-height: 300px;
    }
`;
const HeroButton = styled(Button)`
    width: 70%;
    background: ${Colors.blue};
    color: ${Colors.white};
    @media (max-width: 780px) {
        width: 100%;
    }
`;

const Features = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    background: ${Colors.lightBackground};
`;
const FeatureList = styled(Row)`
  
`;
const FeatureItem = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;
const FeatureItemText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-top: 20px;
`;
const FeatureItemIcon = styled.img`
    width: 60px;
    height: 60px;
`;
const FeatureItemSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
`;
const Steps = styled.div`
    padding-bottom: 100px;
    padding-top: 100px;
`;
const Step = styled.div`
    display: flex;
    padding: 40px 100px 40px 100px;
    width: 100%;
    flex-direction: ${props => (props.currentIndex % 2 === 0) ? 'row-reverse' : 'row'};
    justify-content: space-between;
    @media (max-width: 780px) {
        flex-direction: column-reverse;
        padding: 20px 20px 20px 20px;
    }

`;
const StepTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const StepIcon = styled.img`
    width: 40%;
    object-fit: none;
    margin-right: ${props => (props.currentIndex % 2 === 0) ? '20px' : '0px'};
    margin-left: ${props => (props.currentIndex % 2 === 0) ? '0px' : '20px'};
    @media (max-width: 780px) {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
`;
const StepText = styled.p`
    font-family: ${Fonts.PRIMARY};
    margin-bottom: 0px;
`;
const StepNumber = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 38px;
    margin-bottom: 0px;
`;


const StepSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
`;
const HowItWorksStepsText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
`;

const BuyingGuide = () => {
    return (
        <Container>
            <Hero>
                <HeroTextContainer>

                    <HeroText>
                        {Copy.buyingGuideTitle}
                    </HeroText>
                    <HeroSubText>
                        {Copy.buyingGuideSubtext}
                    </HeroSubText>

                    <Link to='/browse'>
                        <HeroButton>
                            Browse Sneakers
                        </HeroButton>
                    </Link>

                </HeroTextContainer>
                <HeroImageContainer>
                    <HeroImage src={heroImage} />
                </HeroImageContainer>
            </Hero>
            <Features>
                <FeatureList>
                    {Copy.buyingGuideFeatures.map(feature =>
                        <FeatureItem xs={12} sm={6} md={4} lg={4} xl={4}>
                            <FeatureItemIcon src={feature.icon} />
                            <FeatureItemText>
                                {feature.title}
                            </FeatureItemText>
                            <FeatureItemSubText>
                                {feature.subtext}
                            </FeatureItemSubText>
                        </FeatureItem>
                    )}
                </FeatureList>
            </Features>
            <Steps>
                <HowItWorksStepsText>
                    {Copy.buyingGuideStepsTitle}
                </HowItWorksStepsText>
                {
                    Copy.buyingGuideSteps.map((step, index) =>
                        <Step currentIndex={index}>
                            <StepTextContainer>
                                <StepNumber>0{index + 1}</StepNumber>
                                <StepText>{step.title}</StepText>
                                <StepSubText>{step.subtext}</StepSubText>
                            </StepTextContainer>
                            <StepIcon src={step.icon} currentIndex={index} />
                        </Step>
                    )
                }

            </Steps>


        </Container>
    )
}

export default BuyingGuide;