import React from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';

const SideMenuColumn = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
    background: ${Colors.lightGrey};
    min-height: 100vh;
    font-family: ${Fonts.PRIMARY};
    .nav-link.active {
        background-color: ${Colors.white} !important;
        color: ${Colors.blue} !important;
    }
    @media (max-width: 780px) {
        min-height: auto;
        height: auto;
    }
`;

const NavItem = styled(Nav.Item)`
`;

const NavLink = styled(Nav.Link)`
    padding: 20px;
    padding-left: 50px;
    color: ${Colors.midGrey};
    outline: none;
    
`

const Column = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
`;

const TabContainer = styled(Tab.Container)`
`

const UserName = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-align: center;
    background: ${Colors.lightGrey};
`

const TabContent = styled(Tab.Content)`
    padding: 0px;
    background: ${Colors.lightGrey};
    min-height: 100vh;
    @media (max-width: 780px) {
        width: 100%;
        margin-left: 15px;
        min-height: 100vh;
    }
`;

const SideMenu = (props) => {
    const { menuItems, contentItems, name } = props;
    return (
        <TabContainer defaultActiveKey={menuItems[0].key}>
            <Row>
                <SideMenuColumn sm={3}>
                <UserName>{name}</UserName>

                    <Nav variant="pills" className="flex-column">
                        {
                            menuItems.map(menuItem =>
                                <NavItem>
                                    <NavLink eventKey={menuItem.key}>{menuItem.label}</NavLink>
                                </NavItem>
                            )
                        }
                    </Nav>
                </SideMenuColumn>
                <Column sm={9}>
                    <TabContent>
                        {
                            contentItems.map(content =>
                                <Tab.Pane eventKey={content.key}>
                                    {content.component()}
                                </Tab.Pane>
                            )
                        }
                    </TabContent>
                </Column>
            </Row>
        </TabContainer>
    )
}

export default SideMenu;