import React from "react";
import styled from 'styled-components';

const AffirmLabelContainer = styled.div`
  background-color: #ffffff;
  border: none;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const AffirmLabel = ({ onClick }) => {
  return (
    <div style={{ display: "flex" }}>
      <AffirmLabelContainer>
        <img
          src="https://cdn-assets.affirm.com/images/buttons/checkout/60x296-white.png"
          alt="Affirm Button"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto",
          }}
        />
      </AffirmLabelContainer>
    </div>
  );
};

export default AffirmLabel;
