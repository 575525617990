import axios from 'axios';
import {
    SHOPIFY_API
} from '../../../constants/API';
import {
    GET_COLLECTION_PRODUCTS_BY_HANDLE,
} from '../types';

const getCollectionProductsByHandle = (handle, pageSize) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_COLLECTION_PRODUCTS_BY_HANDLE,
                loadingProductsSections: true,
                collectionProducts: [],
            })
            const allProductsResponse = await axios.get(`${SHOPIFY_API}/getCollectionByHandle/${handle}/${pageSize}`)
            dispatch({
                type: GET_COLLECTION_PRODUCTS_BY_HANDLE,
                loadingProductsSections: false,
                collectionProducts: allProductsResponse.data,
            })

        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_COLLECTION_PRODUCTS_BY_HANDLE,
                loadingProductsSections: false,
                trendingAll: [],
                trendingAdidas: [],
                trendingNike: [],
                trendingJordan: [],
                newReleasesForGender: [],
            })
        }
    }
}

export { getCollectionProductsByHandle };
