const BidExpiryOptions = [
    {
        label: '1 day',
        value: 1
    },
    {
        label: '3 days',
        value: 3
    },
    {
        label: '7 days',
        value: 7
    },
    {
        label: '14 days',
        value: 14
    },
    {
        label: '30 days',
        value: 30
    },
    {
        label: '60 days',
        value: 60
    },

]

export default BidExpiryOptions;