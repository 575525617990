import React from "react";

import Steps, { Step } from "rc-steps";
import styled from "styled-components";
import Colors from '../constants/Colors';
import steps from "../constants/steps";

const StyledStep = styled(Step)`
  &.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
      color: ${Colors.blue};
  }
  &.rc-steps-item-finish .rc-steps-item-icon {
  border-color: ${Colors.blue};
  }
  &.rc-steps-item-finish .rc-steps-item-tail:after {
  background: ${Colors.blue};
  }
`;

const renderTracking = (order) => {
  return (
    <div className="rc-steps-item-description" style={{paddingBottom: '0px'}}>
      <p>Your order has been shipped and is on its way to you.</p>
    </div>
  )
}

export default function OrderStatusProgressBarSteps({index, order}) {
    return (
      <Steps direction="vertical" current={index} status="wait">
        {steps.map(step => (
          (step.title === "Shipped" && index === steps.length) ?
          <StyledStep key={step.title} title={step.title} description={renderTracking(order)} />
          :
          <StyledStep key={step.title} title={step.title} description={step.description} />
        ))}
      </Steps>
    );
}