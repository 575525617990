import React from 'react';
import styled from 'styled-components';
import Fonts from '../constants/Fonts';
import Colors from '../constants/Colors';
import ProductScroller from './ProductScroller';
import { Link } from 'react-router-dom';

const Container = styled.div`
    margin-bottom: 60px; 
    @media (max-width: 780px) {
        padding: 20px;
        margin-bottom: 0px; 
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 15px;
    @media (max-width: 780px) {
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
`;

const HeaderTitle = styled.p`
    font-size: 14px;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.blue};
`;

const HeaderButtonText = styled(Link)`
    font-size: 14px;
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.blue};
    cursor: pointer;
`;

const ProductSection = (props) => {
    const {
        headerTitle,
        headerButtonText,
        products
    } = props;
    return (
        <Container>
            <Header>
                <HeaderTitle>{headerTitle}</HeaderTitle>
                <HeaderButtonText to={`/browse/${headerTitle}`}>{headerButtonText}</HeaderButtonText>
            </Header>
            <ProductScroller 
                products={products}
            />
        </Container>
    )
}

export default ProductSection;