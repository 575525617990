
const FilterOptions = {
    gender: [
        'men',
        'women',
        'GS'
    ],
    brands: [
        'Nike',
        'Jordan',
        'Adidas'
    ],
    Sizes: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14, 15, 16, 17, 18],
    menSizes: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14, 15, 16, 17, 18],
    womenSizes: ['4W', '4.5W', '5W', '5.5W', '6W', '6.5W', '7W', '7.5W', '8W', '8.5W', '9W', '9.5W', '10W', '10.5W', '11W', '11.5W', '12W', '13W'],
    GSSizes: ['4Y', '4.5Y', '5Y', '5.5Y', '6Y', '6.5Y', '7Y'
    ],
};

export default FilterOptions;