const privacyHTML = `<p><strong>PRIVACY</strong></p>
<p>This Privacy Policy of www.shopwithsafe.com spells out the obligations of the Users/Members and the website owners. This Privacy Policy is in continuation of the Terms and Conditions for www.shopwithsafe.com therefore both documents should be read together.</p>
<p>This privacy policy has been developed to comply with Canada&rsquo;s Personal Information Protection and Electronic Documents Act ("PIPEDA"). PIPEDA sets out rules for the collection, use and disclosure of personal information in the course of commercial activity as defined in the Act. Along with PIPEDA this privacy policy is also in compliance with Provincial Privacy Laws of British Columbia (Personal Information Protection Act).</p>
<p>Shop With Safe Inc. shall not (a) be subject to any obligations of confidentiality regarding any User Generated Content except as specified in Shop With Safe Inc. Privacy Policy , as set forth in any additional terms and conditions relating to specific services, or as otherwise specifically agreed to by us or required by law; (b) be obligated to pay you or any third party any compensation for any User Generated Content; or (c) be required to respond to any of your submissions of User Generated Content.</p>
<p><br /><br /><br /></p>
<p><strong>Information That We Collect</strong></p>
<p>Depending on how Users/Members use the Website, we may ask Users/Members to share personally identifiable information ("Personal Information") with us. Whether Users choose to share their Personal Information is completely up to you, but you must keep in mind that Users may not be able to access some of the areas of the Website as effectively and fully use the services we offer on the Website without sharing adequate Personal Information. Personal information will be used for only those purposes to which the individual has consented with the following exceptions, as permitted under PIPEDA. Personal Information includes any information that identifies or can be used to identify, contact or locate the Users. If you send us information about another person, you ensure that it is accurate and in good faith . Below are some of the situations wherein Users Personal Information is collected:</p>
<ol>
<li>Account Information. Users may register on Website and share their standard contact information. In addition, we may collect and store any personal information you provide while using our Service or in some other manner. This may include identifying information, such as your name, address (shipping and billing address), email address verification and telephone number; other kind of identifying information. We may ask additional information from sellers and traders in order to confirm the Intellectual property ownership of the products or any other related information related to the products in order to verify the authenticity of the products.</li>
<li>We may collect Users/Members email address to send them account confirmations, notifications for alerts, and to provide services to Users in connection with the Website. Subject to Users consent, we may also send to Users promotional emails, such as monthly newsletters regarding the properties and new service offerings;</li>
<li>We also collect Users Personal Information for administrative purposes, such as addressing account and billing issues, troubleshooting Website issues, investigating abuse on the Website, responding to User enquiries and enforcing and applying our Terms and Conditions of Service.</li>
</ol>
<p><br /><br /><br /></p>
<p><strong>How We Use Your Information</strong></p>
<p>We use information that we collect about you or that you provide us:</p>
<ul>
<li>To present our Website and its contents to you.</li>
<li>To ship the goods at your doorstep.</li>
<li>To authenticate the products we receive from sellers and traders</li>
<li>To provide you with information, services that you request from us.</li>
<li>To provide you with notices about your account, including expiration and renewal notices.</li>
<li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including, for billing and collection.</li>
<li>To notify about changes of our Website or any services we offer or provide through it.</li>
<li>To allow you to participate in interactive features on our Website.</li>
<li>For any other purpose with your consent.</li>
</ul>
<p>Shop With Safe Inc. uses IP addresses for systems administration purposes. IP addresses are not used to track a User&rsquo;s session. This information only helps us determine how often different areas of our Website are visited and the city in which the User is located. We do not link IP address to any information that is personally identifiable.</p>
<p>However, we may also release Personal Information or other information we collect, if we believe that such action is necessary to: (I) comply with legal requirements and processes; (II) identify, contact or bring legal action against persons or entities who are or we believe have caused or might cause injury to us or a third party; (III) defend or respond to claims brought or threatened against us, our employees, service providers, users of our Services or others; or (IV) otherwise to protect, assert rights, interests of Shop With Safe Inc. and of our employees, service providers, Users of the Services or others. Any such release decisions may be made by us in our sole discretion.</p>
<p>While we try to be selective in working with third parties, we are not responsible for their use of your personal information. Where payments are involved, we must release your credit card information to credit card authorization service providers and associated banks to process your payment for purchases, returns or refunds.</p>
<p>In addition, since member information on our Website is a business asset, in case we are reorganized or sold to or merged with another company, we may sell, assign, or transfer your personal information to a purchaser of all or substantially all of our business assets, or to an affiliate, as applicable.</p>
<p><strong>Cookies</strong></p>
<p>We collect information via cookies and other similar technologies (such as web beacons). Cookies are small text files that are automatically placed on your computer or mobile device when you visit almost any Website. They are stored by your Internet browser. Cookies contain basic information about your Internet use. Your browser sends these cookies back to the Website every time you revisit it, so it can recognize your computer or mobile device and personalize and improve your Website experience.</p>
<p>Shop With Safe Inc. uses cookies for the following general purposes:</p>
<ul>
<li>To help us recognize your browser as a previous visitor and save and remember any preferences that may have been set while your browser was visiting our Website. For example, if you register on our Website, we may use cookies to remember your registration information, so you do not need to log into our site each time you visit. We may also record your password in a cookie, if you checked the box entitled "Keep me signed in on this computer unless I sign out." Please note that member IDs, passwords, and any other account-related data included in such cookies are encrypted for security purposes.</li>
<li>To help us customize the content, experience, and advertisements provided to you on our Website and on other Websites across the Internet. For example, when you access a web page, a cookie is automatically set by us, our service providers, or our partners to recognize your browser as you navigate on the Internet and to present you with information and advertising based on your apparent interests. To help measure and research the effectiveness of features and offerings, advertisements, and email communications (by determining which emails you open and act upon).</li>
</ul>
<p>The Help portion of the toolbar on most browsers should tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable non-essential types of cookies.</p>
<p>Our Website may also use Web beacons (also known as clear gifs, pixel tags or Web bugs), which are tiny graphics with a unique identifier, similar in function to cookies, that are placed in the code of a Web page. We use Web beacons to monitor the traffic patterns of users from one page within our Website to another, to deliver or communicate with cookies, to understand whether you have come to our Website from an online advertisement displayed on a third-party Website, and to improve Website performance. We also may allow our service providers to use Web beacons to help us understand which emails have been opened by recipients and to track the visitor traffic and actions on our Website. This helps us measure the effectiveness of our content and other offerings. If the Cookies are sent from third parties e.g. social networking Websites, Shop With Safe Inc. shall not be liable.</p>
<p>Most web browsers automatically accept cookies, but you can disable this function so that your browser will not accept cookies. Please be aware that disabling this function may impact your use and enjoyment of this Website, as declining cookies, certain functionalities of our Website may not be available to you</p>
<p><br /><br /></p>
<p><strong>Opting Out or Unsubscribing of Cookies and Email Newsletters</strong></p>
<p>You may opt out of certain collections of cookies or other web- or mobile device based enhancements, by changing your browser settings or going into your preferences on your mobile device and restricting the transmission of certain information. Your limitation of the collection of this information, however, may disable our ability to provide you with the most relevant services.</p>
<p>We collect your name and e-mail address for the purpose of distributing our daily, weekly or monthly newsletter service. The e-mail address provided will be used for delivery of information you have requested from this web site.</p>
<p>The information we collect is used expressly to provide the services requested. It is not shared with other organizations for commercial purposes. We will never sell or rent your email address for any reason.</p>
<p>Our Subscriptions are in compliance with Canada&rsquo;s Anti-Spam Law (CASL), as amended on of July 1, 2014</p>
<p>To unsubscribe from our emails or other communication from us, you may follow the "unsubscribe" link at the bottom of the email, sign onto your account and change settings, or email us directly at info@shopwithsafe.com.</p>
<p><strong>Right to Access or Correct Personal Information</strong></p>
<p>You have the right to access and correct your personal information. If you want to review or correct your personal information, you can visit your account profile page, or contact us through the Support Center, or you may send us an email at the address noted in the Contact Information section above.</p>
<p><br /><br /></p>
<p><strong>Deletion of the Account</strong></p>
<p>Should you ever decide to delete your Account, you may do so by emailing info@shopwithsafe.com. If you terminate your Account, any association between your Account and information we store will no longer be accessible through your Account. However, given the nature of sharing on the Services, any public activity on your Account prior to deletion will remain stored on our servers and will remain accessible to the public</p>
<p><br /><br /></p>
<p><strong>Security</strong></p>
<p>Please note that no data transmission or storage can be guaranteed to be 100% secure. As a result, while we strive to protect the information we maintain, we cannot guarantee or warrant the security of any information you disclose or transmit to our Services and cannot be responsible for the theft, destruction, or inadvertent disclosure of information.</p>
<p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We advise you not to share your password with anyone.</p>
<p><br /><br /></p>
<p><strong>International Users</strong></p>
<p>Shop With Safe Inc. is based in Canada, and, regardless of where you use our Services or otherwise provide information to us, the information may be transferred to and maintained on servers located in Canada or other countries. Note that the laws, regulations and standards of the country in which this information is stored may be different from your own country. By using our Services, you consent to this collection, transfer, storage, and processing of information to and in Canada and other countries.</p>
<p><br /><br /><br /></p>
<p><strong>Social Networks</strong></p>
<p>We offer you the option of linking your social networking (such as Twitter and Facebook) and email accounts (such as Gmail) with your Shop With Safe Inc. Account. Some of our Services require you to link accounts. For example, to use our Automatic Daily Updating Service, you must link your email account to your Shop With Safe Inc. Account.</p>
<p>By creating an account or using our Services and linking to your social networks or your email services, you authorize us to collect, store, and use certain information from those platforms in accordance with this Privacy Policy. We collect only the information that you request those platforms to share with us. You may also choose to grant us additional permissions (or remove permissions) at any time.</p>
<p>These third parties will provide information to us subject to their privacy policies and terms of use. We encourage you to read those policies. We are not responsible for the information practices or terms of other platforms. You can prevent collection of Personal Information from social networks and email platforms by not linking those accounts with our Services.</p>
<p><br /><br /></p>
<p><strong>Third-Party Websites</strong></p>
<p>This website may link to, or be linked to, other websites not maintained by us. Shop With Safe Inc. Website is not responsible for the privacy policies or practices of any third parties or third-party websites, including any linked or linking websites. To the extent that you disclose personally identifiable information to any such third parties, different rules may apply to their use and disclosure of personal information that you disclose to them. We encourage you to review the privacy policies of such websites before disclosing your personal information to them. If you link to Third Party Websites, you may be subject to those Third Party Websites' terms and conditions and other policies. Shop With Safe Inc. makes no representation or guarantee as to the accuracy or authenticity of the information contained in any such Third-Party Website, and your linking to any other Website is completely at your own risk and Shop With Safe Inc. disclaims all liability thereto.</p>
<p><br /><br /></p>
<p><strong>Child Protection</strong></p>
<p>Our Services are not intended for or directed to children. Children under 13 are not eligible to use our Services. Our policies are compliant with the Ministry of Children &amp; Family Development of British Columbia and Canadian Child Welfare Research Portal of Canada. By using the Services, you represent that you are at least 13 years old and understand that you must be at least 13 years old in order to create an account and purchase the Services advertised through our websites. We do not knowingly collect or maintain Personal Information from children under the age of 13. If we learn that we have inadvertently gathered Personal Information from children younger than the age of 13, we will take reasonable measures to delete or destroy such information from our records.</p>
<p><strong>Your Rights Under the General Data Protection Regulations</strong></p>
<p>If you are a member of the EU, you have the following rights:</p>
<p>Request More Information. You can request more information about the data we collect should you wish to learn more or for any reason. Or if you wish to make a complaint.</p>
<p>Access Any Data We&rsquo;ve Collected. You can request to see exactly what data we have collected that is of a personally identifiable nature.</p>
<p>Update Any Data We&rsquo;ve Collected. You may request that any or all of personally identifiable information we have collected be rectified, modified or otherwise updated.</p>
<p>Delete Any or All Data We&rsquo;ve Collected. You may request that any or all of personally identifiable information we have collected be deleted and removed from our records.</p>
<p>Withdraw Your Consent to The Collection and Use of Your Data. You may withdraw your consent to the collection of your data at any time</p>
<p>In order to exercise any of these rights, simply contact us at: <a href="mailto:info@shopwithsafe.com">info@shopwithsafe.com</a></p>`;

export default privacyHTML;
