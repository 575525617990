import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Copy from '../../constants/Copy';
import heroImage from '../../assets/images/how-it-works-hero.png';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
const Container = styled.div`
    
`;
const HeroTextContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 80px;
    @media (max-width: 780px) {
        padding-left: 0px;

    }
`;
const HeroImageContainer = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    @media (max-width: 780px) {
        width: 100%;
    }
`;
const Hero = styled.div`
    display: flex;
    height: calc(100vh - 90px);
    @media (max-width: 780px) {
        flex-direction: column-reverse;
        height: auto;
        padding: 40px;
    }
`;
const HeroText = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 48px;
`;
const HeroSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    font-size: 18px;
`;
const HeroImage = styled.img`
    width: 100%;
    object-fit: contain;
    @media (max-width: 780px) {
        max-height: 300px;
    }
`;
const HeroButton = styled(Button)`
    width: 70%;
    background: ${Colors.blue};
    color: ${Colors.white};
    @media (max-width: 780px) {
        width: 100%;
    }
`;

const Features = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    background: ${Colors.lightBackground};
`;
const FeatureText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;
`;
const FeatureSubText = styled.div`
    font-family: ${Fonts.PRIMARY};
    font-size: 12px;
    color: ${Colors.midGrey};
    text-align: center;
`;
const FeatureList = styled(Row)`
    margin-top: 40px
`;
const FeatureItem = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
`;
const FeatureItemText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-top: 20px;
`;
const FeatureItemIcon = styled.img`
    width: 60px;
    height: 60px;
`;
const FeatureItemSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
`;

const Steps = styled.div`s
    padding-bottom: 100px;
    padding-top: 100px;
`;
const Step = styled.div`
    display: flex;
    align-items: center;
    justify-center: center;
    flex-direction: column;
    padding: 40px 20px 40px 20px;
    width: 100%;
    @media (max-width: 780px) {
        padding: 20px 20% 20px 20%;
        flex-direction: column;

    }

`;

const StepNumber = styled.div`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.white};
    font-size: 32px;
    background: ${Colors.blue};
    justify-content: center;
     align-items: center;
    border-radius: 100%;
    text-align: center;
    margin: 5px 20px;
    font-size: 15px;
    padding: 15px;
    display: flex;
    height: 50px;
    width: 50px;
    color: #fff;
    margin-bottom: 20px;
    
`;
const StepText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    margin-bottom: 0px;
    font-size: 24px;
    @media (max-width: 780px) {
        text-align: center;
    }
`;
const StepSubText = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.midGrey};
    @media (max-width: 780px) {
        text-align: center;
    }
`;
const HowItWorksStepsText = styled.p`
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
`;

const HowItWorks = () => {
    return (
        <Container>
            <Hero>
                <HeroTextContainer>

                    <HeroText>
                        {Copy.howItWorksTitle}
                    </HeroText>
                    <HeroSubText>
                        {Copy.howItWorksSubText}
                    </HeroSubText>

                    <Link to='/browse'>
                        <HeroButton>
                            Browse Sneakers
                        </HeroButton>
                    </Link>

                </HeroTextContainer>
                <HeroImageContainer>
                    <HeroImage src={heroImage} />
                </HeroImageContainer>
            </Hero>
            <Features>
                <FeatureText>
                    {Copy.featureTitle}
                </FeatureText>
                <FeatureSubText>
                    {Copy.featureSubText}
                </FeatureSubText>
                <FeatureList>
                    {Copy.features.map(feature =>
                        <FeatureItem xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FeatureItemIcon src={feature.icon} />
                            <FeatureItemText>
                                {feature.title}
                            </FeatureItemText>
                            <FeatureItemSubText>
                                {feature.subtext}
                            </FeatureItemSubText>
                        </FeatureItem>
                    )}
                </FeatureList>
            </Features>
            <Steps>
                <HowItWorksStepsText>
                    {Copy.howItWorksStepsTitle}
                </HowItWorksStepsText>
                {
                    Copy.howItWorksSteps.map((step, index) =>
                        <Step>
                                    <StepNumber>{index + 1}</StepNumber>
                                <StepText>{step.title}</StepText>
                                <StepSubText>{step.subtext}</StepSubText>
                        </Step>
                    )
                }

            </Steps>

        </Container>
    )
}

export default HowItWorks;