import {
    LOGIN_LOADING,
    UPDATE_USER_LOADING,
    UPDATE_USER,
    TEXT_APP_LINK
} from '../actions/types';
const defaultState = {
    loginLoading: true,
    isLoggedIn: false,
    fbUser: {},
    dbUser: {},
    updatingUser: false,
    textingAppLink: false
}
const UserReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.loginLoading,
                isLoggedIn: action.isLoggedIn,
                dbUser: action.dbUser,
                fbUser: action.fbUser,
                token: action.token
            }
        case UPDATE_USER_LOADING:
            return {
                ...state,
                updatingUser: action.updatingUser
            }
        case UPDATE_USER:
            return {
                ...state,
                updatingUser: action.updatingUser,
                dbUser: action.dbUser
            }
        case TEXT_APP_LINK:
            return {
                ...state,
                textingAppLink: action.textingAppLink
            }
        default:
            return state;
    }
}

export default UserReducer;