import React from 'react';
import Account from '../screens/Account';
import Login from '../screens/Account/login';
import Home from '../screens/Home';
import Browse from '../screens/Browse';
import ProductPage from '../screens/ProductPage';
import Buy from '../screens/Buy';
//About 
import Authentication from '../screens/About/authentication';
import BuyingGuide from '../screens/About/buyingGuide';
import Contact from '../screens/About/contact';
import HowItWorks from '../screens/About/howItWorks';
import Privacy from '../screens/About/privacy';
import Terms from '../screens/About/terms';
import FAQ from '../screens/About/faq';
import App from '../screens/About/app';
import Blog from '../screens/About/blog';
import Locations from '../screens/Locations';
import Confirm from '../screens/Confirm';

const routes = [
    {
        path: '/',
        screen: (props) => <Home {...props} />,
        exact: true
    },
    {
        path: '/login',
        screen: (props) => <Login {...props} />,
        exact: true
    },
    {
        path: '/browse',
        screen: (props) => <Browse {...props} />,
        exact: true
    },
    {
        path: '/browse/:brand',
        screen: (props) => <Browse {...props} />,
        exact: true
    },
    {
        path: '/confirm',
        screen: (props) => <Confirm {...props} />,
        exact: true
    },
    {
        path: '/account',
        screen: (props) => <Account {...props} />,
        exact: true
    },
    {
        path: '/product/:title/:shopify?',
        screen: (props) => <ProductPage {...props} />,
        exact: true
    },
    {
        path: '/buy',
        screen: (props) => <Buy {...props} />,
        exact: true
    },

    {
        path: '/authentication',
        screen: (props) => <Authentication {...props} />,
        exact: true
    },
    {
        path: '/faq',
        screen: (props) => <FAQ {...props} />,
        exact: true
    },
    {
        path: '/buyingGuide',
        screen: (props) => <BuyingGuide {...props} />,
        exact: true
    },
    {
        path: '/Contact',
        screen: (props) => <Contact {...props} />,
        exact: true
    },
    {
        path: '/howItWorks',
        screen: (props) => <HowItWorks {...props} />,
        exact: true
    },
    {
        path: '/locations',
        screen: (props) => <Locations {...props} />,
        exact: true
    },
    {
        path: '/privacy',
        screen: (props) => <Privacy {...props} />,
        exact: true
    },
    {
        path: '/terms',
        screen: (props) => <Terms {...props} />,
        exact: true
    },
    {
        path: '/blog',
        screen: (props) => <Blog {...props} />,
        exact: true
    },
    {
        path: '/app',
        screen: (props) => <App {...props} />,
        exact: true
    },
]

export default routes;