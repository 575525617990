import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import SizeGrid from "../../components/SizeGrid";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 90px - 78px);
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
    padding-left: 0px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

export const Logo = styled.img`
  /* height: 20px; Adjust the height as needed */
  width: 90px;
  margin: 0 5px;
`;

export const ProductInfoContainer = styled.div`
  margin-left: 50px;
  max-width: 40vw;
  @media (max-width: 780px) {
    width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
export const ProductCondition = styled.p`
  @media screen and (max-width: 780px) {
    font-size: 12px;
  }
  font-size: 16px;
  margin: 0;
  color: ${Colors.yellow};
  font-weight: 800;
  font-family: ${Fonts.PRIMARY_BOLD};
`;
export const ProductTitle = styled.h1`
  font-size: 32px;
  font-family: ${Fonts.PRIMARY_BOLD};
  color: ${Colors.blue};
`;
export const ProductPrice = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 28px;
  margin-bottom: 0px;
`;
export const CompareProductPrice = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 20px;
  text-decoration: line-through;
  margin-bottom: 0px;
  color: grey;
  margin-left: 10px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProductDesc = styled.div`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.midGrey};
  max-width: 100vw;
  margin-bottom: 20px;
  @media (max-width: 780px) {
    margin-bottom: 20px;
  }
`;
export const LinkContainer = styled(Link)``;
export const ErrorContainer = styled.div``;

export const BuyButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background: ${Colors.blue};
  color: ${Colors.white};
  border-radius: 5px;
  border-color: ${Colors.blue};
`;
export const Label = styled.p`
  margin-bottom: 10px;
  margin-right: 10px;
  color: ${Colors.midGrey};
  font-family: ${Fonts.PRIMARY};
  font-size: 16px;
`;

export const ProductFeaturesContainer = styled.div`
  margin-bottom: 20px;
`;

export const ProductFeatures = styled.p`
  margin-bottom: 10px;
  margin-top: 0px;
  color: ${Colors.midGrey};
  font-family: ${Fonts.PRIMARY};
`;

export const FeaturesText = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  color: ${Colors.midGrey};
  font-family: ${Fonts.PRIMARY};
`;

export const ProductPageSizeGrid = styled(SizeGrid)`
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
`;

export const ShippingText = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.midGrey};
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

export const Icon = styled.img`
  @media screen and (max-width: 780px) {
    width: 15px;
    height: 15px;
  }
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

export const PaymentOptions = styled.div`
    display:flex;
    gap: 10px;
    flex-direction:column;
    width: 100%;
    height: 100%;
    `;

// export const SellButton = styled(Button)`
//     width: 100%;
//     margin-top: 10px;
//     margin-top: 10px;
//     background: ${Colors.white};
//     color: ${Colors.blue};
//     border-radius: 5px;
//     border-color: ${Colors.blue};
// `
