
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import routes from './routes';
import topbarMenu from './topbarMenu';
import connectScreen from '../redux/connectScreen';
import Topbar from '../components/Topbar';
import LoadingScreen from '../components/LoadingScreen';
import Footer from '../components/Footer';

class Nav extends Component {
    componentDidMount() {
        this.props.setUserState()
    }
    render() {

        const { loginLoading } = this.props.user;
        if (loginLoading) return <LoadingScreen />
        return (
            <Router>
                <Topbar
                    menuItems={topbarMenu}
                />
                <Switch>
                    {
                        routes.map(route => {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact}
                                >
                                    {route.screen()}
                                </Route>
                            )
                        })
                    }
                </Switch>
                <Footer />
            </Router>
        )
    }
}

export default connectScreen(Nav);