const topBarMenu = [
    {
        path: '/browse',
        name: 'Browse'
    },
    {
        path: '/app',
        name: 'App'
    },
    {
        path: '/locations',
        name: 'Locations'
    },
    // SELL FEATURE DISABLE {
    //     path: '/sell',
    //     name: 'Sell'
    // },
    {
        path: '/about',
        name: 'About',
        isDropDown: true,
        paths: [
            
            {
                path: '/howItWorks',
                name: 'How It Works'
            },
            {
                path: '/buyingGuide',
                name: 'Buying Guide'
            },
            {
                path: '/authentication',
                name: 'Authentication'
            },
        ]
    },
    {
        path: '/faq',
        name: 'FAQ'
    },
    {
        path: '/account',
        name: 'Account'
    },
]
export default topBarMenu