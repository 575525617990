import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";

export const Container = styled.div`
  overflow-x: hidden;
`;

export const CategoryContainer = styled.div`
  height: 100%;
  background: ${Colors.lightBackground};
`;
export const ProductSections = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 780px) {
    margin: 0px;
    width: 100%;
  }
`;

export const TabSearchContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 5%;
  align-items: center;
  height: 80px;
  @media (max-width: 780px) {
    justify-content: center;
    width: 100%;
  }
`;

export const SearchBarContainer = styled.div`
  background: ${Colors.white};
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: 50%;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const AutoCompleteContainer = styled.div`
  position: absolute;
  width: 32%;
  margin-top: 10px;
  border-radius: 5px;
  max-height: 400px;
  overflow-y: scroll;
`;

export const AutoCompleteItem = styled(Link)`
  width: 100%;
  background: white;
  display: flex;
  padding: 10px;
  border-top: 1px solid ${Colors.lightGrey};
  align-items: center;
`;

export const ProductSKU = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
  color: ${Colors.midGrey};
`;
export const ProductTitle = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
  color: ${Colors.black};
`;
export const ProductImage = styled.img`
  width: 30%;
  object-fit: contain;
`;
export const ProductTextContainer = styled.div`
  margin-left: 20px;
`;
export const InnerTabContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  @media (max-width: 780px) {
    width: 100%;
  }
`;