import React, { useState } from 'react';
import styled from 'styled-components';
import Faq from 'react-faq-component';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import Colors from '../../constants/Colors';
import Copy from '../../constants/Copy';

const Container = styled.div``;
const TabsContainer = styled.div`
    background: ${Colors.blue};
`
const Content = styled.div`
    padding: 40px;
`;

const FAQ = () => {
    const [selectedCategory, setCategory] = useState('Purchasing');

    const selectFAQData = () => {
        switch (selectedCategory) {
            case 'Purchasing':
                return Copy.faq.purchasing;
            case 'Shipping':
                return Copy.faq.shipping;
            case 'Return Policies':
                return Copy.faq.returns;
            case 'Safe Certified Products':
                return Copy.faq.certifiedProducts;
            default:
                return Copy.faq.purchasing;
        }
    }

    return (
        <Container>
            <TabsContainer>
                <Tabs>
                    <Tab
                        selectedTab={selectedCategory}
                        tab={'Purchasing'}
                        onTabClicked={(category) => setCategory(category)}
                    />
                    <Tab
                        selectedTab={selectedCategory}
                        tab={'Shipping'}
                        onTabClicked={(category) => setCategory(category)}
                    />
                    <Tab
                        selectedTab={selectedCategory}
                        tab={'Return Policies'}
                        onTabClicked={(category) => setCategory(category)}
                    />
                    <Tab
                        selectedTab={selectedCategory}
                        tab={'Safe Certified Products'}
                        onTabClicked={(category) => setCategory(category)}
                    />
                </Tabs>
            </TabsContainer>
            <Content>
                <Faq data={selectFAQData()} />
            </Content>
        </Container>
    )
}

export default FAQ;