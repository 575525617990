import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Container = styled.div`
    margin-top: 40px;
    @media (max-width: 780px) {
        margin-top: 20px;
    }
`;

const InputContainer = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    border: 1px solid ${Colors.lightGrey};
`
const DollarSign = styled.h2`
    font-size: 28px;
    font-family: ${Fonts.PRIMARY_BOLD};
    background: ${Colors.lightGrey};
    margin-bottom: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

`
const ErrorMessage = styled.p`
    font-family: ${Fonts.PRIMARY};
    color: ${Colors.red};
    margin-top: 10px;
`
const Input = styled.input`
    border: none;
    width: 100%;
    padding: 10px;
    padding-left: 20px;
    font-size: 28px;
    font-family: ${Fonts.PRIMARY_BOLD};
    outline: none;
    ::placeholder { 
        color: ${Colors.lightGrey};
    }
`


const PriceInput = (props) => {
    const {
        value,
        onChange,
        error,
        placeholder
    } = props
    return (
        <Container>
            <InputContainer>
                <DollarSign>$</DollarSign>
                <Input
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            </InputContainer>
            <ErrorMessage>{error}</ErrorMessage>
        </Container>
    )
}

export default PriceInput;