import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

export default styled.button`
    width: ${props => props.width ? props.width : '100px'};
    @media (max-width: 780px) {
        width: ${props => props.mobileWidth ? props.mobileWidth : '100px'};
    }
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: ${Colors.yellow};
    color: ${Colors.blue};
    font-family: ${Fonts.PRIMARY_BOLD};
    font-size: 16px;
`