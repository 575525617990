import { useEffect } from 'react';

const RouteWidget = ({ fees }) => {
  useEffect(() => {
    if (fees && fees.total > 0) {
      window.Route.Protection.render({
        storeDomain: "www.shopwithsafe.com",
        subtotal: fees.total,
        currency: "CAD",
        environment: window.Route.Environment.Production,
        status: window.Route.Coverage.InactiveByDefault,
        merchantId: "merch_TAkCXhoHGUEH9ByYY4B6",
        storeName: "SAFE",
        theming: {
          mode: window.Route.Theme.Mode.LightMode,
          alignment: window.Route.Theme.Alignment.Left,
        }
      });
    }
  }, []);

  return null;
};

export default RouteWidget;
