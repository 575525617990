import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const ListContainer = styled(Container)`
    max-width: 100% !important;
    background: ${Colors.lightBackground};
    @media (max-width: 780px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
        max-width: 100vw !important;
        overflow-x: hidden !important;
    }
`

const ProductCard = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
`
const ProductTitle = styled.p`
    font-size: 12px;
    opacity: 0.7;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.greyishBlue};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin: 45px 0px 10px 0px;
    @media (max-width: 780px) {
        white-space: pre-wrap;
    }
    

`
const ProductPrice = styled.p`
    font-size: 18px;
    font-family: ${Fonts.PRIMARY_BOLD};
    color: ${Colors.greyishBlue};
    margin: 0px;
`
const ProductImage = styled.img`
    width: 100%;
    height: 100px;
    object-fit: contain;
    @media (max-width: 780px) {
        height: 150px;

    }
`
const TextContainer = styled.div``
const LinkContainer = styled(Link)`
    text-decoration: none;
    background-color: ${Colors.white};
    padding: 20px;
`

const ProductList = (props) => {
    const { products } = props;

    const getImage = (product) => {
        if (product.featuredImage) {
            return product.featuredImage !== null && product.featuredImage.originalSrc;
        }
        if (product.featuredImage === null) {
            return "https://res.cloudinary.com/dm5xjgl02/image/upload/v1668198427/Safe-73-jpg_t8olqa.jpg"
        }
        return product.images && product.images[0] ? product.images[0] : '';
    }
    const getPrice = (product) => {
        if (product.priceRangeV2) return `$${product.priceRangeV2.minVariantPrice.amount}`
        const stockXPrice = product && product.lowestAsk ? product.lowestAsk : null;
        if (
            product &&
            product.Listings &&
            product.Listings.length > 0
        ) {
            const lowestPricedListing = product.Listings.reduce((prev, curr) => Number(prev.price) < Number(curr.price) ? prev : curr);
            const { price } = lowestPricedListing;
            const priceToShow = !stockXPrice || price < stockXPrice ? price : stockXPrice;
            return `$${priceToShow}`
        } else if (stockXPrice) {
            return `$${stockXPrice}`
        }
        return 'Sold out'
    }
    return (
        <ListContainer>
                            <FadeIn>

            <Row gutterWidth={15}>
                    {
                        products.map(product =>
                            <ProductCard xs={6} sm={6} md={6} lg={4} xl={3} style={products.length < 4 && {width: "100%", maxWidth: "100%"}}>
                                <LinkContainer to={{
                                pathname: product.handle ? `/product/${product.handle}/?shopify=true` : `/product/${product.stockXHandle}`
                            }}>
                                    <ProductImage
                                        src={getImage(product)}
                                        alt='product-image'
                                    />
                                    <TextContainer>
                                        <ProductTitle>
                                            {product.title}
                                        </ProductTitle>
                                        <ProductPrice>
                                            {getPrice(product)}
                                        </ProductPrice>
                                    </TextContainer>
                                </LinkContainer>
                            </ProductCard>
                        )}
            </Row>
            </FadeIn>

        </ListContainer>
    )
}

export default ProductList;