import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import logo from '../images/logo.png';
import close from '../assets/images/close.svg';
import search from '../assets/images/search.png';
import menu from '../assets/images/menu.png';

const Logo = styled.img`
    width: auto;
    height: 60px;
`
const Container = styled.div`
    height: 90px;
    align-items: center;
    justify-content: space-between;
    position: ${props => props.position};
    display: flex;
    width: 100%;
    padding: 0 70px;
    box-sizing: border-box;
    background-color: ${props => props.backgroundColor};
    z-index: 9999;
    @media (max-width: 780px) {
        padding: 0 20px;
    }
`
const Nav = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.backgroundColor};
    @media (max-width: 780px) {
        flex-direction: column;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100vw;
        min-height: 100vh;
        padding-bottom: 400px;
        height: 100%;
        background: ${Colors.blue};
        padding-top: 100px;
        z-index: 999999;
        overflow-y: scroll;
    }
`

const MenuItem = styled(NavLink)`
    font-size: 17px;
    color: ${Colors.white};
    font-family: ${Fonts.PRIMARY};
    margin-left: 50px;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 780px) {
        margin-left: 0px;
        font-size: 48px;
        margin-top: 50px;
    }
`

const CloseButton = styled.img`
    display: none;
    @media (max-width: 780px) {
        display: flex;
        position: absolute;
        top: 40px;
        right: 40px;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
`
const HamburgerIcon = styled.img`
    width: 30px;
    height: 30px;
`

const SearchIcon = styled.img`
    width: 30px;
    height: 30px;
`

const DropDownMenuContainer = styled.div`
@media (max-width: 780px) {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
`;
const DropDownMenu = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${Colors.white};
    padding: 10px;
    border-radius: 10px;
    @media (max-width: 780px) {
        position: relative;
        background: transparent;
    }
`;
const DropDownMenuItem = styled(NavLink)`
    font-size: 17px;
    color: ${Colors.black};
    font-family: ${Fonts.PRIMARY};
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 10px;
    @media (max-width: 780px) {
        margin-left: 0px;
        font-size: 32px;
        margin-top: 10px;
        color: ${Colors.midGrey};
    }
`
const DropDownMenuLabel = styled.p`
    font-size: 17px;
    color: ${Colors.white};
    font-family: ${Fonts.PRIMARY};
    margin-left: 50px;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 0px;
    @media (max-width: 780px) {
        margin-left: 0px;
        font-size: 48px;
        margin-top: 50px;

    }
`;



const Topbar = ({ menuItems = [] }) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [position, setPosition] = useState('relative');
    const [backgroundColor, setBackgroundColor] = useState(Colors.blue);
    const [dropdownToShow, setDropdownToShow] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const { pathname } = useLocation();

    useEffect(() => {
        getTopBarSettings();
    })

    const getTopBarSettings = () => {
        if (
            pathname === '/'
        ) {
            setPosition('absolute');
            setBackgroundColor(Colors.blue);
        } else {
            setPosition('relative');
            setBackgroundColor(Colors.blue);
        }
    }

    //components 
    const MobileNavBar = () => (
        <>
            <NavLink
                key={0}
                to={'/browse'}
            >
                <SearchIcon
                    src={search}
                    alt='search-button'
                />
            </NavLink>

            <NavLink
                key={0}
                to={'/'}
            >
                <Logo src={logo} />
            </NavLink>
            <HamburgerIcon
                src={menu}
                alt='menu-button'
                onClick={() => setShowMobileMenu(true)}
            />
        </>
    )
    const MobileMenu = () => (
        <Nav
            backgroundColor={backgroundColor}
        >
            <CloseButton
                src={close}
                alt='close-button'
                onClick={() => setShowMobileMenu(false)}

            />
            {
                menuItems.map((item, index) =>
                    item.paths ?
                        <DropDownMenuContainer
                            key={index + 1}
                            onClick={() => setDropdownToShow(dropdownToShow === index ? null : index)}
                        >
                            <DropDownMenuLabel>
                                {item.name}
                            </DropDownMenuLabel>
                            {dropdownToShow === index &&
                                <DropDownMenu
                                >
                                    {item.paths.map((pathItem, pathItemIndex) =>
                                        <DropDownMenuItem
                                            key={pathItemIndex + 1}
                                            to={pathItem.path}
                                            activeStyle={{
                                                color: Colors.yellow
                                            }}
                                            onClick={() => setShowMobileMenu(false)}

                                        >
                                            {pathItem.name}
                                        </DropDownMenuItem>
                                    )}
                                </DropDownMenu>

                            }

                        </DropDownMenuContainer>
                        :
                        <MenuItem
                            key={index + 1}
                            to={item.path}
                            activeStyle={{
                                color: Colors.yellow
                            }}
                            onClick={() => setShowMobileMenu(false)}
                        >
                            {item.name}

                        </MenuItem>
                )
            }

        </Nav>
    )

    const BrowserMenu = () => (
        <>
            <NavLink
                key={0}
                to={'/'}
            >
                <Logo src={logo} />
            </NavLink>
            <Nav
                className="justify-content-end"
                backgroundColor={backgroundColor}
            >
                <CloseButton
                    src={close}
                    alt='close-button'
                />
                {
                    menuItems.map((item, index) =>
                        item.paths ?
                            <DropDownMenuContainer
                                key={index + 1}
                                onMouseEnter={() => setDropdownToShow(index)}
                                onMouseLeave={() => setDropdownToShow(null)}

                            >
                                <DropDownMenuLabel>
                                    {item.name}
                                </DropDownMenuLabel>
                                {dropdownToShow === index &&
                                    <DropDownMenu
                                    >
                                        {item.paths.map((pathItem, pathItemIndex) =>
                                            <DropDownMenuItem
                                                key={pathItemIndex + 1}
                                                to={pathItem.path}
                                                activeStyle={{
                                                    color: Colors.yellow
                                                }}
                                            >
                                                {pathItem.name}
                                            </DropDownMenuItem>
                                        )}
                                    </DropDownMenu>

                                }

                            </DropDownMenuContainer>
                            :
                            <MenuItem
                                key={index + 1}
                                to={item.path}
                                activeStyle={{
                                    color: Colors.yellow
                                }}
                            >
                                {item.name}

                            </MenuItem>
                    )
                }
            </Nav>
        </>
    )
    return (
        <Container
            mode="horizontal"
            defaultSelectedKeys={['1']}
            backgroundColor={backgroundColor}
            position={position}
        >
            {
                isMobile ?
                    <>
                        <MobileNavBar />
                        {
                            showMobileMenu ?
                                <MobileMenu />
                                : null
                        }
                    </> :
                    <BrowserMenu />
            }
        </Container>
    )
}

export default Topbar;