import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 30vw;
    @media (max-width: 780px) {
        width: 100vw;
        padding-left: 10vw;
        padding-right: 10vw;
    }
`;
const SelectedImage = styled.img`
    width: 30vw;
    height: auto;
    object-fit: contain;
    @media (max-width: 780px) {
        width: 80vw;
    }
`;
const ImageList = styled.div`
    overflow-x: scroll;

`;
const Image = styled.img`
    width: 100px;
    object-fit: contain;
    height: 100px;
`;

const Gallery = (props) => {
    const {
        images = [],
        onSelectImage,
        selectedImage
    } = props;
    return (
        <Container>
            <SelectedImage src={selectedImage} alt={"Product image"} />
            <ImageList>
                {images.map((image, index) => 
                    <Image
                        key={`${image}-${index}`} 
                        alt={"Product images"}
                        src={image} 
                        onClick={() => onSelectImage(image)}
                    />
                )}
            </ImageList>
        </Container>
    )
}

export default Gallery;