import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Colors from "../constants/Colors";

const Container = styled(Slider)`
  background-color: ${Colors.black};
  height: 100%;
  .slick-dots {
    bottom: 25px;
  }
  .slick-dots li button:before {
    font-size: 18px;
  }
  .slick-dots li button:focus {
    color: ${Colors.blue};
  }
  .slick-dots li button:hover {
    color: ${Colors.blue};
    opacity: 0.5;
  }
  .slick-dots .slick-active button:before {
    color: ${Colors.blue};
    opacity: 1;
  }
`;

const Image = styled.img`
  height: calc(100vh - 80px);
  width: 100%;
  object-fit: cover;
  :focus {
    outline: 0;
  }
`;

const Hero = ({ images }) => {
  return (
    <Container dots>
      {images.map((image, index) => (
        <div key={`${image}*${index}`}>
          <Image src={image} />
        </div>
      ))}
    </Container>
  );
};

export default Hero;
