import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Container = styled.div`
    display: flex;
    align-items: center;
`;
const Select = styled.select`
    font-family: ${Fonts.PRIMARY};
    font-size: 16px;
    width: 100%;
    border-color: ${Colors.blue};
    color: ${Colors.blue};
    outline: none;
`;

const Label = styled.p`
    margin-bottom: 0px;
    margin-right: 10px;
    color: ${Colors.midGrey};
    font-family: ${Fonts.PRIMARY};
    font-size: 16px;

`;
const Option = styled.option`
`;

const SizeSelector = (props) => {
    const {
        sizes,
        onSizeClick,
        selectedSize,
        labelText
    } = props;
    return (
        <Container>
            <Label>{labelText}</Label>
            <Select
                onChange={(e) => onSizeClick(e.target.value)}
                value={selectedSize}
            >
                {sizes.map(size =>
                    <Option value={size}>{size}</Option>
                )}
            </Select>
        </Container>
    )
}

export default SizeSelector;