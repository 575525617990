import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import SizeGrid from "../../components/SizeGrid";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px - 78px);
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
    padding-left: 0px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

export const ProductTitle = styled.h1`
  font-size: 32px;
  font-family: ${Fonts.PRIMARY_BOLD};
  color: ${Colors.blue};
`;

export const ProductSubTitle = styled.h1`
  font-size: 22px;
  font-family: ${Fonts.PRIMARY_BOLD};
  color: ${Colors.midGrey};
`;

export const BuyButton = styled(Button)`
  width: auto;
  margin-top: 20px;
  background: ${Colors.blue};
  color: ${Colors.white};
  border-radius: 5px;
  border-color: ${Colors.blue};
`;