import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
// import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import connectScreen from "../../redux/connectScreen";
//helpers
import getHighestBid from "../../helpers/getHighestBid";
import getProductPrice from "../../helpers/getProductPrice";
//components
import AddPaymentForm from "../../components/AddPaymentForm";
import CheckoutForm from "../../components/CheckoutForm";
import CheckoutHeader from "../../components/CheckoutHeader";
import CheckoutProduct from "../../components/CheckoutProduct";
import CheckoutTab from "../../components/CheckoutTab";
import FeeLine from "../../components/FeeLine";
import LoadingScreen from "../../components/LoadingScreen";
import PaymentForm from "../../components/PaymentForm";
import PriceInput from "../../components/PriceInput";
import Seperator from "../../components/Seperator";

import { Spinner } from "react-bootstrap";
import ConfirmScreen from "../../components/ConfirmScreen";
import ShippingForm from "../../components/ShippingForm";
import Copy from "../../constants/Copy";
import Checkbox from "../../components/Checkbox";
import swal from "sweetalert";
import AffirmLabel from "../../components/Affirm/AffirmLabel";
import AfterpayLabel from "../../components/Afterpay/AfterpayLabel";
import afterPayImage from "../../assets/images/afterpay-badge-blackonmint72x15@3x.png";
import AfterpayTile from "../../components/Afterpay/AfterpayTile";
import RouteWidget from "../../components/RouteWidget";
import { createCheckoutAffirm } from "../../helpers/affirm/createCheckoutAffirm";
import {
  BackButton,
  CheckoutButton,
  EditButton,
  CheckoutButtonBar,
  CheckoutButtonContainer,
  CheckoutFormContainer,
  Container,
  ErrorContainer,
  InfoContainer,
  Logo,
  CheckLabel,
} from "./styles";

const Buy = (props) => {
  // const gtmDispatch = useGTMDispatch();
  const location = useLocation();
  const { size, selectedVariant, stockXHandle, productId, shopifyHandle } =
    location.state;
  const [selectedTab, setSelectedTab] = useState("Buy Now");
  //SELL FEATURE DISABLED const [bidPrice, setBidPrice] = useState('');
  const bidPrice = "";
  const [errorMessage, setErrorMessage] = useState("");
  const [address, setAddress] = useState(null);
  const [payment, setPayment] = useState(null);
  const [expiresOn, setExpiresOn] = useState("30");
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [currentForm, setCurrentForm] = useState("checkout"); //checkout, payment, exipry, address and addPayment
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [isShopifyOrder, setIsShopifyOrder] = useState(false);

  // discount code
  const [discount, setDiscount] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [discountErrorMessage, setDiscountErrorMessage] = useState("");

  // route protection
  const [routeProtectionFee, setRouteProtectionFee] = useState(0);

  // created order
  const [createdOrder, setCreatedOrder] = useState(null);
  const [isAffirm, setIsAffirm] = useState(false);

  //email
  const [email, setEmail] = useState("");

  const { paymentMethods, loadingPaymentMethods, creatingPaymentMethod } =
    props.paymentMethod;
  const { shippingAddress } = props.shippingAddress;


  useEffect(() => {
    if (props.user.dbUser) {
      setEmail(props.user.dbUser);
    }
  }, [props.user.dbUser]);

  useEffect(() => {
    if (props.order.createdOrder) {
      setCreatedOrder(props.order.createdOrder);
    }
  }, [props.order.createdOrder]);

  useEffect(() => {
    const createRouteQuote = async (orderData) => {
      const iso_date = generateISO8601Date();
      const quote_id = await getQuoteId();
      try {
        const options = {
          method: "POST",
          url: "https://api.route.com/v2/orders",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            token: "d9219300-7c0a-4cbe-b2b2-fef29d2100a0",
          },
          data: {
            quote_id: quote_id,
            source_order_id: orderData.id,
            subtotal: fees.subtotal,
            shipping_total: fees.totalShipping,
            discounts_total: fees.discountAmount,
            taxes: fees.totalTaxes,
            currency: "CAD",
            insurance_selected: true,
            customer_details: {
              first_name: shippingAddress.name,
              last_name: shippingAddress.lastName,
              email: email,
            },
            shipping_details: {
              first_name: shippingAddress.name,
              last_name: shippingAddress.lastName,
              street_address1: shippingAddress.address,
              street_address2: shippingAddress.unitNumber,
              city: shippingAddress.city,
              province: shippingAddress.province,
              country_code: "CAN",
              zip: shippingAddress.postal,
            },
            line_items: [
              {
                source_product_id: productId.toString(),
                sku: orderData.stockXProduct.sku,
                name: orderData.stockXProduct.title,
                price: fees.subtotal,
                quantity: 1,
                upc: orderData.stockXProduct.sku,
                image_url: orderData.stockXProduct.images[0],
              },
            ],
            items: {
              shipping_method: "Next day",
              source_product_id: productId.toString(),
              source_id: productId.toString(),
              sku: orderData.stockXProduct.sku,
              name: orderData.stockXProduct.title,
              price: fees.subtotal,
              quantity: 1,
              upc: orderData.stockXProduct.sku,
              image_url: orderData.stockXProduct.images[0],
              fulfillment_status: "fullfiled",
              weight: {
                value: 0,
                weight_unit: "GRAM",
              },
            },
            source_created_on: iso_date,
            source_updated_on: iso_date,
            merchant_params: {
              membership: true,
              discount: true,
              expedited_shipping: true,
              promotion: true,
            },
          },
        };
        const { data } = await axios.request(options);
        return data;
      } catch (e) {
        throw new Error(e);
      }
    };

    const createRouteOrder = async () => {
      if (routeProtectionFee > 0 && createdOrder) {
        try {
          await createRouteQuote(createdOrder);
        } catch (error) {
          // Handle error here if needed
          swal(
            "Error",
            "Route Protection could not be added to your order. Please contact our support team.",
            "error"
          );
        }
      }
    };

    createRouteOrder();
  }, [routeProtectionFee, createdOrder]);

  useEffect(() => {
    // console.log(
    //   "🚀 ~ file: index.js:187 ~ useEffect ~ shopifyHandle:",
    //   shopifyHandle
    // );
    if (!shopifyHandle) {
      props.getProduct(null, stockXHandle, false);
    } else {
      props.getProduct(null, stockXHandle, true);
    }
    props.getShippingAddress();
    props.getPaymentMethods();
    props.getOrders();
    // props.calculateCheckout();
  }, []);

  useEffect(() => {
    if (address && selectedTab && size && props.product.product) {
      const productPrice = getProductPrice(size, props.product.product);
      props.calculateCheckout({
        shippingAddress: {
          name: address && address.name ? address.name : "",
          lastName: address && address.lastName ? address.lastName : "",
          street1: address && address.address ? address.address : "",
          city: address && address.city ? address.city : "",
          state: address && address.province ? address.province : "",
          zip: address && address.postal ? address.postal : "",
          country: address && address.country ? address.country : "",
        },
        item: {
          price: shopifyHandle ? selectedVariant.price : productPrice.price,
        },
        type: selectedTab === "Buy Now" ? "order" : "bid",
        discount,
      });
    }
  }, [address, props.product.product]);

  useEffect(() => {
    if (selectedVariant) {
      setIsShopifyOrder(true);
    }
  }, [selectedVariant]);

  const onDiscountClick = () => {
    setDiscount(true);
  };

  const sortOrdersByDate = (orders) => {
    return orders.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    })
  };

  const sortOrdersById = (orders) => {
    return orders.sort((a, b) => {
      return b.id - a.id;
      //return new Date(b.createdAt) - new Date(a.createdAt);
    })
  };

  const onDiscountCancel = () => {
    setDiscountCode("");
    setDiscount(false);
  };

  const applyDiscount = async () => {
    try {
      await calculateCheckout(address, discountCode);
    } catch (e) {}
  };

  const selectCard = (card) => {
    setPayment(card);
    setCurrentForm("checkout");
  };

  const calculateCheckout = async (newAddress = address, discount = "") => {
    try {
      setLoadingDiscount(true);
      await props.calculateCheckout({
        shippingAddress: {
          name: newAddress && newAddress.name ? newAddress.name : "",
          lastName:
            newAddress && newAddress.lastName ? newAddress.lastName : "",
          street1: newAddress && newAddress.address ? newAddress.address : "",
          city: newAddress && newAddress.city ? newAddress.city : "",
          state: newAddress && newAddress.province ? newAddress.province : "",
          zip: newAddress && newAddress.postal ? newAddress.postal : "",
          country: newAddress && newAddress.country ? newAddress.country : "",
        },
        item: {
          price: selectPrice(),
        },
        type: selectedTab === "Buy Now" ? "order" : "bid",
        discount,
      });
      setLoadingDiscount(false);
      setDiscountErrorMessage("");
      setDiscount(false);
    } catch (e) {
      console.log(e);
      if (discount !== "") {
        try {
          await calculateCheckout(address, "");
          setDiscountErrorMessage(`Discount code is not valid or expired.`);
        } catch (e) {}
      } else {
        swal(
          "Error",
          "Please, enter your address to get your taxes calculated.",
          "error"
        );
      }
    }
  };

  const createExpressCheckout = async (newAddress = address) => {
    if (
      newAddress &&
      newAddress.address &&
      newAddress.name &&
      newAddress.lastName &&
      newAddress.city &&
      newAddress.province &&
      newAddress.postal &&
      newAddress.country
    ) {
      try {
        return await props.createExpressCheckout({
          shippingAddress: {
            name: newAddress && newAddress.name ? newAddress.name : "",
            lastName:
              newAddress && newAddress.lastName ? newAddress.lastName : "",
            street1: newAddress && newAddress.address ? newAddress.address : "",
            city: newAddress && newAddress.city ? newAddress.city : "",
            state: newAddress && newAddress.province ? newAddress.province : "",
            zip: newAddress && newAddress.postal ? newAddress.postal : "",
            country: newAddress && newAddress.country ? newAddress.country : "",
          },
          item: {
            price: selectPrice(),
          },
          type: selectedTab === "Buy Now" ? "order" : "bid",
          listingId: id,
        });
      } catch (e) {
        if (newAddress && newAddress.address !== "") {
          alert(`${String(e)} - double check your shipping address`);
        }
      }
    } else {
      alert("Please enter a shipping address");
    }
  };

  useEffect(() => {
    //auto apply card
    if (!payment && paymentMethods && paymentMethods.length > 0) {
      selectCard(paymentMethods[0]);
    }
    //auto apply shipping
    if (!address && shippingAddress && shippingAddress.address) {
      setAddress(shippingAddress);
    }
  }, [shippingAddress, paymentMethods]);

  if (!props.user.isLoggedIn) {
    return <Redirect to="/login" />;
  }
  if (props.product.loadingProduct) {
    return <LoadingScreen />;
  }

  if (!props.product.product) {
    return <ErrorContainer>{Copy.ProductLoadingErrorMessage}</ErrorContainer>;
  }

  const { product } = props.product;

  const { title, images, lowestAsk } = product;
  const { price, id } = shopifyHandle
    ? {
        price: selectedVariant.price,
        id: productId,
      }
    : getProductPrice(size, product);
  const { highestBid } = getHighestBid(size, product);

  const onTabClick = (tab) => {
    if (tab === "Buy Now") {
      if (price !== "N/A" && price !== "" && price) {
        setSelectedTab(tab);
        setErrorMessage("");
      }
    } else {
      setSelectedTab(tab);
    }
  };

  // SELL FEATURE DISABLED
  //const handleBidPrice = (bidPrice) => {
  //     if ((bidPrice <= price) || !price) {
  //         setErrorMessage('');
  //         setBidPrice(bidPrice);
  //         calculateCheckout();
  //     } else {
  //         //bid price is more than the buy now price
  //         setErrorMessage('Bid price must be less than buy now price');
  //     }
  // }
  const selectPrice = () => {
    if (selectedVariant) {
      return selectedVariant.price;
    }
    if (selectedTab === "Buy Now" && price) {
      return price;
    }
    return bidPrice;
  };
  const applyShippingAddress = async (newAddress) => {
    //set address in state
    setAddress(newAddress);
    //hide shipping form
    setCurrentForm("checkout");
    //save new address
    await props.saveShippingAddress(newAddress);
    await props.getShippingAddress();
    await calculateCheckout(newAddress);
  };

  const isCheckoutDataComplete = () => {
    const { calculatingCheckout } = props.order;
    if (address && payment && !calculatingCheckout) {
      return true;
    }
    return false;
  };

  const openPaymentMethod = () => {
    props.getPaymentMethods();
    setCurrentForm("payment");
  };

  const createPaymentMethod = async (card, billingAddress) => {
    await props.createPaymentMethod({ card, billingAddress });
    openPaymentMethod();
  };

  const deletePaymentMethod = async (cardId) => {
    await props.deletePaymentMethod(cardId);
    openPaymentMethod();
  };

  const getBuyerFees = () => {
    const { checkoutData } = props.order;
    return checkoutData;
  };

  const fees = getBuyerFees();

  const findCheckoutContainer = () => {
    const maxRetries = 10;
    const retryInterval = 300; // milliseconds
    const appendDelay = 300; // milliseconds

    let retries = 0;
    let routeDiv = document.querySelector(".route-div");
    let checkoutContainer = document.getElementById("checkout-container");

    const retryIntervalId = setInterval(() => {
      if (routeDiv && checkoutContainer) {
        clearInterval(retryIntervalId);
        appendRouteDiv();
      } else if (retries >= maxRetries) {
        clearInterval(retryIntervalId);
      }

      routeDiv = document.querySelector(".route-div");
      checkoutContainer = document.getElementById("checkout-container");

      retries++;
    }, retryInterval);

    const appendRouteDiv = () => {
      setTimeout(() => {
        checkoutContainer.appendChild(routeDiv);
      }, appendDelay);
    };

    // Try appending once immediately
    if (routeDiv && checkoutContainer) {
      appendRouteDiv();
    }
  };

  window.Route.Protection.on("status_change", function (event) {
    // get the route-protect-widget element
    const element = document.querySelector(
      "#checkout-container > div.route-div > route-protect-widget"
    );
    // if status is false, then the widget is active. Get the data-quote value and set it to the routeProtectionFee state
    if (element.getAttribute("status") === "false") {
      const quote = element.getAttribute("quote");
      const quoteWithoutCurrency = quote.split("$")[1];
      setRouteProtectionFee(Number(quoteWithoutCurrency));
    } else {
      setRouteProtectionFee(0);
    }
  });

  function generateISO8601Date() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
    const offsetSign = offsetMinutes < 0 ? "+" : "-";

    const isoDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(now.getDate()).padStart(2, "0")}T${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
      now.getSeconds()
    ).padStart(2, "0")}${offsetSign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMinutesRemainder).padStart(2, "0")}`;

    return isoDate;
  }

  const getQuoteId = async () => {
    try {
      let bodyData = JSON.stringify({
        merchant_id: "merch_TAkCXhoHGUEH9ByYY4B6",
        cart: {
          covered: {
            currency: "CAD",
            amount: fees.subtotal.toFixed(2),
          },
          cart_items: [
            {
              unit_price: fees.subtotal.toFixed(2),
              quantity: 1,
            },
          ],
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.route.com/v2/quotes",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: "d9219300-7c0a-4cbe-b2b2-fef29d2100a0",
        },
        data: bodyData,
      };
      const { data } = await axios.request(config);
      return data.id;
    } catch (e) {
      throw new Error(e);
    }
  };
  const orderConfirmation = async (data) => {
    swal({
      title: "Confirm your order",
      text: `You're ordering ${title} ${size} for CAD $${getTotalFee()}`,
      icon: "success",
      buttons: true,
    }).then(async (willConfirm) => {
      if (willConfirm) {
        try {
          await props.createOrder(data);
          if (props.order.failedToCreateOrder) {
            throw new Error("Failed to create order");
          }
          setLoadingOrder(false);
          setCurrentForm("confirm");
        } catch (e) {
          swal(
            "Error",
            "You're order could not be created at the moment. Please contact our support team",
            "error"
          );
          setLoadingOrder(false);
        }
      } else {
        swal("Your order has been cancelled!");
        setLoadingOrder(false);
        setCurrentForm("checkout");
      }
    });
  };

  const createOrder = async () => {
    if (payment && address) {
      setLoadingOrder(true);
      try {
        const data = {
          type: "order",
          listingId: isShopifyOrder ? "shopify" : "stockx",
          isShopifyOrder,
          selectedVariant,
          totalShipping: fees.totalShipping,
          totalFees: fees.totalFees,
          totalTaxes: fees.totalTaxes,
          totalSubtotal: fees.priceBeforeDiscount
            ? fees.priceBeforeDiscount
            : fees.subtotal,
          checkoutTotal: getTotalFee(),
          cardId: payment.customerPaymentProfileId,
          shippingAddress: {
            name: address.name || "",
            lastName: address.lastName || "",
            street1: address.address || "",
            street2: `Unit ${address.unitNumber}` || "",
            city: address.city || "",
            state: address.province || "",
            zip: address.postal || "",
            country: address.country || "",
            unit: address.unitNumber || "",
          },
          lowestAsk,
          size,
          stockXProduct: product,
          discount: {
            code: discountCode,
            amount: fees.priceRuleValuePercentage
              ? Math.abs(Number(fees.priceRuleValuePercentage))
              : fees.discountAmount,
            type: fees.priceRuleValuePercentage ? "percentage" : "fixed",
          },
          orderType: "WEB",
        };
        console.log({
          orderData: data,
        });
        const missingFields = [];
        // Remove the declaration of the 'swalMessage' variable
        let swalMessage = "";
        if (address.name === "") {
          missingFields.push("name");
          swalMessage += "name, ";
        }
        if (address.lastName === "") {
          missingFields.push("last name");
          swalMessage += " last name, ";
        }
        if (address.address === "") {
          missingFields.push("address");
          swalMessage += "adress, ";
        }
        if (address.city === "") {
          missingFields.push("city");
          swalMessage += "city, ";
        }
        if (address.province === "") {
          missingFields.push("province");
          swalMessage += "province, ";
        }
        if (address.postal === "") {
          missingFields.push("postal");
          swalMessage += "postal, ";
        }
        if (address.country === "") {
          missingFields.push("country");
          swalMessage += "country, ";
        }
        if (
          !Object.hasOwn(props.order.checkoutData, "total") ||
          missingFields.length > 0
        ) {
          if (missingFields.length === 1) {
            swal(
              `Please double check your shipping address fields, the ${swalMessage} field is missing.`
            );
          } else {
            swal(
              `Please double check your shipping address fields, the ${swalMessage} fields are missing.`
            );
          }
          setCurrentForm("shipping");
        } else {
          await orderConfirmation(data);

          // dispatch a gtm purchase event to track the order revenue on google analytics 4.
          const gtmData = {
            event: "purchase",
            ecommerce: {
              purchase: {
                actionField: {
                  transaction_id:
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15),
                  affiliation: "Shop With Safe",
                  revenue: data.checkoutTotal,
                  tax: data.totalTaxes,
                  shipping: data.totalShipping,
                  coupon: "",
                },
                products: [
                  {
                    name: data.stockXProduct.title,
                    id: data.stockXProduct.id,
                    price: data.stockXProduct.lowestAsk,
                    brand: data.stockXProduct.brand,
                    category: data.stockXProduct.category,
                    variant: data.size,
                    quantity: 1,
                  },
                ],
              },
            },
          };
          window.dataLayer.push(gtmData);
        }
      } catch (e) {
        alert(String(e));
      }
    }
  };

  const createExpressOrder = async (expressCheckoutCharge) => {
    if (address) {
      try {
        const data = {
          type: "order",
          listingId: id,
          totalShipping: fees.totalShipping,
          totalFees: fees.totalFees,
          totalTaxes: fees.totalTaxes,
          totalSubtotal: fees.subtotal,
          checkoutTotal: getTotalFee(),
          shippingAddress: {
            name: address.name || "",
            lastName: address.lastName || "",
            street1: address.address || "",
            city: address.city || "",
            state: address.province || "",
            zip: address.postal || "",
            country: address.country || "",
            unit: address.unitNumber || "",
          },
          lowestAsk,
          size,
          stockXProduct: product,
          isExpressCheckout: true,
          expressCheckoutCharge,
        };
        await props.createOrder(data);
        setCurrentForm("confirm");
      } catch (e) {
        alert(String(e));
      }
    }
  };
  const createBid = async () => {
    if (payment && address && expiresOn && Number(bidPrice) > 0) {
      try {
        const data = {
          size,
          price: Number(bidPrice),
          shipping: fees.totalShipping,
          fees: fees.totalFees,
          totalPrice: getTotalFee(),
          taxes: fees.totalTaxes,
          subtotal: fees.subtotal,
          status: "Active",
          expirationDate: moment().add(Number(expiresOn), "days"),
          productId: product.id,
          cardId: payment.customerPaymentProfileId,
          customerId: payment.customer,
          shippingAddress: {
            name: address.name || "",
            lastName: address.lastName || "",
            street1: address.address || "",
            city: address.city || "",
            state: address.province || "",
            zip: address.postal || "",
            country: address.country || "",
            unit: address.unitNumber || "",
          },
        };
        await props.createBid(data);
        setCurrentForm("confirm");
      } catch (e) {
        alert(e);
      }
    }
  };

  const onNext = () => {
    setShowCheckoutForm(true);
    findCheckoutContainer();
  };

  if (props.order.creatingOrder || props.bid.creatingBid) {
    <LoadingScreen />;
  }

  const verifyShippingAddress = () => {
    if (Object.keys(props.shippingAddress.shippingAddress).length !== 0) {
      createOrder();
    } else {
      alert("Please enter your shipping address details.");
    }
  };

  const getTotalFee = () => {
    if (routeProtectionFee > 0) {
      // if route protection is enabled, add the route protection fee to the total fees. Return must be a number with 2 decimal places.
      return (fees.total + routeProtectionFee).toFixed(2);
    } else {
      return fees.total;
    }
  };

  const handleAffirmCheckout = async (checkoutToken, token) => {
    setLoadingOrder(true);
    const response = await axios.post(
      // todo: change this to the production url when we go live.
      // "http://localhost:5000/api/affirm",
      `${process.env.REACT_APP_API}/api/affirm`,
      {
        checkoutToken,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // if response.data.succsess === true then create order on shopify. Need to figure out how to create the order on shopify and consider the order paid. Add a tag to the order to indicate that it was paid with affirm.
    if (response.data.success === true) {
      const data = {
        type: "order",
        listingId: isShopifyOrder ? "shopify" : "stockx",
        isShopifyOrder,
        selectedVariant,
        totalShipping: fees.totalShipping,
        totalFees: fees.totalFees,
        totalTaxes: fees.totalTaxes,
        totalSubtotal: fees.priceBeforeDiscount
          ? fees.priceBeforeDiscount
          : fees.subtotal,
        checkoutTotal: fees.total,
        cardId: payment.customerPaymentProfileId,
        shippingAddress: {
          name: address.name || "",
          lastName: address.lastName || "",
          street1: address.address || "",
          city: address.city || "",
          state: address.province || "",
          zip: address.postal || "",
          country: address.country || "",
          unit: address.unitNumber || "",
        },
        lowestAsk,
        size,
        stockXProduct: product,
        discount: {
          code: discountCode,
          amount: fees.priceRuleValuePercentage
            ? Math.abs(Number(fees.priceRuleValuePercentage))
            : fees.discountAmount,
          type: fees.priceRuleValuePercentage ? "percentage" : "fixed",
        },
        orderType: "WEB",
        isAffirm: true,
      };
      console.log({
        orderData: data,
      });
      await props.createOrder(data);
      setCurrentForm("confirm");
      setLoadingOrder(false);
    } else {
      alert("There was an error processing your order. Please try again.");
      setLoadingOrder(false);
    }
  };

  const affirmCheckoutOptions = {
    customer: {
      shipping: shippingAddress,
      email: email,
    },
    sku: product.sku || selectedVariant.sku,
    ...product,
    ...fees,
    price: price,
    onSuccess: (payload) => {
      handleAffirmCheckout(payload.checkoutToken, props.user.token);
    },
    setShowCheckoutForm,
    discount: fees.discountAmount,
    discountCode,
    setIsAffirm,
  };

  const shouldAffirmLoad = () => {
    return (
      shippingAddress &&
      Object.keys(shippingAddress).length !== 0 &&
      product &&
      Object.keys(product).length !== 0 &&
      fees &&
      typeof fees.total === "number"
    );
  };

  const confirmAffirmAddress = () => {
    const missingFields = [];
    // Remove the declaration of the 'swalMessage' variable
    let swalMessage = "";
    if (address.name === "") {
      missingFields.push("name");
      swalMessage += "name, ";
    }
    if (address.lastName === "") {
      missingFields.push("last name");
      swalMessage += " last name, ";
    }
    if (address.address === "") {
      missingFields.push("address");
      swalMessage += "adress, ";
    }
    if (address.city === "") {
      missingFields.push("city");
      swalMessage += "city, ";
    }
    if (address.province === "") {
      missingFields.push("province");
      swalMessage += "province, ";
    }
    if (address.postal === "") {
      missingFields.push("postal");
      swalMessage += "postal, ";
    }
    if (address.country === "") {
      missingFields.push("country");
      swalMessage += "country, ";
    }

    if (
      !Object.hasOwn(props.order.checkoutData, "total") ||
      missingFields.length > 0
    ) {
      if (missingFields.length === 1) {
        swal(
          `Please double check your shipping address fields, the ${swalMessage} field is missing.`
        );
      } else {
        swal(
          `Please double check your shipping address fields, the ${swalMessage} fields are missing.`
        );
      }
      setCurrentForm("shipping");
    }
  };

  const createCheckoutAfterpay = async (token) => {
    setLoadingOrder(true);
    const today = new Date();
    const futureDate = new Date(today);
    const daysToAdd = selectedVariant ? 3 : 10;
    futureDate.setDate(today.getDate() + daysToAdd);

    const estimatedDate = futureDate.toISOString().split("T")[0];
    const cleanPhoneNumber = "+1" + address.phone.replace(/\D/g, "");

    const missingFields = [];
    // Remove the declaration of the 'swalMessage' variable
    let swalMessage = "";
    if (address.name === "") {
      missingFields.push("name");
      swalMessage += "name, ";
    }
    if (address.lastName === "") {
      missingFields.push("last name");
      swalMessage += " last name, ";
    }
    if (address.address === "") {
      missingFields.push("address");
      swalMessage += "adress, ";
    }
    if (address.city === "") {
      missingFields.push("city");
      swalMessage += "city, ";
    }
    if (address.province === "") {
      missingFields.push("province");
      swalMessage += "province, ";
    }
    if (address.postal === "") {
      missingFields.push("postal");
      swalMessage += "postal, ";
    }
    if (address.country === "") {
      missingFields.push("country");
      swalMessage += "country, ";
    }

    if (
      !Object.hasOwn(props.order.checkoutData, "total") ||
      missingFields.length > 0
    ) {
      if (missingFields.length === 1) {
        swal(
          `Please double check your shipping address fields, the ${swalMessage} field is missing.`
        );
      } else {
        swal(
          `Please double check your shipping address fields, the ${swalMessage} fields are missing.`
        );
      }
      setCurrentForm("shipping");
    } else {
      try {
        props.getOrders();
        const orders = sortOrdersByDate(props.order.orders);
        const idOrdered = sortOrdersById(orders);
        const merchantReference = idOrdered[0].id+1;
        // console.log("MERCHANTREF", merchantReference);
        // console.log("MERCHANTREFID", idOrdered);
        const data = {
          amount: {
            amount: `${getTotalFee()}`,
            currency: "CAD",
          },
          consumer: {
            email: email.email,
            givenNames: address.name,
            surname: address.lastName,
            phoneNumber: cleanPhoneNumber,
          },
          merchantReference: merchantReference,//`safe-merchant-order-${Math.random().toString(36).substring(2, 15)}`,
          billing: {
            name: address.name,
            line1: address.address || "",
            line2: "",
            area1: address.city || "",
            region: address.province || "",
            postcode: address.postal || "",
            countryCode: "CA",
            phoneNumber: cleanPhoneNumber,
          },
          shipping: {
            name: address.name,
            line1: address.address || "",
            line2: "",
            area1: address.city || "",
            region: address.province || "",
            postcode: address.postal || "",
            countryCode: "CA",
          },
          merchant: {
            redirectConfirmUrl: "https://limits-web-git-afterpay-setup-unit-203.vercel.app/Confirm", //https://shopwithsafe.com
            redirectCancelUrl: "https://limits-web-git-afterpay-setup-unit-203.vercel.app/Confirm",
            popupOriginUrl: window.location.href,
            name: "SHOP WITH SAFE",
          },
          items: [
            {
              name: product.title,
              sku: product.sku || selectedVariant.sku,
              pageUrl: `https://shopwithsafe.com/product/${stockXHandle}`,
              imageUrl: `${product.images[0]}`,
              quantity: 1,
              price: {
                amount: `${getTotalFee()}`,
                currency: "CAD",
              },
              categories: [],
              estimatedShipmentDate: estimatedDate,
              preorder: false,
            },
          ],
        };
        console.log("DATA",data);
        await axios
          .post(
            // todo: change this to the production url when we go live.
            // "http://localhost:5000/api/afterpayCheckout",https://shop-limits-server-staging.herokuapp.com
            `${process.env.REACT_APP_API}/api/afterpayCheckout`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (response) => {
            const orderData = {
              type: "order",
              listingId: isShopifyOrder ? "shopify" : "stockx",
              isShopifyOrder,
              selectedVariant,
              totalShipping: fees.totalShipping,
              totalFees: fees.totalFees,
              totalTaxes: fees.totalTaxes,
              totalSubtotal: fees.priceBeforeDiscount
                ? fees.priceBeforeDiscount
                : fees.subtotal,
              checkoutTotal: getTotalFee(),
              cardId: payment.customerPaymentProfileId,
              shippingAddress: {
                name: address.name || "",
                lastName: address.lastName || "",
                street1: address.address || "",
                street2: `Unit ${address.unitNumber}` || "",
                city: address.city || "",
                state: address.province || "",
                zip: address.postal || "",
                country: address.country || "",
                unit: address.unitNumber || "",
              },
              lowestAsk,
              size,
              stockXProduct: product,
              discount: {
                code: discountCode,
                amount: fees.priceRuleValuePercentage
                  ? Math.abs(Number(fees.priceRuleValuePercentage))
                  : fees.discountAmount,
                type: fees.priceRuleValuePercentage ? "percentage" : "fixed",
              },
              orderType: "WEB",
              isAfterpay: true,
            };

            const gtmData = {
              event: "purchase",
              ecommerce: {
                purchase: {
                  actionField: {
                    transaction_id:
                      Math.random().toString(36).substring(2, 15) +
                      Math.random().toString(36).substring(2, 15),
                    affiliation: "Shop With Safe",
                    revenue: orderData.checkoutTotal,
                    tax: orderData.totalTaxes,
                    shipping: orderData.totalShipping,
                    coupon: "",
                  },
                  products: [
                    {
                      name: orderData.stockXProduct.title,
                      id: orderData.stockXProduct.id,
                      price: orderData.stockXProduct.lowestAsk,
                      brand: orderData.stockXProduct.brand,
                      category: orderData.stockXProduct.category,
                      variant: orderData.size,
                      quantity: 1,
                    },
                  ],
                },
              },
            };

            localStorage.setItem("orderData", JSON.stringify(orderData));
            localStorage.setItem("gtmData", JSON.stringify(gtmData));
            localStorage.setItem("orderCreated", "false");
            const redirectionLink = response.data.redirectCheckoutUrl;
            window.open(redirectionLink, "_blank");
            swal(
              `A contextual Afterpay window or tab will open to complete your purchase. please wait or inspect your browser bar.`
            );
          });
      } catch (e) {
        alert("Something went wrong, try again later!");
      }
    }
  };

  const isObjectNotEmpty = (obj) => {
    if(obj === null || obj === undefined) return false;
    const values = Object.values(obj);
    return values.every(value => value !== null && value !== undefined && value !== '');
  }

  return (
    <Container>
      <CheckoutProduct
        title={title}
        images={images}
        highestBid={highestBid}
        lowestAsk={lowestAsk}
        size={size}
        sizeType="label"
      />

      {currentForm === "checkout" ? (
        <>
          <CheckoutFormContainer>
            <CheckoutTab
              //SELL FEATURE DISABLE - removed Place Bid from tabs
              tabs={["Buy Now"]}
              onTabClick={(tab) => onTabClick(tab)}
              selectedTab={selectedTab}
            />
            <InfoContainer>
              <PriceInput
                //SELL FEATURE DISABLE - disable on change
                //  onChange={(e) => handleBidPrice(e.target.value)}
                value={selectPrice()}
                error={errorMessage}
                placeholder={Copy.BuyInputPlaceholder}
                type="number"
              />
              <CheckoutHeader>{Copy.BuyPriceHeader}</CheckoutHeader>
              {selectedTab === "Place Bid" && (
                <FeeLine label={Copy.BuyProccessingFee} fee={fees.totalFees} />
              )}
              {fees.discountAmount &&
                (fees.priceRuleValuePercentage ? (
                  <FeeLine
                    label={`${
                      Copy.Discount
                    } (${-fees.priceRuleValuePercentage}%)`}
                    fee={Number(-fees.discountAmount)}
                  />
                ) : (
                  <FeeLine
                    label={`${
                      Copy.Discount
                    } ($${-fees.priceRuleValueFixedAmount})`}
                    fee={Number(-fees.discountAmount)}
                  />
                ))}
              <FeeLine
                label={
                  selectedVariant
                    ? Copy.BuyShippingFeeFast
                    : Copy.BuyShippingFee
                }
                fee={fees.totalShipping}
              />
              <FeeLine label={Copy.BuyTaxesFee} fee={fees.totalTaxes} />
              <FeeLine label={Copy.BuyProtectionFee} fee={routeProtectionFee} />
              <Seperator />
              <FeeLine label={Copy.BuyTotal} fee={getTotalFee()} bold />
              {!showCheckoutForm &&
              payment &&
              paymentMethods.length > 0 &&
              address &&
              shippingAddress &&
              isObjectNotEmpty(address) === true &&
                    isObjectNotEmpty(shippingAddress) === true ? (
                <>
                  <CheckoutHeader>Select Payment Method:</CheckoutHeader>
                  <CheckoutButtonContainer>
                  <div
                      style={{
                        display: "flex",
                        flex: "column",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        onChange={() =>
                          createCheckoutAfterpay(props.user.token)
                        }
                      />
                      <div style={{ display: "flex",flexDirection:"row",gap:5,justifyContent:"center",alignItems:"center" }}>
                        <CheckLabel large={true}>{`4 payments of $${(getTotalFee()/4).toFixed(2)} With`}</CheckLabel>
                        {/* <Logo src={afterPayImage} alt="Logo" /> */}
                        <a href="https://www.afterpay.com/en-CA/how-it-works" target="_blank" rel="noreferrer"><Logo src={afterPayImage} alt="Logo" /></a>
                      </div>
                    </div>
                    <Seperator />

                    <Checkbox
                      onChange={() => onNext()}
                      disabled={!selectPrice() || selectPrice() === ""}
                      large={false}
                      label="Pay With Credit Card"
                    />
                    <Seperator />

                    {shouldAffirmLoad() && fees.total > 50 ? (
                      <div
                        style={{
                          display: "flex",
                          flex: "column",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          onChange={() =>
                            createCheckoutAffirm(affirmCheckoutOptions)
                          }
                        />
                        <AffirmLabel />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: "column",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox onChange={() => confirmAffirmAddress()} />
                        <AffirmLabel />
                      </div>
                    )}
                    <Seperator />

                  </CheckoutButtonContainer>
                </>
              ) : (
                < div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  {(paymentMethods.length === 0 || payment === null) && (
                    <EditButton
                      onClick={() => setCurrentForm("addPayment")}
                    >
                      Edit Payment
                    </EditButton>
                  )}
                  {(isObjectNotEmpty(address) === false ||
                    isObjectNotEmpty(shippingAddress) === false) && (
                      <EditButton
                        onClick={() => setCurrentForm("shipping")}
                      >
                        Edit Shipping Address
                      </EditButton>
                    )}
                </div>
              )}
            </InfoContainer>
            {showCheckoutForm ? (
              <>
                <CheckoutForm
                  address={address}
                  payment={payment}
                  discount={discount}
                  discountCode={discountCode}
                  setDiscountCode={setDiscountCode}
                  discountErrorMessage={discountErrorMessage}
                  loadingDiscount={loadingDiscount}
                  applyDiscount={applyDiscount}
                  expiresOn={selectedTab === "Place Bid" ? expiresOn : null}
                  onAddressClick={() => setCurrentForm("shipping")}
                  onPaymentClick={() => openPaymentMethod()}
                  onDiscountClick={() => onDiscountClick()}
                  onDiscountCancel={() => onDiscountCancel()}
                  onExpiryChange={(days) => setExpiresOn(days)}
                  isAffirm={isAffirm}
                />
                {fees.total > 0 && <RouteWidget fees={fees} />}
              </>
            ) : null}
          </CheckoutFormContainer>
          {showCheckoutForm && (
            <CheckoutButtonBar>
              <BackButton onClick={() => setShowCheckoutForm(false)}>
                Back
              </BackButton>
              {selectedTab === "Buy Now" &&
                (loadingOrder ? (
                  <CheckoutButton loading={props.order.creatingOrder}>
                    <Spinner animation="border" variant="primary" />
                  </CheckoutButton>
                ) : (
                  <CheckoutButton
                    onClick={() => verifyShippingAddress()}
                    disabled={!isCheckoutDataComplete()}
                    loading={props.order.creatingOrder}
                  >
                    Place Order
                  </CheckoutButton>
                ))}
            </CheckoutButtonBar>
          )}
        </>
      ) : null}

      {currentForm === "payment" ? (
        <PaymentForm
          onBack={() => setCurrentForm("checkout")}
          paymentMethods={paymentMethods}
          loadingPaymentMethods={loadingPaymentMethods}
          goToAddPaymentForm={() => setCurrentForm("addPayment")}
          deletePaymentMethod={(cardId) => deletePaymentMethod(cardId)}
          onSelect={(card) => selectCard(card)}
          showExpressCheckout={selectedTab === "Buy Now"}
          fees={fees}
          productTitle={title}
          size={size}
          createExpressCheckout={() => createExpressCheckout()}
          createExpressOrder={(data) => createExpressOrder(data)}
        />
      ) : null}
      {currentForm === "addPayment" ? (
        <AddPaymentForm
          onBack={() => openPaymentMethod()}
          onCreate={(card, billingAddress) =>
            createPaymentMethod(card, billingAddress)
          }
          creatingPaymentMethod={creatingPaymentMethod}
        />
      ) : null}
      {currentForm === "shipping" ? (
        <ShippingForm
          onBack={() => setCurrentForm("checkout")}
          addressData={shippingAddress}
          onApply={(newAddress) => applyShippingAddress(newAddress)}
        />
      ) : null}

      {currentForm === "confirm" ? (
        <ConfirmScreen
          title={
            selectedTab === "Buy Now"
              ? Copy.BuySuccessTitle
              : Copy.BidSuccessTitle
          }
          subTitle={
            selectedTab === "Buy Now"
              ? Copy.BuySuccessSubtitle
              : Copy.BidSuccessSubtitle
          }
          buttonText={
            selectedTab === "Buy Now" ? Copy.BuyButtonText : Copy.BidButtonText
          }
        />
      ) : null}
    </Container>
  );
};

export default connectScreen(Buy);
