import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import connectScreen from "../../redux/connectScreen";
import ProductList from "../../components/ProductList";
import Input from "../../components/Input";
import SizeGrid from "../../components/SizeGrid";
import close from "../..//assets/images/close.png";
import searchIcon from "../../assets/images/search_image.svg";

import Copy from "../../constants/Copy";
import FilterOptions from "../../constants/FilterOptions";
import Checkbox from "../../components/Checkbox";
import LoadingScreen from "../../components/LoadingScreen";
import handleMetaTags from "../../helpers/handleMetaTags";
import {
  Container,
  CloseButton,
  ApplyButton,
  ClearFilterButton,
  FilterButtons,
  FiltersContainer,
  SearchContainer,
  FilterButton,
  ProductSearchContainer,
  ProductsContainer,
  CheckboxContainer,
  FilterContainer,
  FilterTitle,
  StyledSlider,
  StyledThumb,
  StyledTrack,
} from "./styles";

const Thumb = (props, state) => (
  <StyledThumb {...props}>${state.valueNow}</StyledThumb>
);

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const Browse = (props) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [gender, setGender] = useState("");
  const [price, setPrice] = useState([]);
  const [brand, setBrand] = useState("");
  const [size, setSize] = useState("");
  const [search, setSearch] = useState(params.search || "");
  const [preBrand, setPreBrand] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApparel, setIsApparel] = useState(false);
  //const [page, setPage] = useState(0);
  const page = 0;

  useEffect(() => {
    const brandFromPathName = location.pathname.split("/")[2];
    if (brandFromPathName) {
      const preSetBrandFilter = brandFromPathName.substring((brandFromPathName.lastIndexOf(' ')) + 1);
      if (preSetBrandFilter === "Nike" || preSetBrandFilter === "Adidas" || preSetBrandFilter === "Jordan") {
        setBrand(preSetBrandFilter);
        setPreBrand(preSetBrandFilter);
      }
      if (brandFromPathName === "Apparel") {
        setIsApparel(true);
        props.getCollectionProductsByHandle('clothing', 50);
      }
    }
  }, []);

  useEffect(() => {
    // set loading according to the state of the products
    if (isApparel) {
      setIsLoading(props.product.loadingProductsSections);
    } else {
      setIsLoading(props.product.loadingProducts);
    }
  }, [props.product.loadingProducts, props.product.loadingProductsSections])


  useEffect(() => {
    if (!showMobileFilters && brand !== undefined && gender !== undefined && preBrand === null) {
      searchProducts();
    }
  }, [price, showMobileFilters, preBrand]);

  useEffect(() => {
    if (!showMobileFilters && brand !== undefined && brand !== '') {
      searchOnPreSelectedBrand();
    }
  }, [brand]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== undefined && search !== "") {
        searchProducts();
      }
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const searchOnPreSelectedBrand = () => {
    searchProducts();
    setShowMobileFilters(false);
  };

  const clearFilters = () => {
    setPrice([]);
    setGender("");
    setBrand("");
    setSize("");
    //hide mobile filter
    setShowMobileFilters(false);
  };

  const searchProducts = (newSearchText) => {
    //new search text to refresh, since set state is not synchrounous

    const data = {
      query: newSearchText || search,
      brand,
      size,
      gender,
      page,
    };
    //need to check before adding price filtre
    price[0] && (data.priceRangeLow = price[0]); // 1
    price[1] && (data.priceRangeHigh = price[1]); // 1
    if (location.pathname.includes("Apparel")) {
      props.getCollectionProductsByHandle('clothing', 50);
    } else {
      props.getProducts(data);
    }
  };


  const CheckboxList = ({
    options = [],
    value, //the currently sellected value
    onChange,
  }) => (
    <CheckboxContainer>
      {options.map((option) => (
        <Checkbox
          onChange={onChange}
          label={option}
          selected={value === option}
        />
      ))}
    </CheckboxContainer>
  );

  const renderFilterComponent = (title, type, options, value, onChange) => {
    switch (type) {
      case "checkbox":
        return (
          <CheckboxList
            title={title}
            type={type}
            options={options}
            value={value}
            onChange={onChange}
          />
        );
      case "scroller":
        return (
          <StyledSlider
            defaultValue={[0, 10000]}
            min={0}
            max={10000}
            step={100}
            value={price}
            onAfterChange={(values) => setPrice(values)}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            pearling
            renderTrack={Track}
            renderThumb={Thumb}
          />
        );
      case "sizes":
        return (
          <SizeGrid
            sizes={options}
            selectedSize={value}
            onSizeClick={onChange}
          />
        );
      default:
        return (
          <SizeGrid
            sizes={options}
            selectedSize={value}
            onSizeClick={onChange}
          />
        );
    }
  };

  const Filter = ({
    title,
    type, // type of filter
    options, //list of options
    value, // selected value for the filter
    onChange,
  }) => (
    <FilterContainer>
      <FilterTitle>{title}</FilterTitle>
      {renderFilterComponent(title, type, options, value, onChange)}
    </FilterContainer>
  );

  const Filters = () => (
    <FiltersContainer>
      {isMobile ? (
        <CloseButton src={close} onClick={() => setShowMobileFilters(false)} />
      ) : null}

      <Filter
        title="Price"
        type="scroller"
        options={FilterOptions.gender}
        value={price}
        onChange={(priceFilter) => setPrice(priceFilter)}
      />
      <Filter
        title="Brands"
        type="checkbox"
        options={FilterOptions.brands}
        value={brand}
        onChange={(brandsFilter) => setBrand(brandsFilter)}
      />
      <Filter
        title="Size Type"
        type="checkbox"
        options={FilterOptions.gender}
        value={gender}
        onChange={(size) => setGender(size)}
      />

      <Filter
        title="Size"
        type="sizes"
        options={FilterOptions[`${gender}Sizes`]}
        value={size}
        onChange={(sizeFilter) => setSize(sizeFilter)}
      />
      <FilterButtons>
        <ApplyButton
          onClick={() => {
            searchProducts();
            //hide mobile filter
            setShowMobileFilters(false);
          }}
          disabled={
            brand === undefined && size === "" && gender === "" ? true : false
          }
          handleOpacity={
            brand === undefined && size === "" && gender === "" ? true : false
          }
        >
          Apply
        </ApplyButton>
        <ClearFilterButton onClick={() => clearFilters()}>
          Clear Filters
        </ClearFilterButton>
      </FilterButtons>
    </FiltersContainer>
  );
  const Search = () => (
    <SearchContainer>
      <Input
        icon={searchIcon}
        placeholder="Search by title, sku, brand.."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {isMobile ? (
        <FilterButton onClick={() => setShowMobileFilters(true)}>
          {Copy.filterButton}
        </FilterButton>
      ) : null}
    </SearchContainer>
  );
  return (
    <>
      {handleMetaTags(brand)}
      <Container>
        {isMobile ? showMobileFilters ? <Filters /> : null : <Filters />}

        <ProductSearchContainer>
          {Search()}
          {isLoading ? (
            <ProductsContainer>
              <LoadingScreen />
            </ProductsContainer>
          ) : (
            <ProductsContainer>
              <ProductList products={isApparel ? props.product.collectionProducts : props.product.products || []} />
            </ProductsContainer>
          )}
        </ProductSearchContainer>
      </Container>
    </>
  );
};

export default connectScreen(Browse);
