import {
    GET_SHIPPING_ADDRESS,
    SAVE_SHIPPING_ADDRESS
} from '../actions/types';
const defaultState = {
    loadingShippingAddress: false,
    shippingAddress: null,
    savingShippingAddress: false
}
const ShippingAddressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_SHIPPING_ADDRESS:
            return {
                ...state,
                loadingShippingAddress: action.loadingShippingAddress,
                shippingAddress: action.shippingAddress
            }
        case SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                savingShippingAddress: action.savingShippingAddress
            }
        default:
            return state;
    }
}

export default ShippingAddressReducer;