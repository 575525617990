import Colors from "../constants/Colors";
import Fonts from "../constants/Fonts";
import afterPayImage from "../assets/images/afterpay-badge-blackonmint72x15@3x.png";

const styled = require("styled-components").default;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${Colors.lightGrey};
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const SectionTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const SectionContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const SectionLabel = styled.p`
  font-family: ${Fonts.PRIMARY};
  margin-right: 3px;
  margin-bottom: 5px;
`;

const SectionText = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${(props) => props.color || Colors.midGrey};
  margin: 0px;
`;

export const Logo = styled.img`
  height: 30px; /* Adjust the height as needed */
  
`;


const SectionDetails = ({ order, type }) => {
  console.log("🚀 ~ file: SectionDetails.js ~ line 65 ~ SectionDetails ~ order", order)
  return type === "Price Details" ? (
    <SectionContainer>
      <SectionTitle>{type}</SectionTitle>
      <Container>
        <InnerContainer>
          <SectionContainerColumn>
            <SectionRow>
              <SectionLabel>Price:</SectionLabel>
              <SectionText color={Colors.midGrey}>
                {order && order.price
                  ? `CAD ${Number(order.price).toFixed(2)}`
                  : "N/A"}
              </SectionText>
            </SectionRow>
            <SectionRow>
              <SectionLabel>Taxes:</SectionLabel>
              <SectionText color={Colors.midGrey}>
                {order && order.taxes
                  ? `CAD ${Number(order.taxes).toFixed(2)}`
                  : "N/A"}
              </SectionText>
            </SectionRow>
            <SectionRow>
              <SectionLabel>Total Price:</SectionLabel>
              <SectionText color={Colors.midGrey}>
                {order && order.totalPrice
                  ? `CAD ${Number(order.totalPrice).toFixed(2)}`
                  : "N/A"}
              </SectionText>
            </SectionRow>
          </SectionContainerColumn>
        </InnerContainer>
      </Container>
    </SectionContainer>
  ) : (
    <SectionContainer>
      <SectionTitle>{type}</SectionTitle>
      <Container>
        <InnerContainer>
          <SectionContainerColumn>
            {order.chargeId === "afterpay" ? 
             <SectionRow>
             <SectionLabel>Paid with</SectionLabel>
             <SectionText color={Colors.midGrey}>
              <a href="https://www.afterpay.com/en-CA/how-it-works" target="_blank" rel="noreferrer"><Logo src={afterPayImage} alt="Logo" /></a>
             </SectionText>
           </SectionRow>
            : (
              <SectionRow>
              <SectionLabel>Paid with Credit Card</SectionLabel>
              <SectionText color={Colors.midGrey}>
                {order?.billingAddress?.payment?.creditCard?.cardNumber}
              </SectionText>
            </SectionRow>
            )}
           
          </SectionContainerColumn>
        </InnerContainer>
      </Container>
    </SectionContainer>
  );
};


export default SectionDetails;