
 const Colors = {
    white: '#fff',
    browseBackground:'#ECEDEF',
    lightBlue: '#00BCD4',
    black: '#000000',
    yellow: '#bbbbbb',
    blue: '#000000',
    greyBackground: '#F5F5F5',
    grey: '#94A1B0',
    lightGrey: '#E5E6E8',
    midGrey:'#8F919B',
    greyishBlue: '#282C40',
    darkGrey: '#272838',
    greyLight: '#B8B9BF',
    red: 'red',
    primaryGrey: '#D0D0D0',
    green: '#65906A',
    lightBackground: '#f2f3f4',
    inputLabel: '#C6C6C6',
    inputTextColor: '#696C79',
    lightWhite: '#5C6F8E',
    blueBack: '#F7F7F7',
    detailColor: '#ACADB5',
    backHideStart: 'rgba(252,252,252,0.0)',
    backHideEnd: 'rgba(252,252,252,0.7)',
    placeBidInput: '#F7F8F8',
    modalBackground: 'rgba(0,0,0,0.4)',
    couponBackground: '#EAF0FB',
    couponBorder: '#5780D9',
    yellowHome: '#F4C000'
};

export default Colors;