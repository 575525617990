import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';

const Tab = styled.a`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    font-size: ${props => props.selectedTab === props.tab ? '14px' : '16px'};
    font-family: ${props => props.selectedTab === props.tab ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY};
    color: ${props => props.fontColor ? props.fontColor : Colors.white};
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    @media (max-width: 780px) {
        width: 100%;
    }
    :hover {
     text-decoration:none; 
     color: ${props => props.fontColor ? props.fontColor : Colors.white};

    }

`

const TabUnderline = styled.div`
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 100%;
    background-color: ${Colors.yellow};
`

const TabComponent = (props) => {
    const {
        selectedTab,
        onTabClicked,
        tab,
        fontColor
    } = props;
    return (
        <Tab
            selectedTab={selectedTab}
            tab={tab}
            onClick={() => onTabClicked(tab)}
            fontColor={fontColor}
        >
            {tab}
            {selectedTab === tab && <TabUnderline />}
        </Tab>

    )
}

export default TabComponent;