import axios from 'axios';
import {
    API_HEADER,
    ORDERS_API
} from '../../../constants/API';
import {
    GET_ORDER
} from '../types';


const getOrder = (orderId) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_ORDER,
                loadingOrders: true,
                orders: []
            });
            const { token } = getState().UserReducer;
            const orderResponse = await axios.get(`${ORDERS_API}/${orderId}`, API_HEADER(token));
            dispatch({
                type: GET_ORDER,
                loadingOrder: false,
                order: orderResponse.data || []
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_ORDER,
                loadingOrder: false,
                order: false
            })
        }
    }
}

export { getOrder };