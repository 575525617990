import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 780px) {
        margin: 0;
        width: 100%;
    }
`


const OuterTabs = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    )
}

export default OuterTabs;