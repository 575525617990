import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    min-height: 30px;
    border: none;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
`
const Input = styled.input`
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-family: Futura;
    ::placeholder {
        font-family: Futura;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #94a1b0;
        opacity: 0.59;
    }
    @media (max-width: 780px) {
        font-size: 16px;
    }
`

const Icon = styled.img`
    width: 12px;
    height: 12px;
    margin-right: 5px;
`


const InputComponent = (props) => {
    const {
        icon,
        placeholder,
        onChange,
        onKeyDown,
        value
    } = props;
    return (
        <Container>
            <Icon
                src={icon} 
            />
            <Input 
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
            />
        </Container>
    )
}

export default InputComponent;