import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';
import Fonts from '../constants/Fonts';
import Check from '../assets/images/tick.svg'

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    cursor:pointer
`

const CheckContainer = styled.div`
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:2px solid ${Colors.darkGrey};
    border-radius: 2px;
`

const Icon = styled.img`
    width: 10px;
    height: 10px;
`

const CheckLabel = styled.div`
  font-family: ${(props) => (props.large ? Fonts.PRIMARY_BOLD : Fonts.PRIMARY_BOLD)};
  font-size: ${(props) => (props.large ? '16px' : '14px')};
  color: ${Colors.darkGrey};
  margin-left: 10px;
  text-transform: capitalize;
`;

const Checkbox = (props) => {
    const {
        label,
        selected,
        onChange,
        large = false,
    } = props;
    return (
        <Container onClick={() => onChange(label)}>
            <CheckContainer>
                {selected && <Icon src={Check} />}
            </CheckContainer>
            <CheckLabel large={large}>{label}</CheckLabel>
        </Container>
    )
}

export default Checkbox;