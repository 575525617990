const getHighestBid = (size, product) => {
    if (
        product &&
        product.Bids &&
        Array.isArray(product.Bids) &&
        product.Bids.length > 0
    ) {
        const bidsForSize = product.Bids.filter(bid => { return bid.size === size })
        if (
            bidsForSize &&
            Array.isArray(bidsForSize) &&
            bidsForSize.length > 0
        ) {
            const lowestPricedListing = bidsForSize.reduce((prev, curr) => Number(prev.price) > Number(curr.price) ? prev : curr);
            if (
                lowestPricedListing &&
                lowestPricedListing.price
            ) {
                return { label: `${lowestPricedListing.price}`, value: lowestPricedListing.price, bidId: lowestPricedListing.id };
            }
        }
    }
    return { label: 'N/A', value: 'N/A' }
}

const getHighestBidData = (size, product) => {
    const { bidId, label } = getHighestBid(size, product)
    return {
        highestBid: label,
        highestBidId: bidId,
    }
}

export default getHighestBidData;