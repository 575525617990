import axios from 'axios';
import {
    API_HEADER,
    ORDERS_API
} from '../../../constants/API';
import {
    CALCULATE_CHECKOUT
} from '../types';


const calculateCheckout = (data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CALCULATE_CHECKOUT,
                calculatingCheckout: true,
                checkoutData: {
                    totalShipping: 'TBD',
                    totalTaxes: 'TBD',
                    totalFees: 'TBD',
                    subtotal: 'TBD',
                    total: 'TBD'
                }
            });
            const { token } = getState().UserReducer;
            const calculateCheckoutResponse = await axios.post(`${ORDERS_API}/calculate`, data,  API_HEADER(token));
            console.log(calculateCheckoutResponse)
            dispatch({
                type: CALCULATE_CHECKOUT,
                calculatingCheckout: false,
                checkoutData: calculateCheckoutResponse.data
            })
        } catch (e) {
        
            dispatch({
                type: CALCULATE_CHECKOUT,
                calculatingCheckout: true,
                checkoutData: {
                    totalShipping: 'TBD',
                    totalTaxes: 'TBD',
                    totalFees: 'TBD',
                    subtotal: 'TBD',
                    total: 'TBD'
                }
            })
            throw e;
        }
    }
}

export { calculateCheckout };