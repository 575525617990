import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from '../constants/Colors';
import Button from '../components/Button';
import Fonts from '../constants/Fonts';
import checkmarkIcon from '../assets/images/tick.svg';

const Container = styled.div`
    width: 50vw;
    padding: 80px 80px 80px 80px;
    background: ${Colors.lightGrey};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 780px) {
        padding: 50px 40px 40px 40px;
        width: 100vw;
        min-height: 60vh;
    }
`;
const Title = styled.h2`
    font-family: ${Fonts.PRIMARY_BOLD};
    text-align: center;

`;
const Subtitle = styled.p`
    font-family: ${Fonts.PRIMARY};
    text-align: center;
    margin-bottom: 20px
`;
const Card = styled.div`
    width: 60%;
    background: white;
    padding: 60px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 780px) {
        width: 100%;
    }
`;

const ConfirmIcon = styled.img`
    width: 30px;
    height: 30px;
    
`;

const ConfirmIconContainer = styled.div`
    background: ${Colors.yellow};
    border-radius: 50%;
    padding: 10px;  
    margin-bottom: -25px;
    z-index: 999;
`

const ConfirmButton = styled(Button)`
    border: none;
    width: 100%;
    font-size: 12px;
`;



const ConfirmScreen = (props) => {

    const {
        title,
        subTitle,
        buttonText
    } = props;

    return (
        <Container>
            <ConfirmIconContainer>
                <ConfirmIcon src={checkmarkIcon} />
            </ConfirmIconContainer>
            <Card>
                <Title>{title}</Title>
                <Subtitle>{subTitle}</Subtitle>
                <Link to={{ pathname: '/' }}>
                    <ConfirmButton>{buttonText}</ConfirmButton>
                </Link>
            </Card>
        </Container>
    )
}

export default ConfirmScreen;