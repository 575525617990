import axios from 'axios';
import {
    API_HEADER,
    ORDERS_API
} from '../../../constants/API';
import {
    CREATE_ORDER
} from '../types';


const createOrder = (orderData) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_ORDER,
                creatingOrder: true,
                createdOrder: null
            });
            const { token } = getState().UserReducer;
            const { data } = await axios.post(`${ORDERS_API}`, orderData, API_HEADER(token));
            dispatch({
                type: CREATE_ORDER,
                creatingOrder: false,
                createdOrder: data.createdOrder
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: CREATE_ORDER,
                creatingOrder: false,
                createdOrder: null,
                failedToCreateOrder: true
            })
            throw e;
        }
    }
}

export { createOrder };